import * as actionTypes from '../actions/bankAccountActions';

export default function (state = {}, action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case actionTypes.START_LINK_BANK_ACCOUNT_WORKFLOW:
      newState.sourceRoute = action.sourceRoute;
      return newState;
    case actionTypes.END_LINK_BANK_ACCOUNT_WORKFLOW:
      newState.linkedAccount = action.linkedAccount;
      return newState;
    case actionTypes.CLEANUP_LINK_BANK_ACCOUNT_WORKFLOW:
      return {};
    default:
      return newState;
  }
}
