import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, destroy } from 'redux-form';
import WireTransferDepositForm from './WireTransferDepositForm';
import WireTransferDepositEquities from './WireTransferDepositEquities';
import WireTransferDepositFutures from './WireTransferDepositFutures';
import * as accounts from '../../actions/brokerageAccountActions';
import './WireTransferDeposit.css';

class WireTransferDepositPage extends Component {
  componentWillUnmount() {
    this.props.destroyForm();
  }

  render() {
    return (
      <section className="page">
        {this.props.viewForm &&
          <WireTransferDepositForm
            {...this.props}
            onSubmit={this.props.submitForm}
          />
        }
        {this.props.isEquitiesAccount &&
          this.props.viewInstructions &&
          <WireTransferDepositEquities
            {...this.props}
            onSubmit={this.props.editForm}
          />}
        {this.props.isFuturesAccount &&
          this.props.viewInstructions &&
          <WireTransferDepositFutures
            {...this.props}
            onSubmit={this.props.editForm}
          />}
      </section>
    );
  }
}

WireTransferDepositPage.propTypes = {
  isFuturesAccount: PropTypes.bool,
  isEquitiesAccount: PropTypes.bool,
  viewForm: PropTypes.bool,
  viewInstructions: PropTypes.bool,
  submitForm: PropTypes.func.isRequired,
  editForm: PropTypes.func.isRequired,
  destroyForm: PropTypes.func.isRequired,
};

const initialValues = {
  fundsDestinationAccount: {
    isFuturesAccount: false,
    isEquitiesAccount: false,
  },
  transferCurrency: 'USD',
  transferAmount: '0.00',
  viewForm: true,
  viewInstructions: false,
};

const formName = 'wireTransferDepositForm';

export default connect(
  (state) => {
    const form = state.form.wireTransferDepositForm || {};
    form.values = Object.assign({ }, initialValues, form.values);

    return {
      brokerageAccounts: state.brokerageAccounts.data,
      formValues: form,
      isEquitiesAccount: form.values.fundsDestinationAccount
        ? form.values.fundsDestinationAccount.isEquitiesAccount
        : false,
      isFuturesAccount: form.values.fundsDestinationAccount
        ? form.values.fundsDestinationAccount.isFuturesAccount
        : false,
      viewInstructions: form.values.viewInstructions,
      viewForm: form.values.viewForm,
      showPrintButton: state.clientConfig.showPrintButton,
      isWireTransferEnabled: state.clientConfig.isWireTransferEnabled,
      isCheckTransferEnabled: state.clientConfig.isCheckTransferEnabled,
    };
  },
  (dispatch) => {
    return {
      onInitializeForm: (form) => {
        dispatch(accounts.requestBrokerageAccounts());
        Object.keys(initialValues).forEach(key => dispatch(
          change(form, key, initialValues[key])
        ));
      },
      onSelectDestinationAccount: (form, account) => {
        dispatch(change(form, 'fundsDestinationAccount', account));
      },
      printPage: (event) => {
        event.preventDefault();
        window.print();
      },
      submitForm: (_, __, props) => {
        dispatch(change(props.form, 'viewInstructions', true));
        dispatch(change(props.form, 'viewForm', false));
      },
      editForm: (_, __, props) => {
        dispatch(change(props.form, 'viewInstructions', false));
        dispatch(change(props.form, 'viewForm', true));
      },
      destroyForm: () => {
        dispatch(destroy(formName));
      },
    };
  }
)(WireTransferDepositPage);
