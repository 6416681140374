import React from 'react';
import Header from '../Header';
import { AbsoluteRoutes } from '../../Routes';

const ACATSDepositForm = () => {
  return (
    <form id="acats-deposit-form">
      <Header
        title="ACATS DEPOSIT"
        description={`Transfer cash and most securities electronically from an account at another financial
          institution to a TradeStation Securities account (including IRA accounts).`}
      />
      <section>
        <div className="fee-schedule">
          <dl>
            <dt>Cost:</dt>
            <dd>Free</dd>
          </dl>
          <span className="separator" />
          <dl>
            <dt>Availability:</dt>
            <dd>7 to 10 Days</dd>
          </dl>
        </div>
        <div>
          <h4>Instructions</h4>
          <ol>
            <li>
              Download, complete and sign the following Automated Customer Account Transfer (ACAT) form:&nbsp;
              <a
                href={AbsoluteRoutes.CUSTOMER_ACCOUNT_TRANSFER}
                title="Automated Customer Account Transfer Form"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Automated Customer Account Transfer Form
              </a>
            </li>
            <li>
              Include a copy of your most recent statement
                (this must be included in order to complete the transfer)
            </li>
            <li>
              Mail both of the above to:
              <address>
                <strong>TradeStation Securities, Inc. </strong><br />
                8050 SW 10th Street, Suite 2000 <br />
                Plantation, FL 33324<br />
              </address>
            </li>
            <li>
              If you prefer to send this form via email,
              please send this form to:&nbsp;
              <address>
                <a href="mailto:ClientServices@tradestation.com">
                  ClientServices@tradestation.com
                </a>
              </address>
            </li>
            <li>
              If you prefer to fax this form, please send to:
              <address>
                <a href="tel:+19546527599">954-652-7599</a>.
              </address>
            </li>
          </ol>
        </div>
        <div>
          <h5>Important Notes</h5>
          <p>
            The time it takes to complete an electronic transfer of assets depends on your financial institution.
            However, in most cases, it takes 7-10 business days for broker to broker transfers.
            Transfers involving banks, mutual fund companies and transfer agents can take 2-4 weeks.
          </p>
          <p>
            The type and title of your TradeStation Securities Equities account and the type and title
            of the account from which you are transferring funds must match exactly, or the transfer will not be
            valid. For example, you can transfer securities from one IRA to another IRA of the same kind with the
            same owner, but not from a non-retirement account to an IRA.
          </p>
        </div>
      </section>
    </form>
  );
};

export default ACATSDepositForm;
