import * as modalActions from '../actions/modalActions';

const initialState = {
  modalType: 'alert',
  modalProps: {
    open: false,
    isConfirmModal: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case modalActions.SHOW_MODAL:
      return Object.assign({}, state, {
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      });
    case modalActions.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};
