import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DEEP_LINK } from '../constants';

const isExternal = (url) => {
  return url.startsWith('http') || url === DEEP_LINK;
};

/**
 * Link that also works for external URL's
 * see https://github.com/ReactTraining/react-router/issues/1147
 */
const BetterLink = (props) => {
  return isExternal(props.to) ?
    <a
      href={props.to}
      target={props.target}
      className={props.className}
      {...props}
    >
      {props.children}
    </a>
    :
    <Link to={props.to} className={props.className}>
      {props.children}
    </Link>;
};

BetterLink.propTypes = {
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]),
};

export default BetterLink;
