import { UPDATE_USER_PROFILE } from '../actions/userProfileActions';

const initialState = {
  isEmployee: false,
  isQAUser: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_PROFILE: {
      const tsBrokerageAccounts = action.accounts.data;
      const oldIsEmployee = state.isEmployee;

      const isEmployee = tsBrokerageAccounts.filter(acc => acc.repId.startsWith('EMP')).length > 0;

      if (oldIsEmployee !== isEmployee) {
        return {
          ...state,
          isEmployee,
        };
      }

      return state;
    }
    default:
      return state;
  }
}
