import { CHANGE_CURRENCY } from '../actions/currencyActions';

const initialState = {
  code: 'USD',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_CURRENCY: {
      return Object.assign({}, state, {
        code: action.payload,
      });
    }
    default:
      return state;
  }
}
