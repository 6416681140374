import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createBrowserHistory from 'history/createBrowserHistory';
import { routerMiddleware, ConnectedRouter } from 'react-router-redux';
import { Route } from 'react-router-dom';
import { StaticRoutes } from './Routes';
import App from './App';
import getStore from './getStore';
import getConfig from './config';
import getClientConfig from './clientConfig';
import logMetric from './logging/logMetric';
import lookup from './api/lookupData';
import getUserProfile from './userProfile';
import './polyfill';
import { CLIENT_CENTER_CLIENT_ID, TANGO_CLIENT_ID } from './constants';

const AUTH_TOKEN_STORAGE_KEY = 'authToken';
const AUTH_TOKEN_PARAMETER_KEY = 'access_token';
const CLIENT_ID_STORAGE_KEY = 'clientId';
const CLIENT_ID_PARAMETER_KEY = 'client_id';
const USER_ID_STORAGE_KEY = 'userId';
const USER_ID_PARAMETER_KEY = 'user_id';
const ALTERNATE_USER_ID_PARAMETER_KEY = 'userid';

const getParameter = (parameterKey, storageKey) => {
  let param = null;
  const searchKey = ('[\\?&]').concat(parameterKey, '=([^&#]*)');
  const result = new RegExp(searchKey).exec(window.location.href);

  if (result) {
    param = decodeURIComponent(result[1]);
    param = param === 'undefined' || param === 'null' ? undefined : param;
  }

  return param || sessionStorage.getItem(storageKey);
};

const getInitialState = () => {
  const referrer = document.referrer;
  const configuration = getConfig();
  const authToken = getParameter(AUTH_TOKEN_PARAMETER_KEY, AUTH_TOKEN_STORAGE_KEY);
  
  let clientId = getParameter(CLIENT_ID_PARAMETER_KEY, CLIENT_ID_STORAGE_KEY);
  if (!clientId) {
    clientId = configuration.env === 'local' ? TANGO_CLIENT_ID : CLIENT_CENTER_CLIENT_ID;
  }

  const clientConfig = getClientConfig(clientId);
  const userProfile = getUserProfile();
  let userId = getParameter(USER_ID_PARAMETER_KEY)
    || getParameter(ALTERNATE_USER_ID_PARAMETER_KEY, USER_ID_STORAGE_KEY)
    || 'unspecified';

  if (authToken === '1234') userId = 'omgjsam';

  logMetric('initialized', { clientId, referrer });

  sessionStorage.setItem(CLIENT_ID_STORAGE_KEY, clientId);
  sessionStorage.setItem(USER_ID_STORAGE_KEY, userId);
  sessionStorage.setItem(AUTH_TOKEN_STORAGE_KEY, authToken);

  const authentication = { authToken, isAuthenticated: authToken !== 'null' && !!authToken };

  return { configuration, authentication, userId, clientId, lookup, clientConfig, userProfile };
};

const history = createBrowserHistory();

const renderApp = store => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Route path={StaticRoutes.ROOT} component={App} />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
};

const initialState = getInitialState();
const store = getStore(initialState, [routerMiddleware(history)]);

history.listen(location => { window.parent.postMessage(location, '*'); });

renderApp(store);
