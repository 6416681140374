import moment from 'moment-timezone';
import * as requestHistoryActions from '../actions/requestHistoryActions';

const supportsCloning = ['ACH - Debit', 'ACH - Credit', 'Check - Debit', 'Wire - Debit', 'Journal'];

const getRequest = (request) => {
  return {
    requestId: request.ClientReferenceId,
    brokerageAccountNumber: request.AccountNumber,
    requestReceivedDate: moment(request.RequestReceivedDate),
    transferType: request.TransferType,
    transferAmount: request.Amount,
    transferCurrency: (request.CurrencyCode === '___') ? 'USD' : request.CurrencyCode,
    transferStatus: request.Status,
    transferStatusType: request.StatusType,
    supportsCloning: supportsCloning.includes(request.TransferType),
    declineReason: request.DeclineReason,
    suspenseReason: request.SuspenseReasonDetail,
    stateName: request.StateName || '',
    brokerAccountType: request.BrokerAccountType,
  };
};

export default function (state = [], action) {
  switch (action.type) {
    case requestHistoryActions.FETCH_HISTORY_SUCCESS: {
      if (!action.payload.Items) return state;
      const requestHistory = action.payload.Items.map(request => getRequest(request));
      return requestHistory;
    }
    default:
      return state;
  }
}
