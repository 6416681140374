import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from '../Header';
import { StaticRoutes, AbsoluteRoutes } from '../../Routes';
import './FundingMethods.css';
import { ReactComponent as SmartTransfer } from '../../images/TransferCash.svg';
import { ReactComponent as TransferAccount } from '../../images/TransferAcc.svg';

const getParameter = (parameterKey, storageKey) => {
  let param = null;
  const searchKey = ('[\\?&]').concat(parameterKey, '=([^&#]*)');
  const result = new RegExp(searchKey).exec(window.location.href);

  if (result) {
    param = decodeURIComponent(result[1]);
    param = param === 'undefined' || param === 'null' ? undefined : param;
  }

  return param || sessionStorage.getItem(storageKey);
};

const getClientIdFromURL = () => {
  const CLIENT_ID_STORAGE_KEY = 'clientId';
  const CLIENT_ID_PARAMETER_KEY = 'client_id';

  return getParameter(CLIENT_ID_PARAMETER_KEY, CLIENT_ID_STORAGE_KEY) || 'client_center';
};

const FundingMethodsPage = props => {
  const { isWireTransferEnabled, isCheckTransferEnabled } = props;
  const clientId = getClientIdFromURL();
  return (
    <div className="page">
      <Header title="Funding Methods" />
      <section id="funding-methods">
        <Link to={StaticRoutes.TRANSFERS}>
          <div id="smart-transfer">
            <img alt="Smart Transfer" title="Smart Transfer" src={SmartTransfer} />
            <div className="description">
              <h3>Smart Transfer</h3>
              { (clientId !== 'aop') ? (
                <p>Link your external bank or brokerage account for fast and easy deposits and withdrawals.</p>
              ) : (
                <p>Link your external account and deposit funds, or transfer from an existing TradeStation account.</p>
              )}
              <h4>Recommended</h4>
            </div>
          </div>
        </Link>
        {isWireTransferEnabled &&
          <Link to={StaticRoutes.WIRE_TRANSFER_IN}>
            <div id="wire-transfer">
              <img alt="Wire Transfer" title="Wire Transfer" src={SmartTransfer} />
              <div className="description">
                <h3>Wire Transfer</h3>
                <p>Transfer cash assets including select foreign currencies.</p>
              </div>
            </div>
          </Link>
        }
        {isCheckTransferEnabled &&
          <Link to={StaticRoutes.CHECK_TRANSFER_IN}>
            <div id="check-transfer">
              <img alt="Check Transfer" title="Check Transfer" src={SmartTransfer} />
              <div className="description">
                <h3>Submit a Check</h3>
                <p>Fund your account by check. Only 1st party checks will be accepted</p>
              </div>
            </div>
          </Link>
        }
        { clientId !== 'aop' &&
          <Link to={StaticRoutes.TRANSFERS}>
            <div id="internal-transfer">
              <img alt="Internal Transfer" title="Internal Transfer" src={SmartTransfer} />
              <div className="description">
                <h3>Internal Transfer</h3>
                <p>Transfer funds from another TradeStation Account.</p>
              </div>
            </div>
          </Link>
        }
        <a
          href={AbsoluteRoutes.CUSTOMER_ACCOUNT_TRANSFER}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div id="transfer-account">
            <img alt="Transfer Account" title="Transfer Account" src={TransferAccount} />
            <div className="description">
              <h3>Transfer Account</h3>
              <p>Consolidate your assets. Move cash and securities from an external financial institution.</p>
            </div>
          </div>
        </a>
      </section>
    </div >
  );
};

FundingMethodsPage.propTypes = {
  isWireTransferEnabled: PropTypes.bool,
  isCheckTransferEnabled: PropTypes.bool,
};

export default connect(
  state => {
    return {
      isWireTransferEnabled: state.clientConfig.isWireTransferEnabled,
      isCheckTransferEnabled: state.clientConfig.isCheckTransferEnabled,
    };
  }
)(FundingMethodsPage);
