import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as lookupKeys from '../../api/lookupData';
import { FORM_FIELD_NAMES } from './IRADistributionForm';

const ConfirmationField = ({ name, value }) => {
  return (
    <dl>
      <dt>{name}</dt>
      <dd>{value}</dd>
    </dl>
  );
};

ConfirmationField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};


const IRAContributionConfirmation = (props) => {
  const getValueFromKey = (name, key) => {
    const lookup = props.lookup[name] && props.lookup[name].find(l => l.Key.toString() === key.toString());
    return lookup && lookup.Description;
  };

  const identifiers = props.lookup.identifiers;

  const RECHARACTIRIZATION_ID = identifiers[lookupKeys.IRADistributionReason].ContributionRecharacterization;
  const DIRECT_ROLLOVER_ID = identifiers[lookupKeys.IRADistributionReason].DirectRollover;
  const EXCESS_CONTRIBUTION_ID = identifiers[lookupKeys.IRADistributionReason].RemovalOfExcessContribution;

  const {
    [FORM_FIELD_NAMES.DISTRIBUTION_REASON_TYPE_ID]: distributionReasonTypeId,
    [FORM_FIELD_NAMES.WITHHOLD_FEDERAL_TAXES]: withholdFederalTaxes,
    [FORM_FIELD_NAMES.WITHHOLD_STATE_TAXES]: withholdStateTaxes,
    [FORM_FIELD_NAMES.FEDERAL_WITHHOLD_AMOUNT]: federalWithholdTaxAmount,
    [FORM_FIELD_NAMES.FEDERAL_WITHHOLD_PERCENT]: federalWithholdTaxPercentage,
    [FORM_FIELD_NAMES.STATE_WITHHOLD_AMOUNT]: stateWithholdTaxAmount,
    [FORM_FIELD_NAMES.STATE_WITHHOLD_PERCENT]: stateWithholdTaxPercentage,
    [FORM_FIELD_NAMES.IRA_TYPE_ID]: iraTypeId,
    [FORM_FIELD_NAMES.RECHARACTIRIZATION_YEAR]: recharacterizationYear,
    [FORM_FIELD_NAMES.DIRECT_ROLLOVER_ACCOUNT_TYPE_ID]: directRolloverAccountTypeId,
    [FORM_FIELD_NAMES.EXCESS_CONTRIBUTION_YEAR]: excessContributionYear,
    [FORM_FIELD_NAMES.DISTRIBUTION_TYPE_ID]: distributionTypeId,
  } = (props.formValues.values.iraWithdrawal || {});
  return (
    <div>
      <ConfirmationField
        name="IRA Account Type"
        value={getValueFromKey(lookupKeys.IRAType, iraTypeId)}
      />
      <ConfirmationField
        name="Distribution Reason"
        value={getValueFromKey(lookupKeys.IRADistributionReason, distributionReasonTypeId)}
      />
      {distributionReasonTypeId === RECHARACTIRIZATION_ID &&
        <div>
          {recharacterizationYear &&
            <ConfirmationField
              name="Recharacterization Year"
              value={recharacterizationYear}
            />
          }
        </div>
      }
      {distributionReasonTypeId === DIRECT_ROLLOVER_ID &&
        <div>
          {directRolloverAccountTypeId &&
            <ConfirmationField
              name="Rollover Account Type"
              value={getValueFromKey(lookupKeys.IRADirectRolloverAccountType, directRolloverAccountTypeId)}
            />
          }
        </div>
      }
      {distributionReasonTypeId === EXCESS_CONTRIBUTION_ID &&
        <div>
          {excessContributionYear &&
            <ConfirmationField
              name="Excess Contribution Year"
              value={excessContributionYear}
            />
          }
        </div>
      }
      {distributionTypeId &&
        <ConfirmationField
          name="Distribution Type"
          value={getValueFromKey(lookupKeys.IRADistributionType, distributionTypeId)}
        />
      }
      {withholdFederalTaxes &&
        <ConfirmationField
          name="Federal Tax Withheld"
          value={federalWithholdTaxAmount || `${federalWithholdTaxPercentage}%`}
        />
      }
      {withholdStateTaxes &&
        <ConfirmationField
          name="State Tax Withheld"
          value={stateWithholdTaxAmount || `${stateWithholdTaxPercentage}%`}
        />
      }
    </div>
  );
};

IRAContributionConfirmation.propTypes = {
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      iraWithdrawal: PropTypes.shape(),
    }),
  }),
  lookup: PropTypes.shape(),
};

export default connect(
  state => {
    return {
      lookup: state.lookup,
    };
  }
)(IRAContributionConfirmation);
