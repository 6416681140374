import React, { Component } from 'react';
import PropTypes from 'prop-types';

const rippleStyle = {
  position: 'absolute',
  borderRadius: '50%',
  opacity: 0,
  width: 35,
  height: 35,
  transform: 'translate(-50%, -50%)',
  pointerEvents: 'none',
};

const wrapStyle = {
  position: 'relative',
  overflow: 'hidden',
};

class Ripples extends Component {
  constructor(props) {
    super(props);
    this.state = { rippleStyle: {} };

    this.handleClick = this.handleClick.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  handleClick(ev) {
    if (ev.stopPropagation) {
      ev.stopPropagation();
    }

    const { onClick, color, during } = this.props;
    const {
      pageX, pageY, currentTarget: {
        offsetLeft, offsetTop,
        offsetWidth, offsetHeight,
      },
    } = ev;

    const left = pageX - offsetLeft;
    const top = pageY - offsetTop;

    this.setState({
      rippleStyle: {
        top: top || 0,
        left: left || 0,
        opacity: 1,
        backgroundColor: color,
      },
    });

    const timeoutId = setTimeout(() => {
      const size = Math.max(offsetWidth, offsetHeight);

      this.setState({
        rippleStyle: {
          top,
          left,
          backgroundColor: color,
          transition: `all ${during}ms`,
          transform: `${rippleStyle.transform} scale(${size / 9})`,
          opacity: 0,
        },
      });
    }, 50);

    this.timeoutId = timeoutId;

    if (typeof onClick === 'function') {
      onClick(ev);
    }
  }

  render() {
    const { children, style, during, color, ...props } = this.props;
    const { state, handleClick } = this;

    const s = {
      ...style,
      ...wrapStyle,
    };

    return (
      <div {...props} style={s} onClick={handleClick} role="presentation">
        { children }
        <s style={{
          ...rippleStyle,
          ...state.rippleStyle,
        }}
        />
      </div>
    );
  }
}

Ripples.propTypes = {
  during: PropTypes.number,
  color: PropTypes.string,
  children: PropTypes.shape(),
  style: PropTypes.shape(),
  onClick: PropTypes.func,
};

Ripples.defaultProps = {
  during: 600,
  color: 'rgba(0, 0, 0, .3)',
};

export default Ripples;
