import * as bankAccountActions from '../actions/bankAccountActions';

export default function (state = { loading: true }, action) {
  switch (action.type) {
    case bankAccountActions.FETCH_FASTLINK_SESSION_REQUEST: {
      return {
        loading: true,
        message: 'Getting things ready...',
      };
    }
    case bankAccountActions.FETCH_FASTLINK_SESSION_ERROR: {
      return {
        loading: true,
        message: '',
        error: true,
      };
    }
    case bankAccountActions.FETCH_FASTLINK_SESSION_SUCCESS: {
      return {
        ...state,
        url: action.payload.fastlinkUiUrl,
      };
    }


    case bankAccountActions.FETCH_FASTLINK_HTML_REQUEST: {
      return {
        loading: true,
        message: 'Getting things ready...',
      };
    }
    case bankAccountActions.FETCH_FASTLINK_HTML_ERROR: {
      return {
        loading: true,
        message: '',
        error: true,
      };
    }
    case bankAccountActions.FETCH_FASTLINK_HTML_SUCCESS: {
      return {
        ...state,
        url: action.payload.fastlinkUiUrl,
        html: action.payload.html,
      };
    }
    case bankAccountActions.FASTLINK_FRAME_LOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case bankAccountActions.SUBMIT_BANK_ACCOUNT_AUTOMATIC_REQUEST: {
      return {
        ...state,
        loading: true,
        carmaRequestMade: true,
      };
    }
    case bankAccountActions.SUBMIT_BANK_ACCOUNT_AUTOMATIC_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case bankAccountActions.SUBMIT_BANK_ACCOUNT_AUTOMATIC_ERROR: {
      return {
        ...state,
        carmaError: true,
      };
    }
    case bankAccountActions.SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_REQUEST:
    case bankAccountActions.SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_REQUEST_V1: {
      return {
        ...state,
        loading: true,
        carmaRequestMade: true,
      };
    }
    case bankAccountActions.SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_SUCCESS:
    case bankAccountActions.SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_SUCCESS_V1: {
      return {
        ...state,
        loading: false,
      };
    }
    case bankAccountActions.SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_ERROR:
    case bankAccountActions.SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_ERROR_V1: {
      return {
        ...state,
        carmaError: true,
      };
    }
    case bankAccountActions.SUBMIT_PLAID_EVENT_REQUEST: {
      return {
        ...state,
        carmaRequestMade: true,
      };
    }
    case bankAccountActions.SUBMIT_PLAID_EVENT_SUCCESS: {
      return {
        ...state,
      };
    }
    case bankAccountActions.SUBMIT_PLAID_EVENT_ERROR: {
      return {
        ...state,
        // do not show <ErrorBar /> due to Plaid logs issues
        carmaError: false,
        errorBar: {
          error: false,
        },
      };
    }
    case bankAccountActions.FASTLINK_FAILURE: {
      return {
        ...state,
        loading: true,
        message: '',
        error: true,
      };
    }
    case bankAccountActions.PLAIDLINK_FAILURE: {
      return {
        ...state,
        loading: true,
        message: action.plaidError.display_message || action.plaidError.error_message,
        error: true,
      };
    }
    case bankAccountActions.FETCH_PLAID_HTML_REQUEST: {
      return {
        loading: true,
        message: 'Getting things ready...',
      };
    }

    case bankAccountActions.FETCH_PLAID_HTML_SUCCESS: {
      return {
        ...state,
        url: action.payload.fastlinkUiUrl,
        html: action.payload.html,
        token: action.payload.token,
        clientName: action.payload.clientName,
        env: action.payload.env,
        product: action.payload.product,
        loading: false,
      };
    }

    case bankAccountActions.FETCH_PLAID_HTML_ERROR: {
      return {
        loading: true,
        message: '',
        error: true,
      };
    }
    default:
      return state;
  }
}
