export const BankAccountType = 'BankAccountType';
export const DeliveryMethod = 'DeliveryMethod';

export const IRAContributionSourceType = 'IRAContributionSourceType';
export const IRAContributionType = 'IRAContributionType';
export const IRADepositType = 'IRADepositType';
export const IRADirectRolloverAccountType = 'DirectRolloverAccountType';
export const IRADistributionReason = 'DistributionReason';
export const IRADistributionType = 'IRADistributionType';
export const IRARolloverType = 'IRARolloverType';
export const IRAType = 'IRAType';

export default {
  [BankAccountType]: [
    {
      Key: 73,
      Value: 'Checking',
      Description: 'Checking',
    },
    {
      Key: 75,
      Value: 'Savings',
      Description: 'Savings',
    },
  ],
  CheckType: [
    {
      Key: 82,
      Value: 'PersonalCheck',
      Description: 'Personal Check',
    },
    {
      Key: 83,
      Value: 'CashierCheck',
      Description: 'Cashier Check',
    }
  ],
  Direction: [
    {
      Key: 1,
      Value: 'Debit',
      Description: 'Debit',
    },
    {
      Key: 2,
      Value: 'Credit',
      Description: 'Credit',
    }
  ],
  [DeliveryMethod]: [
    {
      Key: 20,
      Value: 'Regular',
      Description: 'Regular',
    },
    {
      Key: 21,
      Value: 'Overnight',
      Description: 'Overnight',
    },
  ],
  [IRAContributionSourceType]: [
    {
      Key: 243,
      Value: 'Employer',
      Description: 'Employer',
    },
    {
      Key: 244,
      Value: 'Employee',
      Description: 'Employee',
    },
  ],
  [IRAContributionType]: [
    {
      Key: 242,
      Value: 'Regular',
      Description: 'Regular',
    },
  ],
  [IRADepositType]: [
    {
      Key: 234,
      Value: 'Contribution',
      Description: 'Contribution',
    },
    {
      Key: 235,
      Value: 'Rollover',
      Description: 'Rollover',
    },
  ],
  [IRADirectRolloverAccountType]: [
    {
      Key: 230,
      Value: 'TraditionalIRA',
      Description: 'Traditional IRA',
    },
    {
      Key: 231,
      Value: 'RothIRA',
      Description: 'Roth IRA',
    },
    {
      Key: 232,
      Value: 'RetailAccount',
      Description: 'Retail Account',
    },
    {
      Key: 233,
      Value: 'EmployeeSponsoredPlan',
      Description: 'Employee Sponsored Plan',
    },
  ],
  [IRADistributionReason]: [
    {
      Key: 7,
      Value: 'Normal',
      Description: 'Normal',
    },
    {
      Key: 8,
      Value: 'Premature',
      Description: 'Premature',
    },
    {
      Key: 9,
      Value: 'RolloverToQualifiedPlan',
      Description: 'Rollover to Qualified Plan',
    },
    {
      Key: 11,
      Value: 'TransferToIdenticalRetirementAccount',
      Description: 'Transfer to Identical Retirement Account',
    },
    {
      Key: 91,
      Value: 'RothConversion',
      Description: 'Roth Conversion',
    },
    {
      Key: 92,
      Value: 'ContributionRecharacterization',
      Description: 'Contribution Recharacterization',
    },
    {
      Key: 93,
      Value: 'DirectRollover',
      Description: 'Direct Rollover',
    },
    {
      Key: 94,
      Value: 'RequiredMinimumDistribution',
      Description: 'Required Minimum Distribution (RMD)',
    },
    {
      Key: 95,
      Value: 'Death',
      Description: 'Death',
    },
    {
      Key: 96,
      Value: 'Disabiltiy',
      Description: 'Disabiltiy',
    },
    {
      Key: 97,
      Value: 'Divorce',
      Description: 'Divorce',
    },
    {
      Key: 98,
      Value: 'Education',
      Description: 'Education',
    },
    {
      Key: 100,
      Value: 'RemovalOfExcessContribution',
      Description: 'Removal of Excess Contribution',
    },
    {
      Key: 101,
      Value: 'Annuitized',
      Description: 'Annuitized',
    },
  ],
  IRADistributionType: [
    {
      Key: 240,
      Value: 'TotalDistribution',
      Description: 'Total Distribution',
    },
    {
      Key: 241,
      Value: 'PartialDistribution',
      Description: 'Partial Distribution',
    },
  ],
  [IRARolloverType]: [
    {
      Key: 236,
      Value: 'TraditionalIRAOrSimpleIRAToTraditionalIRARollover',
      Description: 'Traditional to Traditional',
    },
    {
      Key: 237,
      Value: 'RothIRAToRothIRARollover',
      Description: 'Roth to Roth',
    },
    {
      Key: 238,
      Value: 'RolloverOrDirectRolloverFromQualifiedPlanIntoATraditionalIRA',
      Description: 'Qualified to Traditional',
    },
    {
      Key: 239,
      Value: 'TraditionalIRAToARothIRARollover',
      Description: 'Traditional to Roth',
    },
  ],
  [IRAType]: [
    {
      Key: 226,
      Value: 'TraditionalIRA',
      Description: 'Traditional IRA',
    },
    {
      Key: 227,
      Value: 'RothIRA',
      Description: 'Roth IRA',
    },
    {
      Key: 228,
      Value: 'SEPIRA',
      Description: 'SEP IRA',
    },
    {
      Key: 229,
      Value: 'SimpleIRA',
      Description: 'Simple IRA',
    },
  ],
  StatusType: [
    {
      Key: 272,
      Value: 'Approved',
      Description: 'Approved',
    },
    {
      Key: 273,
      Value: 'Declined',
      Description: 'Declined',
    },
    {
      Key: 274,
      Value: 'Pending',
      Description: 'Pending',
    },
    {
      Key: 275,
      Value: 'Submitted',
      Description: 'Submitted',
    },
    {
      Key: 276,
      Value: 'Suspended',
      Description: 'Suspended',
    }
  ],
  identifiers: {
    BankAccountType: {
      Checking: '73',
      Savings: '75',
    },
    Direction: {
      Debit: '1',
      Credit: '2',
    },
    CheckType: {
      PersonalCheck: '82',
      CashierCheck: '83',
    },
    DeliveryMethod: {
      Regular: '20',
      Overnight: '21',
    },
    IRADistributionType: {
      TotalDistribution: '240',
      PartialDistribution: '241',
    },
    IRAContributionSourceType: {
      Employer: '243',
      Employee: '244',
    },
    IRAContributionType: {
      Regular: '242',
    },
    IRARolloverType: {
      TraditionalIRAOrSimpleIRAToTraditionalIRARollover: '236',
      RothIRAToRothIRARollover: '237',
      RolloverOrDirectRolloverFromQualifiedPlanIntoATraditionalIRA: '238',
      TraditionalIRAToARothIRARollover: '239',
    },
    IRADepositType: {
      Contribution: '234',
      Rollover: '235',
    },
    DirectRolloverAccountType: {
      TraditionalIRA: '230',
      RothIRA: '231',
      RetailAccount: '232',
      EmployeeSponsoredPlan: '233',
    },
    DistributionReason: {
      Normal: '7',
      Premature: '8',
      RolloverToQualifiedPlan: '9',
      TransferToIdenticalRetirementAccount: '11',
      RothConversion: '91',
      ContributionRecharacterization: '92',
      DirectRollover: '93',
      RequiredMinimumDistribution: '94',
      Death: '95',
      Disabiltiy: '96',
      Divorce: '97',
      Education: '98',
      RemovalOfExcessContribution: '100',
      Annuitized: '101',
    },
    IRAType: {
      TraditionalIRA: '226',
      RothIRA: '227',
      SEPIRA: '228',
      SimpleIRA: '229',
    },
    StatusType: {
      Approved: '272',
      Declined: '273',
      Pending: '274',
      Submitted: '275',
      Suspended: '276',
    },
    RecurrenceSchedule: {
      'One Time': '1',
      Weekly: '2',
      'Twice Monthly': '3',
      Monthly: '4',
      Quarterly: '5',
    },
  },
  RecurrenceSchedule: [
    {
      Key: 1,
      Value: 'One Time',
      Description: 'This is a single transaction to be processed at a later date',
    },
    {
      Key: 2,
      Value: 'Weekly',
      Description: "We'll initiate this deposit every Monday",
    },
    {
      Key: 3,
      Value: 'Twice Monthly',
      Description: '1st and 15th of each month',
    },
    {
      Key: 4,
      Value: 'Monthly',
      Description: "We'll initiate this deposit on the 1st of every month",
    },
    {
      Key: 5,
      Value: 'Quarterly',
      Description: "We'll initiate this deposit on the 1st of January, April, July and October",
    },
  ],
  TransferHistoryPeriods: [
    {
      Key: 30,
      Value: 'Last 30 Days',
    },
    {
      Key: 60,
      Value: 'Last 60 Days',
    },
    {
      Key: 120,
      Value: 'Last 120 Days',
    },
    {
      Key: 180,
      Value: 'Last 180 Days',
    },
    {
      Key: 365,
      Value: 'Last Year',
    }
  ],
};

