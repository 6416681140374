import * as wireTransferWithdrawalActions from '../actions/wireTransferActions';
import * as map from './responseMapping';

export default function (state = {}, action) {
  switch (action.type) {
    case wireTransferWithdrawalActions.FETCH_WIRE_WITHDRAWALS_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload.Items.map(map.wireTransfer),
      });
    default:
      return state;
  }
}
