import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import RenderDropdownField from '../Forms/RenderDropdownField';
import RenderTextField, { normalizeAmount } from '../Forms/RenderTextField';
import { StaticRoutes } from '../../Routes';
import publishPageHeight from '../../publishPageHeight';
import Header from '../Header';
import RippleButton from '../Forms/RippleButton';
import { TransferMethod, WiresDirection } from '../DropdownRouter';
import IRACustodianAccount from '../IRA/IRACustodianAccount';

const AmountErrorMessage = 'Amount must be greater than 0.00';

class WireTransferDepositForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.formatAmount = this.formatAmount.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.formValues.anyTouched) {
      this.props.onInitializeForm(this.props.form);
    }
  }

  componentDidUpdate() {
    publishPageHeight();
  }

  // iOS Safari pre 10.3.3 won't call checkValidity before a form submission, so we have to do this
  // ourselves because we depend on native html validation to prevent form submission errors
  /* eslint-disable */
  handleSubmit(event) {
    if (!event.target.checkValidity()) event.preventDefault();

    let amount = 0;
    let normalize = '';

    if (this.props.formValues && this.props.formValues.values) {
      normalize = normalizeAmount(this.props.formValues.values.transferAmount);
      amount = parseFloat(normalize);
    }

    if (amount <= 0.00) {
      event.preventDefault();
      this.setState({
        error: AmountErrorMessage,
      });
      return;
    }
    
    //this.props.formValues.values.transferAmount = normalize;
    this.props.handleSubmit(event);
  }

  /* eslint-disable */
  formatAmount(value) {
    if (!value) return value;
    const cleanValue = parseFloat(value.replace(/\./g, '').replace(/,/g, ''));
    const newValue = (cleanValue / 100);
    const result = Number(newValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return result;
  }

  handleChange(event) {
    let amount = 0;
    
    if (event.target.value) {
      amount = normalizeAmount(event.target.value);
      amount = parseFloat(amount);
    }

    if (amount <= 0.00) {
      this.setState({
        error: AmountErrorMessage,
      });
    } else {
      this.setState({
        error: '',
      });
    }
  }

  render() {
    const { isFuturesAccount } = this.props.formValues.values.fundsDestinationAccount;
    const { fundsDestinationAccount } = this.props.formValues.values;
    const { isWireTransferEnabled, isCheckTransferEnabled } = this.props;
    return (
      <form id="wire-deposit-form" onSubmit={this.handleSubmit}>
        <Header
          title="Wire Transfer"
          description="Wire cash to your TradeStation brokerage account from an external account."
        />
        <section>
          <TransferMethod 
            isWireTransferEnabled={isWireTransferEnabled}
            isCheckTransferEnabled={isCheckTransferEnabled}
            defaultValue={StaticRoutes.WIRE_TRANSFER_IN} 
          />
          <div className="fee-schedule">
            <dl>
              <dt>Cost:</dt>
              <dd>Free</dd>
            </dl>
            <span className="separator" />
            <dl>
              <dt>Availability:</dt>
              <dd>Next Business Day (if received by 4PM ET)</dd>
            </dl>
          </div>
          <WiresDirection defaultValue={StaticRoutes.WIRE_TRANSFER_IN} />
          <Field
            component={RenderDropdownField}
            id="funds-destination"
            name="funds-destination"
            label="Funds Destination"
            onChange={(e) => this.props.onSelectDestinationAccount(
              this.props.form,
              this.props.brokerageAccounts.find(account => account.accountNumber === e.target.value)
            )}
            required
            validationMessage="Please select an account from the list."
          >
            <option />
            {this.props.brokerageAccounts.map((account, i) =>
              <option key={i} value={account.accountNumber} title={account.accountName}>
                {account.displayName}
              </option>
            )}
          </Field>
          {fundsDestinationAccount
            && (
              (fundsDestinationAccount.isIRA && fundsDestinationAccount.isFuturesAccount)
              || (fundsDestinationAccount.IsCustodian)
            )

            ? <IRACustodianAccount />
            : <div>
              <div className="amount">
                <Field
                  component={RenderTextField}
                  name="transferAmount"
                  type="text"
                  min="0.01"
                  pattern="^-?[\d,.]+$"
                  step="any"
                  id="transferAmount"
                  label="Amount"
                  normalize={this.formatAmount}
                  onChange={this.handleChange}
                  validationMessage="Enter the amount to be transferred."
                  required
                />
                <Field
                  component={RenderDropdownField}
                  id="transfer-currency"
                  name="transferCurrency"
                  label="Currency"
                  onChange={this.onCurrencySelected}
                  required
                >
                  <option />
                  {['USD'].concat(isFuturesAccount
                    && ['AUD', 'CAD', 'CHF', 'EUR', 'GBP', 'HKD', 'JPY', 'SGD']
                  ).map(code =>
                    <option
                      key={code}
                      value={code}
                    >
                      {code}
                    </option>
                  )}
                </Field>
              </div>
              <div>
                  <span className="amount-validation">{this.state.error}</span>
              </div>
              <div className="actions">
                <RippleButton
                  disabled={this.state.error.length > 0}
                  className="primary"
                  context={this.props.formValues.values.transferAmount > 0 && fundsDestinationAccount.accountNumber ? "WIRE_DEPOSIT_VIEW_INSTRUCTIONS_CLICK"   : "" }
                >
                  View Instructions
                </RippleButton>
              </div>
            </div>
          }
        </section>
      </form>
    );
  }
}

WireTransferDepositForm.propTypes = {
  brokerageAccounts: PropTypes.arrayOf(PropTypes.object),
  onInitializeForm: PropTypes.func.isRequired,
  onSelectDestinationAccount: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    anyTouched: PropTypes.bool,
    values: PropTypes.shape({
      fundsDestinationAccount: PropTypes.shape({
        supportedCurrencies: PropTypes.arrayOf(PropTypes.string),
        isFuturesAccount: PropTypes.bool,
      }),
    }),
  }),
  form: PropTypes.string,
  isWireTransferEnabled: PropTypes.bool,
  isCheckTransferEnabled: PropTypes.bool,
};

export default reduxForm({
  form: 'wireTransferDepositForm',
  destroyOnUnmount: false,
})(WireTransferDepositForm);
