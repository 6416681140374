import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, reset, destroy } from 'redux-form';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';
import { StaticRoutes } from '../../Routes';
import * as countries from '../../actions/countriesActions';
import * as accounts from '../../actions/brokerageAccountActions';
import * as transferFeeActions from '../../actions/transferFeeActions';
import * as actions from '../../actions/wireTransferActions';
import * as cloneActions from '../../actions/cloneRequestActions';
import * as scheduledTransferActions from '../../actions/scheduledTransferActions';
import WireTransferWithdrawalForm from './WireTransferWithdrawalForm';
import WireTransferWithdrawalConfirmation from './WireTransferWithdrawalConfirmation';
import { changeAmount } from '../../actions/amountActions';
import { changeDate } from '../../actions/scheduledDateActions';
import './WireTransferWithdrawal.css';
import { changeCurrency } from '../../actions/currencyActions';
import { changeFrequency } from '../../actions/frequencyActions';

/* eslint-disable */

const normalize = (value) => {
  if (!value || !isNaN(value)) return value;
  const cleanValue = parseFloat(value.replace(/\./g, '').replace(/,/g, ''));
  const result = (cleanValue / 100).toFixed(2);
  return Number(result);
}

class WireTransferWithdrawalPage extends Component {
  componentWillUnmount() {
    this.props.destroyForm();
  }

  render() {
    return (
      <section className="wire-transfers page">
        {this.props.confirming ?
          <WireTransferWithdrawalConfirmation
            {...this.props}
            onSubmit={this.props.submitForm}
          /> :
          <WireTransferWithdrawalForm
            {...this.props}
            onSubmit={this.props.confirmRequested}
          />
        }
      </section>
    );
  }
}

WireTransferWithdrawalPage.propTypes = {
  confirmRequested: PropTypes.func.isRequired,
  confirming: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  destroyForm: PropTypes.func.isRequired,
};

const initialValues = {
  confirming: false,
  transferCurrency: 'USD',
  transferAmount: '0.00',
};

const formName = 'wireTransferWithdrawalForm';

export default connect(
  (state) => {
    const form = state.form.wireTransferWithdrawalForm || {};

    initialValues.transferCurrency = state.currency.code;

    form.values = Object.assign({ }, initialValues, form.values);

    const previouslyWiredBanks = Array.from(new Set(
      (state.wireTransfers.data || []).map(request => request.destinationBank)));

    const destinations = [];

    previouslyWiredBanks.forEach(bank => destinations.push((state.wireTransfers.data.find(wire =>
      bank === wire.destinationBank
    ))));

    const distributionTypeId = form.values.iraWithdrawal && form.values.iraWithdrawal.distributionTypeId;
    const totalDistributionId = state.lookup.identifiers.IRADistributionType.TotalDistribution;

    const brokerageAccounts = state.brokerageAccounts.data;
    const origin = brokerageAccounts.find(account => account.key === form.values.fundsOriginAccountKey);
    const showWithdrawAll = origin && !origin.isIRA;
    const transferBalanceChosen = showWithdrawAll && form.values.transferBalanceChosen;

    // const supportedCurrencies = (selectedDestinationAccount && selectedDestinationAccount.isFuturesAccount &&
    //   selectedOriginAccount && selectedOriginAccount.supportedCurrencies) ||
    //   [initialValues.transferCurrency];

    const frequencyOptions = state.lookup.RecurrenceSchedule;
    let frequencyTooltipOptions = '';

    frequencyOptions.forEach(frequency => {
      frequencyTooltipOptions +=
      `<p><strong>${frequency.Value}: </strong>${frequency.Description}</p>`;
    });

    const oneTimeTransferId = state.lookup.identifiers.RecurrenceSchedule['One Time'];
    const frequency = state.frequency.key ? state.frequency.key : oneTimeTransferId;
    initialValues.frequency = frequency;
    const isOneTimeTransfer = frequency
    && frequency.toString() === oneTimeTransferId;

    let amount = state.amount;
    if (normalize(form.values.transferAmount) !== 0 && amount.current !== normalize(form.values.transferAmount)) {
      amount = {
        current: Number(form.values.transferAmount),
      };
    }
    let allCashAmount = 0;
    if (transferBalanceChosen || distributionTypeId === totalDistributionId) {
      allCashAmount = form.values.fundsOriginAccount.availableToWithdraw
        .find((x) => x.Currency === form.values.transferCurrency).Realtime;
    }

    const bankingDays = state.bankingDays.data;
    const scheduledDate = state.schedule.scheduledDate || moment.min(bankingDays);

    return {
      formValues: form,
      brokerageAccounts,
      confirming: form.values.confirming,
      intermediaryNeeded: form.values.intermediaryNeeded,
      cloneRequest: state.cloneRequest,
      destinations,
      fees: state.transferFees.data,
      showWithdrawAll,
      transferAccountBalance: transferBalanceChosen || distributionTypeId === totalDistributionId,
      countries: state.countries.data,
      amount,
      allCashAmount,
      isWireTransferEnabled: state.clientConfig.isWireTransferEnabled,
      isCheckTransferEnabled: state.clientConfig.isCheckTransferEnabled,
      scheduledDate,
      bankingDays,
      frequencyOptions,
      frequencyTooltipOptions,
      isOneTimeTransfer,
      wireTransfers: state.wireTransfers,
    };
  },
  (dispatch) => {
    let isEdit = false;
    return {
      onInitialize: (requestId, form) => {
        const initialActions = [
          dispatch(accounts.requestBrokerageAccounts()),
          dispatch(transferFeeActions.fetchFees()),
          dispatch(countries.fetchCountries()),
          dispatch(changeDate(null)),
        ];

        if (requestId) {
          initialActions.push(dispatch(actions.fetchWireTransfer(requestId)));
        } else {
          dispatch(actions.fetchWireTransfers()),
          Object.keys(initialValues).forEach(key => dispatch(
            change(form, key, initialValues[key])
          ));
        }

        Promise.all(initialActions);
      },
      confirmRequested: (_, __, props) => {
        dispatch(change(props.form, 'confirming', true));
        window.scrollTo(0, 0);
      },
      confirmCanceled: (form) => {
        isEdit = true;
        dispatch(change(form, 'confirming', false));
        window.scrollTo(0, 0);
      },
      selectPreviousRequest: (selectedRequest, form, brokerageAccounts, fundsOriginAccount, isEdit) => {
        const request = selectedRequest;
        const selectedAccount = brokerageAccounts.find(
          account => account.key === request.fundsOriginAccountKey
        );

        if (isEdit) {
          selectedRequest.isEdit = true;
          Object.keys(request).forEach(key => dispatch(
            change(form, key, request[key])
          ));

          dispatch(change(form, 'fundsOriginAccount', selectedAccount));
          dispatch(change(form, 'fundsOriginAccountKey', selectedAccount.key));

          dispatch(change(form, 'destinationBank', request.requestHistoryKey));
        } else {
          selectedRequest.isEdit = false;
          delete request.transferAmount;
          delete request.transferCurrency;
          delete request.fundsOriginAccount;
          delete request.fundsOriginAccountKey;

          // dispatch(change(form, 'destinationBank', request.requestHistoryKey));
          // if (fundsOriginAccount) { request.fundsOriginAccountKey = fundsOriginAccount.key; }

          Object.keys(request).forEach(key => dispatch(
            change(form, key, request[key])
          ));

          //dispatch(change(form, 'fundsOriginAccount', selectedAccount));
        }

        dispatch(cloneActions.removeCloneRequest());
      },
      onSelectOriginAccount: (value, form, brokerageAccounts) => {
        const selectedAccount = brokerageAccounts.find(
          account => account.key === value
        );

        dispatch(change(form, 'transferCurrency', 'USD'));

        dispatch(change(form, 'fundsOriginAccount', selectedAccount));
        dispatch(change(form, 'fundsOriginAccountKey', selectedAccount.key));
      },
      changeAmount: (amount) => {
        dispatch(changeAmount(amount));
      },
      changeScheduledDate: (date) => {
        dispatch(changeDate(date));
      },
      changeFrequency: (frequency, form) => {
        dispatch(change(form, 'frequency', frequency));
        dispatch(changeFrequency(frequency));
      },
      changeCurrency: (currency) => {
        dispatch(changeCurrency(currency));
      },
      submitForm: (values, _, props) => {
        const data = {
          accountNumber: values.fundsOriginAccount.accountNumber,
          amount: values.transferAmount,
          currencyCode: values.transferCurrency,
          beneficiaryBankName: values.destinationBankName,
          beneficiaryBankAddress: values.destinationBankAddress,
          beneficiaryBankCity: values.destinationBankCity,
          beneficiaryBankState: values.destinationBankState,
          beneficiaryBankPostalCode: values.destinationBankPostalCode,
          beneficiaryBankCountry: values.destinationBankCountry,
          beneficiaryBankABANumber: values.destinationSwiftNumber,
          beneficiaryBankIBAN: values.destinationAccountNumber,
          beneficiaryBankSwiftNumber: values.destinationSwiftNumber,
          beneficiaryBankAccountTitle: values.destinationAccountTitle,
          beneficiaryBankAccountNumber: values.destinationAccountNumber,
          intermediaryBankName: values.intermediaryBankName,
          intermediaryBankAddress: values.intermediaryBankAddress,
          intermediaryBankCity: values.intermediaryBankCity,
          intermediaryBankState: values.intermediaryBankState,
          intermediaryBankCountry: values.intermediaryBankCountry,
          intermediaryBankPostalCode: values.intermediaryBankPostalCode,
          intermediaryBankABANumber: values.intermediaryBankSwiftNumber,
          intermediaryBankIBAN: values.intermediaryBankAccountNumber,
          intermediaryBankSwiftNumber: values.intermediaryBankSwiftNumber,
          furtherCreditAccountNumber: values.furtherCreditAccountNumber,
          furtherCreditAccountTitle: values.furtherCreditAccountTitle,
          transferAccountBalance: props.transferAccountBalance,
          iraDistribution: values.iraWithdrawal,
          scheduledEventFrequencyID: values.frequency,
          startDate: props.isOneTimeTransfer ?
            props.scheduledDate.format('MM/DD/YYYY') : undefined,
        };

        if (props.match.params.requestId && props.match.params.requestId !== '') {
          // Here we will call the api endpoint for updating scheduled transfer. Next 3 lines should change when knowing the api endpoint for updating scheduleds
          data.direction = 'Debit';
          data.iraDistribution = props.formValues.values.fundsOriginAccount.isIRA ? values.iraWithdrawal : null;
          data.fdcnId = values.fdcnId;
          const scheduledEventId = props.match.params.requestId;
          dispatch(scheduledTransferActions.updateWireScheduledTransfer(scheduledEventId, data))
          .then(action => {
            if (action.isSuccess) {
              dispatch(scheduledTransferActions.fetchScheduledTransfers());
              dispatch(push(StaticRoutes.THANK_YOU));
            }
          });
        } else {
          dispatch(actions.submitWireTransfer(data))
          .then(action => {
            if (action.isSuccess) {
              dispatch(scheduledTransferActions.fetchScheduledTransfers());
              dispatch(push(StaticRoutes.THANK_YOU));
            }
          });
        }
      },
      resetForm: (form, fundsOriginAccount) => {
        dispatch(reset(form));

        Object.keys(initialValues).forEach(key => dispatch(
          change(form, key, initialValues[key])
        ));

        if (fundsOriginAccount) {
          dispatch(change(form, 'fundsOriginAccount', fundsOriginAccount));
          dispatch(change(form, 'fundsOriginAccountKey', fundsOriginAccount.key));
        }
      },
      destroyForm: () => {
        dispatch(destroy(formName));
      },
    };
  }
)(WireTransferWithdrawalPage);
