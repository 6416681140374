import React from 'react';
import PropTypes from 'prop-types';
import { Field, change } from 'redux-form';
import RenderTextField from '../Forms/RenderTextField';
import RenderDropdownField from '../Forms/RenderDropdownField';
import * as lookupKeys from '../../api/lookupData';

const ContributionFormField = (props) => {
  const hidden = props.lookup[props.lookupName].length <= 1;
  return (
    <Field
      {...props}
      className={hidden ? 'hidden' : ''}
      component={RenderDropdownField}
    >
      <option />
      {props.lookup[props.lookupName].map(lookup =>
        <option key={lookup.Key} value={lookup.Key}>{lookup.Description}</option>
      )}
    </Field>
  );
};

ContributionFormField.propTypes = {
  lookup: PropTypes.shape(),
  lookupName: PropTypes.string,
};

export const FORM_FIELD_NAMES = {
  IRA_TYPE_ID: 'iraTypeId',
  DEPOSIT_TYPE_ID: 'iraDepositTypeId',
  ROLLOVER_TYPE_ID: 'iraRolloverTypeId',
  CONTRIBUTION_TYPE_ID: 'iraContributionTypeId',
  CONTRIBUTION_YEAR: 'iraContributionYear',
  CONTRIBUTION_SOURCE_ID: 'iraContributionSourceTypeId',
};

export const IRAContributionFields = (props) => {
  const identifiers = props.lookup.identifiers;

  const SEP_IRA_TYPE_ID = identifiers.IRAType.SEPIRA;
  const ROLLOVER_TYPE_ID = identifiers.IRADepositType.Rollover;
  const CONTRIBUTION_TYPE_ID = identifiers.IRADepositType.Contribution;

  const {
    [FORM_FIELD_NAMES.DEPOSIT_TYPE_ID]: depositTypeId,
  } = (props.formValues.values.iraDeposit || {});
  return (
    <div>
      <p>Tell us more about this IRA deposit</p>
      <ContributionFormField
        {...props}
        name={`iraDeposit.${FORM_FIELD_NAMES.IRA_TYPE_ID}`}
        label="IRA Account Type"
        required
        lookupName={lookupKeys.IRAType}
      />
      <ContributionFormField
        {...props}
        name={`iraDeposit.${FORM_FIELD_NAMES.DEPOSIT_TYPE_ID}`}
        label="Deposit Type"
        required
        lookupName={lookupKeys.IRADepositType}
      />
      {depositTypeId === ROLLOVER_TYPE_ID &&
        <ContributionFormField
          {...props}
          name={`iraDeposit.${FORM_FIELD_NAMES.ROLLOVER_TYPE_ID}`}
          label="Rollover Type"
          required
          lookupName={lookupKeys.IRARolloverType}
        />}
      {depositTypeId === CONTRIBUTION_TYPE_ID &&
        <div>
          <ContributionFormField
            {...props}
            name={`iraDeposit.${FORM_FIELD_NAMES.CONTRIBUTION_TYPE_ID}`}
            label="Contribution Type"
            lookupName={lookupKeys.IRAContributionType}
          />
          <Field
            {...props}
            component={RenderTextField}
            name={`iraDeposit.${FORM_FIELD_NAMES.CONTRIBUTION_YEAR}`}
            label="Contribution Year"
            type="number"
            min="2000"
            max={new Date().getFullYear().toString()}
            required
          />
          {props.account.iraTypeId.toString() === SEP_IRA_TYPE_ID && <ContributionFormField
            {...props}
            name={`iraDeposit.${FORM_FIELD_NAMES.CONTRIBUTION_SOURCE_ID}`}
            label="Contribution Source"
            lookupName={lookupKeys.IRAContributionSourceType}
          />}
        </div>
      }
    </div>
  );
};

IRAContributionFields.propTypes = {
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      iraDeposit: PropTypes.shape(),
    }),
  }),
  account: PropTypes.shape({
    isIRA: PropTypes.bool,
    iraTypeId: PropTypes.number,
  }).isRequired,
  lookup: PropTypes.shape(),
};

class IRAContributionForm extends React.Component {
  componentDidUpdate(prevProps) {
    const hasValue = this.props.formValues.values.iraDeposit;
    if (hasValue && prevProps.account.key === this.props.account.key) return;
    if (this.props.account.isIRA) {
      const field = `iraDeposit.${FORM_FIELD_NAMES.IRA_TYPE_ID}`;
      this.props.dispatch(change(this.props.form, field, this.props.account.iraTypeId));
      this.props.dispatch(change(this.props.form, `iraDeposit.${FORM_FIELD_NAMES.CONTRIBUTION_TYPE_ID}`, '242'));
    } else if (hasValue) {
      this.props.dispatch(change(this.props.form, 'iraDeposit', null));
    }
  }

  componentWillUnmount() {
    if (!this.props.account.isIRA) {
      this.props.dispatch(change(this.props.form, 'iraDeposit', null));
    }
  }

  render() {
    if (!this.props.account.isIRA) return <div />;
    return (
      <IRAContributionFields {...this.props} />
    );
  }
}

IRAContributionForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  formValues: PropTypes.shape().isRequired,
  account: PropTypes.shape({
    key: PropTypes.string,
    isIRA: PropTypes.bool,
    iraTypeId: PropTypes.number,
  }).isRequired,
};

export default IRAContributionForm;
