import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import RippleButton from '../Forms/RippleButton';
import { StaticRoutes, AbsoluteRoutes } from '../../Routes';

const CheckTransferWithdrawalStatic = (props) => {
  return (
    <div>
      <h4>Instructions</h4>
      <p>
        Check Withdrawals are not ready for automatic withdrawals.
        In the meantime, you can call Client Services at <a href="tel:+19546527920">(954) 652-7920</a>.

        Or, get your money even faster by linking your bank account and perform a
        &nbsp;
        <Link to={StaticRoutes.TRANSFERS}>
          Smart Transfer
        </Link>.
      </p>
      <ol>
        <li>
          To request a check by FAX or postal mail please download, print, and complete the Check Request Form.
          <div>
            <a
              href={AbsoluteRoutes.CHECK_REQUEST}
              title="Check Request Form"
              target="_blank"
              rel="noopener noreferrer"
            >
                Download Check Request Form
            </a>
          </div>
        </li>
        <li>
              If you prefer to fax this form, please send to: <a href="tel:+19546527599">954-652-7599</a>.
        </li>
        <li>
              Mail the above to:
          <address>
            <strong>TradeStation Securities, Inc. </strong><br />
                8050 SW 10th Street, Suite 2000 <br />
                Plantation, FL 33324<br />
          </address>
        </li>
        <li>
          To request a check by telephone,
          please call TradeStation Brokerage Client Services at one of the phone numbers below.
          <div>
            <a href="tel:+18008713577">(800) 871-3577</a> or <a href="tel:+19546527920">(954) 652-7920</a>
          </div>
        </li>
      </ol>

      {props.showPrintButton &&
        <RippleButton className="primary" onClick={(e) => props.printPage(e)}>PRINT</RippleButton>}
    </div>
  );
};

CheckTransferWithdrawalStatic.propTypes = {
  printPage: PropTypes.func.isRequired,
  showPrintButton: PropTypes.bool,
};

export default CheckTransferWithdrawalStatic;
