import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StaticRoutes, DynamicRoutes } from '../../Routes';
import publishPageHeight from '../../publishPageHeight';
import BetterLink from '../BetterLink';
import Loader from '../../Loader';
import { AOP_CLIENT_ID } from '../../constants';
import PlaidRenderer from './PlaidRenderer';
import config from '../../config';
import Disclaimer from '../Common/Disclaimer';
import getUserProfile from '../../userProfile';


const FASTLINK_SUCCESS = 'FastLink Success';
const FASTLINK_INCOMPLETE = 'FastLink Incomplete';
const FASTLINK_CANCELED = 'FastLink Canceled';
const FASTLINK_FAILURE = 'FastLink Failure';
const crypto = require('crypto');

const salt = '55f9t9lu5KQwLV0UEpAM4B575kasTJ1c';
const PERCENTAGESYMBOL = '%';

function getHostname(href) {
  const l = document.createElement('a');
  l.href = href;
  return l;
}

class AddBankAccountAutomatic extends React.Component {
  constructor(props) {
    super(props);
    
    this.configPlaidUrl = config().plaidUrl;
    this.messageListener = this.messageListener.bind(this);
    this.onFrameLoad = this.onFrameLoad.bind(this);
    this.hashFDCNID = this.hashFDCNID.bind(this);
    this.isPlaidAllowed = this.isPlaidAllowed.bind(this);
  }

  componentWillMount() {
    this.props.updateUserProfile(this.props.brokerageAccounts);

    if (this.isPlaidAllowed() || !this.props.accounts.length || this.isfastlinkAllowed() === 0) {
      this.props.clientConfig.isFastlinkEnabled = false;
      this.props.clientConfig.isYodleeEnabled = false;
    }
  }
  
  componentDidMount() {
    if (this.props.clientConfig.isYodleeEnabled && !this.isPlaidAllowed()) {
      window.addEventListener('message', this.messageListener);
      this.props.fetchYodleeFrame();
    }

    if (this.isPlaidAllowed()) {
      this.props.fetchPlaidIAV();
    }
  }

  componentDidUpdate() {
    publishPageHeight(900);

    const fastlinkUrl = this.props.fastlink.url;
    const fastlinkHtml = this.props.fastlink.html;

    if (!this.isPlaidAllowed() && fastlinkUrl && !fastlinkHtml) {
      this.props.fetchYodleeHtml(fastlinkUrl);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageListener);
    this.props.onYodleeLoad(false);
  }

  onFrameLoad() {
    if (!this.props.fastlink.carmaRequestMade) this.props.onYodleeLoad(true);
  }

  isLoading() {
    return this.props.fastlink.loading;
  }

  messageListener(event) {
    if (!this.props.fastlink.url && !event.data.result) return;
    const apiHost = getHostname(this.props.fastlink.url);
    if (event.origin !== `${apiHost.protocol}//${apiHost.hostname}`) return;
    if (event.data.result === FASTLINK_SUCCESS) {
      this.props.addLinkedBankAccount(event.data.accountId);
    } else if (event.data.result === FASTLINK_INCOMPLETE) {
      this.props.history.push(
        DynamicRoutes.linkBankAccountIncomplete(event.data.accountId)
      );
    } else if (event.data.result === FASTLINK_CANCELED) {
      this.props.history.push(StaticRoutes.LINK_BANK_ACCOUNT_MANUAL);
    } else if (event.data.result === FASTLINK_FAILURE) {
      this.props.onFastlinkFailure();
    }
  }

  isfastlinkAllowed() {
    let aux = 0;
    if (this.props.accounts.length) {
      for (let i = 0; i < this.props.accounts.length; i += 1) {
        const toHashFDCN = this.props.accounts[i].fdcnId;
        const data = {
          FDCNID: this.props.accounts[i].fdcnId,
          LoginName: this.hashFDCNID(toHashFDCN),
        };
        if (!this.props.accounts[i].isActive) {
          aux += 1;
        } else if (
          this.props.accounts[i].isActive &&
          !this.props.accounts[i].isNewAccount
        ) {
          this.props.increaseFastlinkLoginCount(data);
          return 1;
        } else if (
          this.props.accounts[i].isActive &&
          this.props.accounts[i].isNewAccount &&
          this.props.accounts[i].fastlinkLoginCount >=
          this.props.clientConfig.fastlinkAllowedAttemps
        ) {
          aux += 1;
        } else {
          this.props.increaseFastlinkLoginCount(data);
          return 1;
        }
      }
      if (aux === this.props.accounts.length) {
        return 0;
      }
    }
    return 0;
  }

  isPlaidAllowed() {
    return this.props.clientConfig.isPlaidEnabled
           && getUserProfile(this.props.clientId, this.props.brokerageAccounts.data).isPlaidAuthorized;
  }

  hashFDCNID(data) {
    this.setState({ someState: '' });
    const shasum = crypto.createHash('sha256');
    shasum.update(`${data}-${salt}`);
    return shasum.digest('hex');
  }

  renderFrame() {
    // Rendering Plaid
    if (this.isPlaidAllowed()) {
      return (
        <div style={{ marginLeft: `43${PERCENTAGESYMBOL}`, marginTop: `24${PERCENTAGESYMBOL}` }}>
          <PlaidRenderer
            plaidUrl={this.configPlaidUrl}
            products={this.props.fastlink.products || ['auth']}
            token={this.props.fastlink.token || ''}
            clientName={this.props.fastlink.clientName || 'client_name'}
            env={this.props.fastlink.env || 'sandbox'}
            history={this.props.history}
            onPlaidlinkFailure={this.props.onPlaidlinkFailure}
            {...this.props}
          />
        </div>
      );
    }
    // Rendering Yodlee
    if (!this.props.fastlink.html) return <div />;
    return (
      <iframe
        title="fastlink"
        onLoad={this.onFrameLoad}
        srcDoc={this.props.fastlink.html}
      />
    );
  }

  renderUnavailable() {
    return (
      <div>
        <p>
          Automatic linking is currently unavailable. Click the link below to
          manually add your account
        </p>
        <BetterLink
          target="_blank"
          to={this.props.clientConfig.manualLinkingUrl}
        >
          Link account manually
        </BetterLink>
      </div>
    );
  }

  render() {
    if (!this.props.clientConfig.isFastlinkEnabled && !this.props.clientConfig.isPlaidEnabled) {
      return (
        <div>
          <p>
            We are unable to process your request, please use the contact us
            button for further assistance.
          </p>
        </div>
      );
    }
    if (!this.props.clientConfig.isYodleeEnabled && !this.props.clientConfig.isPlaidEnabled) {
      return this.renderUnavailable();
    } else if (this.props.fastlink.carmaError) {
      return (
        <div className="manual-link-url top">
          Please use our&nbsp;
          <BetterLink
            target="_blank"
            to={this.props.clientConfig.manualLinkingUrl}
          >
            Add Bank Account Form
          </BetterLink>
          &nbsp;to complete.
        </div>
      );
    }
    const { isAopClient } = this.props;
    return (
      <div className="yodlee-frame">
        <div
          className={`${this.isLoading() ? 'invisible' : ''} frame`}
          style={isAopClient ? { height: '475px' } : {}}
        >
          {this.renderFrame()}
        </div>
        <div className={`${this.isLoading() ? 'invisible' : ''}`}>
          { !this.isPlaidAllowed() ? <Disclaimer /> : <div /> }
        </div>
        <div className={`${this.isLoading() ? '' : 'hidden'} loader`}>
          <Loader />
          <p>{this.props.fastlink.message}</p>
          {this.props.fastlink.error && (
            <p>
              An unexpected error ocurred,&nbsp;
              <a
                href="#retry"
                role="button"
                onClick={() => this.props.fetchYodleeFrame(this.isPlaidAllowed())}
              >
                try again
              </a>
              &nbsp;or click the link below to link your account manually.
            </p>
          )}
        </div>
      </div>
    );
  }
}

AddBankAccountAutomatic.propTypes = {
  addLinkedBankAccount: PropTypes.func,
  addLinkedBankPlaidAccount: PropTypes.func,
  onYodleeLoad: PropTypes.func,
  onFastlinkFailure: PropTypes.func,
  onPlaidlinkFailure: PropTypes.func,
  fetchYodleeFrame: PropTypes.func,
  fetchYodleeHtml: PropTypes.func,
  fetchPlaidIAV: PropTypes.func,
  increaseFastlinkLoginCount: PropTypes.func,
  updateUserProfile: PropTypes.func,
  fastlink: PropTypes.shape({
    loading: PropTypes.bool,
    url: PropTypes.string,
    message: PropTypes.string,
    error: PropTypes.bool,
    carmaError: PropTypes.bool,
    carmaRequestMade: PropTypes.bool,
    html: PropTypes.string,
    token: PropTypes.string,
    clientName: PropTypes.string,
    env: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.string),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  clientConfig: PropTypes.shape({
    manualLinkingUrl: PropTypes.string.isRequired,
    isYodleeEnabled: PropTypes.bool.isRequired,
    showManualLinkAtTop: PropTypes.bool,
    isFastlinkEnabled: PropTypes.bool.isRequired,
    fastlinkAllowedAttemps: PropTypes.number.isRequired,
    isPlaidEnabled: PropTypes.bool,
  }),
  isAopClient: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape),
  userProfile: PropTypes.shape({
    isEmployee: PropTypes.bool.isRequired,
  }),
  brokerageAccounts: PropTypes.shape(),
};

function mapStateToProps(state) {
  return {
    isAopClient: state.clientId === AOP_CLIENT_ID,
    clientId: state.clientId,
  };
}

export default connect(mapStateToProps)(AddBankAccountAutomatic);
