import * as scheduledTransferDetailsActions from '../actions/scheduledTransferDetailsActions';

const initialState = {
};

export default function (state = initialState, action) {
  switch (action.type) {
    case scheduledTransferDetailsActions.FETCH_SCHEDULED_TRANSFER_DETAILS_SUCCESS: {
      if (!action.payload) return state;
      return action.payload;
    }
    default:
      return state;
  }
}
