import logMetric from './logMetric';

const logAction = (store, { type, ...rest }, next) => {
  const clientId = store.getState().clientId;
  if (!type.includes('@@redux-form')) {
    logMetric('clientBehavior', { clientId, type });
  }
  next({ type, ...rest });
};

const actionLogger = store => next => action =>
  (action.type ? logAction(store, action, next) : next(action));

export default actionLogger;
