/* eslint no-param-reassign: ["error", { "props": false }] */
export const FETCH_BANKING_ACCOUNTS_REQUEST = 'FETCH_BANKING_ACCOUNTS_REQUEST';
export const FETCH_BANKING_ACCOUNTS_SUCCESS = 'FETCH_BANKING_ACCOUNTS_SUCCESS';
export const FETCH_BANKING_ACCOUNTS_ERROR = 'FETCH_BANKING_ACCOUNTS_ERROR';

export function requestBankingAccounts(loading = false) {
  return {
    types: [
      FETCH_BANKING_ACCOUNTS_REQUEST,
      FETCH_BANKING_ACCOUNTS_SUCCESS,
      FETCH_BANKING_ACCOUNTS_ERROR
    ],
    fetch: {
      url: '/api/v1/bankaccounts',
    },
    loading,
  };
}

export const SUBMIT_INCREASE_FASTLINK_COUNTER_REQUEST =
  'SUBMIT_INCREASE_FASTLINK_COUNTER_REQUEST';
export const SUBMIT_INCREASE_FASTLINK_COUNTER_SUCCESS =
  'SUBMIT_INCREASE_FASTLINK_COUNTER_SUCCESS';
export const SUBMIT_INCREASE_FASTLINK_COUNTER_ERROR =
  'SUBMIT_INCREASE_FASTLINK_COUNTER_ERROR';

export function increaseFastlinkLoginCount(data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [
      SUBMIT_INCREASE_FASTLINK_COUNTER_REQUEST,
      SUBMIT_INCREASE_FASTLINK_COUNTER_SUCCESS,
      SUBMIT_INCREASE_FASTLINK_COUNTER_ERROR
    ],
    fetch: {
      url: '/Users/incrementFastLinkAccessCounter',
      options: { method: 'post', body: JSON.stringify(data) },
    },
  };
}

export const SUBMIT_BANK_ACCOUNT_REQUEST = 'SUBMIT_BANK_ACCOUNT_REQUEST';
export const SUBMIT_BANK_ACCOUNT_SUCCESS = 'SUBMIT_BANK_ACCOUNT_SUCCESS';
export const SUBMIT_BANK_ACCOUNT_ERROR = 'SUBMIT_BANK_ACCOUNT_ERROR';

export function submitBankAccount(data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [
      SUBMIT_BANK_ACCOUNT_REQUEST,
      SUBMIT_BANK_ACCOUNT_SUCCESS,
      SUBMIT_BANK_ACCOUNT_ERROR
    ],
    fetch: {
      url: '/api/v1/bankaccounts',
      options: { method: 'post', body: JSON.stringify(data) },
    },
  };
}

export const SUBMIT_BANK_ACCOUNT_DOCUMENTATION_REQUEST =
  'SUBMIT_BANK_ACCOUNT_DOCUMENTATION_REQUEST';
export const SUBMIT_BANK_ACCOUNT_DOCUMENTATION_SUCCESS =
  'SUBMIT_BANK_ACCOUNT_DOCUMENTATION_SUCCESS';
export const SUBMIT_BANK_ACCOUNT_DOCUMENTATION_ERROR =
  'SUBMIT_BANK_ACCOUNT_DOCUMENTATION_ERROR';

export function submitBankAccountDocumentation(bankId, file) {
  return state => {
    return {
      types: [
        SUBMIT_BANK_ACCOUNT_DOCUMENTATION_REQUEST,
        SUBMIT_BANK_ACCOUNT_DOCUMENTATION_SUCCESS,
        SUBMIT_BANK_ACCOUNT_DOCUMENTATION_ERROR
      ],
      fetch: {
        url: `/Users/${state.userId}/BankAccounts/${bankId}/Documents`,
        options: {
          method: 'post',
          body: file,
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      },
    };
  };
}

export const DELETE_LINKED_ACCOUNT_REQUEST = 'DELETE_LINKED_ACCOUNT_REQUEST';
export const DELETE_LINKED_ACCOUNT_SUCCESS = 'DELETE_LINKED_ACCOUNT_SUCCESS';
export const DELETE_LINKED_ACCOUNT_ERROR = 'DELETE_LINKED_ACCOUNT_ERROR';

export function deleteLinkedAccount(bankId) {
  return {
    types: [
      DELETE_LINKED_ACCOUNT_REQUEST,
      DELETE_LINKED_ACCOUNT_SUCCESS,
      DELETE_LINKED_ACCOUNT_ERROR
    ],
    fetch: {
      url: `/api/v1/bankaccounts/${bankId}`,
      options: { method: 'delete' },
    },
  };
}

export const SUBMIT_BANK_ACCOUNT_AUTOMATIC_REQUEST =
  'SUBMIT_BANK_ACCOUNT_AUTOMATIC_REQUEST';
export const SUBMIT_BANK_ACCOUNT_AUTOMATIC_SUCCESS =
  'SUBMIT_BANK_ACCOUNT_AUTOMATIC_SUCCESS';
export const SUBMIT_BANK_ACCOUNT_AUTOMATIC_ERROR =
  'SUBMIT_BANK_ACCOUNT_AUTOMATIC_ERROR';

export function submitBankAccountAutomatic(data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [
      SUBMIT_BANK_ACCOUNT_AUTOMATIC_REQUEST,
      SUBMIT_BANK_ACCOUNT_AUTOMATIC_SUCCESS,
      SUBMIT_BANK_ACCOUNT_AUTOMATIC_ERROR
    ],
    fetch: {
      api: 'fastlink',
      url: '/',
      options: { method: 'post', body: JSON.stringify(data) },
    },
  };
}

export const FETCH_FASTLINK_SESSION_REQUEST = 'FETCH_FASTLINK_SESSION_REQUEST';
export const FETCH_FASTLINK_SESSION_SUCCESS = 'FETCH_FASTLINK_SESSION_SUCCESS';
export const FETCH_FASTLINK_SESSION_ERROR = 'FETCH_FASTLINK_SESSION_ERROR';
export function fetchYodleeFrame() {
  return {
    types: [
      FETCH_FASTLINK_SESSION_REQUEST,
      FETCH_FASTLINK_SESSION_SUCCESS,
      FETCH_FASTLINK_SESSION_ERROR
    ],
    fetch: {
      api: 'fastlink',
      url: '/',
    },
  };
}

export const FETCH_FASTLINK_HTML_REQUEST = 'FETCH_FASTLINK_HTML_REQUEST';
export const FETCH_FASTLINK_HTML_SUCCESS = 'FETCH_FASTLINK_HTML_SUCCESS';
export const FETCH_FASTLINK_HTML_ERROR = 'FETCH_FASTLINK_HTML_ERROR';
export function fetchYodleeHtml(data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [
      FETCH_FASTLINK_HTML_REQUEST,
      FETCH_FASTLINK_HTML_SUCCESS,
      FETCH_FASTLINK_HTML_ERROR
    ],
    fetch: {
      api: 'yodleeFastlinkUrl',
      url: '/iav/',
      options: { method: 'post', body: JSON.stringify(data) },
    },
  };
}

export const START_LINK_BANK_ACCOUNT_WORKFLOW =
  'START_LINK_BANK_ACCOUNT_WORKFLOW';
export function startLinkBankAccountWorkflow(sourceRoute) {
  return {
    type: START_LINK_BANK_ACCOUNT_WORKFLOW,
    sourceRoute,
  };
}

export const END_LINK_BANK_ACCOUNT_WORKFLOW = 'END_LINK_BANK_ACCOUNT_WORKFLOW';
export function endLinkBankAccountWorkflow(linkedAccount) {
  return {
    type: END_LINK_BANK_ACCOUNT_WORKFLOW,
    linkedAccount,
  };
}

export const CLEANUP_LINK_BANK_ACCOUNT_WORKFLOW =
  'CLEANUP_LINK_BANK_ACCOUNT_WORKFLOW';
export function cleanupLinkBankAccountWorkflow() {
  return {
    type: CLEANUP_LINK_BANK_ACCOUNT_WORKFLOW,
  };
}

export const FASTLINK_FRAME_LOAD_SUCCESS = 'FASTLINK_FRAME_LOAD_SUCCESS';
export function yodleeLoadCompleted(yodleeLoadComplete) {
  return {
    type: FASTLINK_FRAME_LOAD_SUCCESS,
    yodleeLoadComplete,
  };
}

export const FASTLINK_FAILURE = 'FASTLINK_FAILURE';
export function fastlinkFailure() {
  return {
    type: FASTLINK_FAILURE,
  };
}

export const FETCH_PLAID_HTML_REQUEST = 'FETCH_PLAID_HTML_REQUEST';
export const FETCH_PLAID_HTML_SUCCESS = 'FETCH_PLAID_HTML_SUCCESS';
export const FETCH_PLAID_HTML_ERROR = 'FETCH_PLAID_HTML_ERROR';
export function fetchPlaidIAV() {
  return {
    types: [
      FETCH_PLAID_HTML_REQUEST,
      FETCH_PLAID_HTML_SUCCESS,
      FETCH_PLAID_HTML_ERROR
    ],
    fetch: {
      api: 'plaidUrl',
      url: '/interface/',
      options: { method: 'get' },
    },
  };
}

export const SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_REQUEST =
  'SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_REQUEST';
export const SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_SUCCESS =
  'SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_SUCCESS';
export const SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_ERROR =
  'SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_ERROR';

export function submitBankAccountAutomaticFromPlaid(data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [
      SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_REQUEST,
      SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_SUCCESS,
      SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_ERROR
    ],
    fetch: {
      api: 'plaidUrl',
      url: '/submitBankAccountAutomatic',
      options: { method: 'post', body: JSON.stringify(data) },
    },
  };
}

export const SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_REQUEST_V1 =
  'SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_REQUEST_V1';
export const SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_SUCCESS_V1 =
  'SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_SUCCESS_V1';
export const SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_ERROR_V1 =
  'SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_ERROR_V1';

export function submitBankAccountAutomaticFromPlaidEx(data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [
      SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_REQUEST_V1,
      SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_SUCCESS_V1,
      SUBMIT_BANK_ACCOUNT_AUTOMATIC_FROM_PLAID_ERROR_V1
    ],
    fetch: {
      api: 'plaidUrl',
      url: '/v1/submitBankAccountAutomatic',
      options: { method: 'post', body: JSON.stringify(data) },
    },
  };
}


export const PLAIDLINK_FAILURE = 'PLAIDLINK_FAILURE';
export function plaidlinkFailure(plaidError) {
  return {
    type: PLAIDLINK_FAILURE,
    plaidError,
  };
}

export const SUBMIT_PLAID_EVENT_REQUEST =
  'SUBMIT_PLAID_EVENT_REQUEST';
export const SUBMIT_PLAID_EVENT_SUCCESS =
  'SUBMIT_PLAID_EVENT_SUCCESS';
export const SUBMIT_PLAID_EVENT_ERROR =
  'SUBMIT_PLAID_EVENT_ERROR';

export function submitPlaidEvent(data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [
      SUBMIT_PLAID_EVENT_REQUEST,
      SUBMIT_PLAID_EVENT_SUCCESS,
      SUBMIT_PLAID_EVENT_ERROR
    ],
    fetch: {
      api: 'carma',
      url: '/plaid/log',
      options: { method: 'post', body: JSON.stringify(data) },
    },
  };
}

export const SUBMIT_UPDATE_LINKED_ACCOUNT_NICK_NAME = 'SUBMIT_UPDATE_LINKED_ACCOUNT_NICK_NAME';
export const SUBMIT_UPDATE_LINKED_ACCOUNT_NICK_NAME_SUCCESS = 'SUBMIT_UPDATE_LINKED_ACCOUNT_NICK_NAME_SUCCESS';
export const SUBMIT_UPDATE_LINKED_ACCOUNT_NICK_NAME_ERROR = 'SUBMIT_UPDATE_LINKED_ACCOUNT_NICK_NAME_ERROR';

export function updateLinkedBankAccountNickName(bankId, nickName) {
  return {
    types: [
      SUBMIT_UPDATE_LINKED_ACCOUNT_NICK_NAME,
      SUBMIT_UPDATE_LINKED_ACCOUNT_NICK_NAME_SUCCESS,
      SUBMIT_UPDATE_LINKED_ACCOUNT_NICK_NAME_ERROR
    ],
    fetch: {
      url: `/api/v1/bankaccounts/${bankId}`,
      options: {
        method: 'put',
        body: JSON.stringify({ BankAccountNickName: nickName }),
      },
    },
  };
}
