import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import RippleButton from '../Forms/RippleButton';
import Header from '../Header';
import IRAContributionConfirmation from '../IRA/IRAContributionConfirmation';
import IRADistributionConfirmation from '../IRA/IRADistributionConfirmation';

/* eslint-disable */
const normalize = (value) => {
  if (!value || !isNaN(value)) return value;
    const cleanValue = parseFloat(value.replace(/\./g, '').replace(/,/g, ''));
    const result = (cleanValue / 100).toFixed(2);
    return result;
  }

const convertAmountToNumber = (amount) => {
  return Number(normalize(amount)).toFixed(2);
}

const formatAmount = (formValues) => {
  formValues.transferAmount = convertAmountToNumber(formValues.transferAmount);
}

const getSelectedFrequencyTooltipHtml = (props) => {
  let selectedFrequencyTooltipHtml = '';
  const selectedFrequencyKey = props.formValues.values.frequency;
  if (selectedFrequencyKey) {
    const selectedFrequency = props.frequencyOptions.find((frequency) =>
      selectedFrequencyKey && frequency.Key.toString() === selectedFrequencyKey);
    selectedFrequencyTooltipHtml =
      <p><strong>{selectedFrequency.Value}: </strong>{selectedFrequency.Description}</p>;
  }
  return selectedFrequencyTooltipHtml;
}

const SmartTransferConfirmation = (props) => {
  const { handleSubmit, onConfirmCanceled, isOneTimeTransfer, scheduledDate } = props;
  const { transferAmount, transferCurrency, frequency } = props.formValues.values;

  const frequencyOption = props.frequencyOptions
    .find(m => m.Key.toString() === frequency.toString()).Value;

  return (
    <form id="cash-ach-confirmation" onSubmit={handleSubmit} className="confirmation">
      <Header
        title="Verify Transfer Details"
      />
      <dl>
        <dt>Transfer Method:</dt>
        <dd>Smart Transfer</dd>
      </dl>
      <dl>
        <dt>From:</dt>
        <dd>{props.selectedOriginAccount.displayName}</dd>
      </dl>
      <dl>
        <dt>To:</dt>
        <dd>{props.selectedDestinationAccount.displayName}</dd>
      </dl>
      <dl>
        <dt>Amount:</dt>
        {props.transferAccountBalance ?
          <dd>All Available Cash</dd> :
          <dd>
            {(transferCurrency === 'CAD') ?
              transferAmount.toLocaleString('en-US') :
              transferAmount.toLocaleString('en-US', { style: 'currency', currency: transferCurrency })
            } {(transferCurrency === 'CAD') ? 'C$' : transferCurrency}
          </dd>}
      </dl>
      {props.selectedOriginAccount.isIRA &&
        <IRADistributionConfirmation {...props} />}
      {props.selectedDestinationAccount.isIRA &&
        <IRAContributionConfirmation {...props} />}
      <dl>
        <dt>Frequency:</dt>
        <dd>{frequencyOption}</dd>
      </dl>
      {isOneTimeTransfer ?
        <dl>
          <dt>Schedule Date:</dt>
          <dd>{scheduledDate.toDate().toLocaleDateString()}</dd>
        </dl> :
        <dl>
          <div id="selectedFrequencyText">
            {getSelectedFrequencyTooltipHtml(props)}
          </div>
        </dl>
      }
      <div className="actions">
        <RippleButton
          id="edit"
          onClick={() => onConfirmCanceled(props.form)}
          className="default"
          type="button"
        >
          edit
        </RippleButton>
        <RippleButton onClick={formatAmount(props.formValues.values)}>confirmation</RippleButton>
      </div>
      <div>
        <p>
          <strong>Note: </strong> Scheduled Transfers that occur on non-banking days will
          be processed on the next banking day, following the selected date.
        </p>
      </div>
    </form>
  );
};

SmartTransferConfirmation.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onConfirmCanceled: PropTypes.func.isRequired,
  isOneTimeTransfer: PropTypes.bool,
  selectedDestinationAccount: PropTypes.shape(),
  selectedOriginAccount: PropTypes.shape(),
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      transferAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      transferCurrency: PropTypes.string,
      frequency: PropTypes.isRequired,
    }),
  }),
  scheduledDate: PropTypes.shape({
    date: PropTypes.func,
  }),
  form: PropTypes.string,
  transferAccountBalance: PropTypes.bool,
  frequencyOptions: PropTypes.arrayOf(PropTypes.shape()),
};

export default reduxForm({
  form: 'cashACHForm',
  destroyOnUnmount: false,
})(SmartTransferConfirmation);
