import log from '../logging/logMsg';
/* eslint-disable import/prefer-default-export */
export function sendEvent(event) {
  const updatedEvent = {
    ...event,
    timestamp: new Date().toISOString(),
  };

  window.parent.postMessage(updatedEvent, '*');

  const eventToLog = {
    ...updatedEvent,
    userId: sessionStorage.getItem('userId'),
  };
  
  log(eventToLog);
}
