import './loggly';

const { newrelic, _LTracker } = window;

export default error => {
  if (newrelic) {
    newrelic.noticeError(error);
  }

  if (_LTracker) {
    _LTracker.push(error);
  }
};
