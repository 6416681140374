export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';


export const showModal = (details) => ({
  type: SHOW_MODAL,
  payload: details,
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});
