import { FETCH_LOOKUP_SUCCESS } from '../actions/lookupActions';

const parseIdentifiers = data => {
  const result = { };
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      result[key] = result[key] || { };
      data[key].forEach((node) => {
        result[key][node.Value] = node.Key.toString();
      });
    }
  });
  return result;
};

export default function (state = { }, action) {
  let result = {};
  switch (action.type) {
    case FETCH_LOOKUP_SUCCESS: {
      result = Object.assign({ everLoaded: true }, action.payload);
      result.identifiers = parseIdentifiers(result);
      return result;
    }
    default:
      return state;
  }
}
