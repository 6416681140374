const brokerageAccounts = [
  {
    Fdcnid: 99999,
    AccountNumber: 'FLOA5VrZ/b5aVi0MLTzoiw==',
    AccountName: 'Oki Matsumoto',
    AccountNickname: 'Oki Matsumoto',
    FirstName: 'Oki',
    LastName: 'Matsumoto',
    Email: 'OMatsumoto@tradestation.com',
    BrokerAccountType: 'Trust',
    TradeAccountType: 'Margin',
    AssetType: 'EQ',
    RepId: 'FTR9',
    FuturesPlusAccount: false,
    AccountSummary: {
      RealtimeAccountBalance: 0,
    },
    LinkedBankAccounts: {
      BankAccounts: [
        { BankId: 123 },
        { BankId: 124 },
      ],
    },
    Address: {
      Address1: '1234 Main St',
      Address2: 'Suite 100',
      City: 'Plantation',
      State: 'FL',
      Zip: '33324',
      Country: 'US',
    },
    AllowedCurrency: {
      CurrencyCodes: [
        { Currency: 'USD' },
      ],
    },
    AccountBalance: {
      AccountNumber: '21010416',
      Currency: 'USD',
      Balance: 0,
      OpenTradeEquity: 0,
      TotalEquity: 0,
      NetLiquidatingValue: 143990,
      MarketValue: 20000,
      InitialMargin: 0,
    },
    AvailableToWithdraw: {
      ByCurrency: [
        {
          Currency: 'USD',
          Realtime: 123990,
          BeginningOfDay: 123990,
          ExchangeRate: 1,
        },
        {
          Currency: 'CAD',
          Realtime: 123990,
          BeginningOfDay: 123990,
          ExchangeRate: 1,
        },
      ],
    },
    CanWithholdStateTax: true,
  },
  {
    Fdcnid: 99999,
    AccountNumber: 'VVqw6ehHobfmcvBmA7CDGQ==',
    AccountName: 'Oki Matsumoto',
    AccountNickname: 'Oki Matsumoto',
    FirstName: 'Oki',
    LastName: 'Matsumoto',
    Email: 'OMatsumoto@tradestation.com',
    BrokerAccountType: 'Individual',
    TradeAccountType: 'IRA',
    IRATypeId: 227,
    AssetType: 'EQ',
    RepId: 'TR9',
    FuturesPlusAccount: false,
    AccountSummary: { RealtimeAccountBalance: -82476.89 },
    LinkedBankAccounts: {
      BankAccounts: [
        { BankId: 123 },
      ],
    },
    Address: {
      Address1: '1234 Main St',
      Address2: 'Suite 100',
      City: 'Plantation',
      State: 'FL',
      Zip: '33324',
      Country: 'US',
    },
    AllowedCurrency: {
      CurrencyCodes: [
        { Currency: 'USD' },
      ],
    },
    AccountBalance: {
      AccountNumber: '21010416',
      Currency: 'USD',
      Balance: 1125000.6675,
      OpenTradeEquity: 0,
      TotalEquity: 1125000.6675,
      NetLiquidatingValue: 1125000,
      MarketValue: 100000,
      InitialMargin: 0.00,
    },
    AvailableToWithdraw: {
      ByCurrency: [
        {
          Currency: 'USD',
          Realtime: 1025000.88,
          BeginningOfDay: 1025000,
          ExchangeRate: 1,
        },
        {
          Currency: 'CAD',
          Realtime: 1025000.88,
          BeginningOfDay: 1025000,
          ExchangeRate: 1,
        },
      ],
    },
    CanWithholdStateTax: true,
  },
  {
    Fdcnid: 99999,
    AccountNumber: 'Ua7ckonLpKoh+iaCID495w==',
    AccountName: 'Oki Matsumoto',
    AccountNickname: 'Oki Matsumoto',
    FirstName: 'Oki',
    LastName: 'Matsumoto',
    Email: 'OMatsumoto@tradestation.com',
    BrokerAccountType: 'Individual',
    TradeAccountType: 'Futures',
    AssetType: 'FU',
    RepId: 'FTR9',
    FuturesPlusAccount: false,
    AccountSummary: { RealtimeAccountBalance: 2929 },
    LinkedBankAccounts: {
      BankAccounts: [
        { BankId: 123 },
      ],
    },
    Address: {
      Address1: '1234 Main St',
      Address2: 'Suite 100',
      City: 'Plantation',
      State: 'FL',
      Zip: '33324',
      Country: 'US',
    },
    AllowedCurrency: {
      CurrencyCodes: [
        { Currency: 'AUD' },
        { Currency: 'CAD' },
        { Currency: 'CHF' },
        { Currency: 'EUR' },
        { Currency: 'GBP' },
        { Currency: 'HKD' },
        { Currency: 'JPY' },
        { Currency: 'SGD' },
        { Currency: 'USD' },
      ],
    },
    AccountBalance: {
      AccountNumber: '21010416',
      Currency: 'USD',
      Balance: 16707.6675,
      OpenTradeEquity: 0,
      TotalEquity: 16707.6675,
      NetLiquidatingValue: 100000,
      MarketValue: 3000,
      InitialMargin: 25766.86,
    },
    AvailableToWithdraw: {
      ByCurrency: [
        {
          Currency: 'GBP',
          Realtime: 74157.96,
          BeginningOfDay: 74157.96,
          ExchangeRate: 2,
        },
        {
          Currency: 'JPY',
          Realtime: 10917495.50,
          BeginningOfDay: 10917495.50,
          ExchangeRate: 0.0002125,
        },
        {
          Currency: 'USD',
          Realtime: 97000,
          BeginningOfDay: 97000.00,
          ExchangeRate: 1,
        },
        {
          Currency: 'CAD',
          Realtime: 126898.79,
          BeginningOfDay: 126898.79,
          ExchangeRate: 1.31,
        },
      ],
    },
    CanWithholdStateTax: true,
  },
  {
    Fdcnid: 99999,
    AccountNumber: '8E6uSEXFhnjRwSiW+easwA==',
    AccountName: 'Oki Matsumoto',
    AccountNickname: 'Oki Matsumoto',
    FirstName: 'Oki',
    LastName: 'Matsumoto',
    Email: 'OMatsumoto@tradestation.com',
    BrokerAccountType: 'Individual',
    TradeAccountType: 'IRA',
    IRATypeId: 226,
    AssetType: 'FU',
    RepId: 'TR9',
    FuturesPlusAccount: false,
    AccountSummary: { RealtimeAccountBalance: -82476.89 },
    LinkedBankAccounts: {
      BankAccounts: [
        { BankId: 123 },
      ],
    },
    Address: {
      Address1: '1234 Main St',
      Address2: 'Suite 100',
      City: 'Plantation',
      State: 'FL',
      Zip: '33324',
      Country: 'US',
    },
    AllowedCurrency: {
      CurrencyCodes: [
        { Currency: 'USD' },
      ],
    },
    AccountBalance: {
      AccountNumber: '21010416',
      Currency: 'USD',
      Balance: 8416707.6675,
      OpenTradeEquity: 0,
      TotalEquity: 8416707.6675,
      NetLiquidatingValue: 8416707.6675,
      MarketValue: 2000000,
      InitialMargin: 25766.86,
    },
    AvailableToWithdraw: {
      ByCurrency: [
        {
          Currency: 'GBP',
          Realtime: 412.5,
          BeginningOfDay: 412.5,
          ExchangeRate: 2,
        },
        {
          Currency: 'JPY',
          Realtime: 38750.0002,
          BeginningOfDay: 38750.0002,
          ExchangeRate: 0.0002125,
        },
        {
          Currency: 'USD',
          Realtime: 6416707.6675,
          BeginningOfDay: 6416707.6675,
          ExchangeRate: 1,
        },
      ],
    },
    CanWithholdStateTax: true,
  },
  {
    Fdcnid: 99999,
    AccountNumber: '17276670',
    AccountName: 'Oki Matsumoto',
    AccountNickname: 'Oki Matsumoto',
    FirstName: 'Oki',
    LastName: 'Matsumoto',
    Email: 'OMatsumoto@tradestation.com',
    BrokerAccountType: 'Individual',
    TradeAccountType: 'Futures',
    IRATypeId: 226,
    AssetType: 'FU',
    RepId: 'TR9',
    FuturesPlusAccount: true,
    AccountSummary: { RealtimeAccountBalance: -92476.89 },
    LinkedBankAccounts: {
      BankAccounts: [
        { BankId: 123 },
      ],
    },
    Address: {
      Address1: '1234 Main St',
      Address2: 'Suite 100',
      City: 'Plantation',
      State: 'FL',
      Zip: '33324',
      Country: 'US',
    },
    AllowedCurrency: {
      CurrencyCodes: [
        { Currency: 'AUD' },
        { Currency: 'CAD' },
        { Currency: 'CHF' },
        { Currency: 'EUR' },
        { Currency: 'GBP' },
        { Currency: 'HKD' },
        { Currency: 'JPY' },
        { Currency: 'SGD' },
        { Currency: 'USD' },
      ],
    },
    AccountBalance: {
      AccountNumber: '21010416',
      Currency: 'USD',
      Balance: 8416707.6675,
      OpenTradeEquity: 0,
      TotalEquity: 8416707.6675,
      NetLiquidatingValue: 8416707.6675,
      MarketValue: 500000,
      InitialMargin: 3766.86,
    },
    AvailableToWithdraw: {
      ByCurrency: [
        {
          Currency: 'GBP',
          Realtime: 0,
          BeginningOfDay: 0,
          ExchangeRate: 2,
        },
        {
          Currency: 'JPY',
          Realtime: 0,
          BeginningOfDay: 0,
          ExchangeRate: 0.0002125,
        },
        {
          Currency: 'USD',
          Realtime: 0,
          BeginningOfDay: 0,
          ExchangeRate: 1,
        },
      ],
    },
    CanWithholdStateTax: true,
  },
];

const bankAccounts = [
  {
    BankId: 123,
    BankName: 'Bank of America',
    BankAccountNumber: 'xx0001',
    RoutingNumber: '123456789',
    BankAccountTitle: 'Oki Matsumoto',
    BankAccountType: 'Savings',
    BankAccountNickName: null,
    IsDomesticAccount: false,
    LinkedBrokerageAccounts: {
      Accounts: [
        { AccountNumber: '17232446' },
        { AccountNumber: '17272068' },
        { AccountNumber: '210E4099' },
        { AccountNumber: '17272070' },
      ],
    },
  },
  {
    BankId: 124,
    BankAccountNumber: 'xx0002',
    BankAccountTitle: 'Oki Matsumoto',
    BankAccountType: 'Checking',
    BankName: 'Bank of America',
    RoutingNumber: '123456789',
    LinkedBrokerageAccounts: {
      Accounts: [
        { AccountNumber: '17232446' },
      ],
    },
  },
  {
    BankId: 125,
    BankAccountNumber: 'xx0003',
    BankAccountTitle: 'Oki Matsumoto',
    BankAccountType: 'Checking',
    BankName: 'Bank of America',
    RoutingNumber: '123456789',
    LinkedBrokerageAccounts: {
      Accounts: [],
    },
  },
];

const achTransfers = {
  ACHTransfers: [
    {
      ClientReferenceId: '20170713203636',
      AccountNumber: '17232446',
      FdcnId: 99999,
      BankId: 124,
      BankAccountNumber: 'xx0002',
      BankRoutingNumber: '123456789',
      BankAccountType: 'Savings',
      Amount: 10000,
      Direction: 'Credit',
      CurrencyCode: 'USD',
      TransferAccountBalance: false,
      RequestReceivedDate: '2017-07-13T20:36:36.81+00:00',
      Status: 'Submitted',
    },
    {
      ClientReferenceId: '20170713203637',
      AccountNumber: '210E4099',
      FdcnId: 99999,
      BankId: 123,
      BankAccountNumber: 'xx0001',
      BankRoutingNumber: '123456789',
      BankAccountType: 'Savings',
      Amount: 1000,
      Direction: 'Debit',
      CurrencyCode: 'USD',
      TransferAccountBalance: false,
      RequestReceivedDate: '2017-07-13T20:36:37.81+00:00',
      Status: 'Submitted',
    },
    {
      ClientReferenceId: '20170713203642',
      AccountNumber: '17272068',
      FdcnId: 99999,
      BankId: 123,
      BankAccountNumber: 'xx0001',
      BankRoutingNumber: '123456789',
      BankAccountType: 'Savings',
      Amount: 1999,
      Direction: 'Credit',
      CurrencyCode: 'USD',
      TransferAccountBalance: false,
      RequestReceivedDate: '2017-07-13T20:36:42.81+00:00',
      Status: 'Submitted',
    },
    {
      ClientReferenceId: '20170713203649',
      AccountNumber: '17232446',
      FdcnId: 99999,
      BankId: 123,
      BankAccountNumber: 'xx0001',
      BankRoutingNumber: '123456789',
      BankAccountType: 'Savings',
      Amount: 20000,
      Direction: 'Debit',
      CurrencyCode: 'USD',
      TransferAccountBalance: false,
      RequestReceivedDate: '2017-07-13T20:36:42.81+00:00',
      Status: 'Submitted',
    },
  ],
};

const achTransfer = (requestId) => {
  return achTransfers.ACHTransfers.find(transfer => transfer.ClientReferenceId === requestId);
};

const checkTransfers = {
  Checks: [
    {
      ClientReferenceId: '20170713203638',
      FdcnId: 99999,
      AccountNumber: '210E4099',
      Direction: 'Debit',
      Amount: 2000,
      DeliveryType: 'Regular',
      CurrencyCode: 'USD',
      RequestReceivedDate: '2017-07-13T20:36:38.81+00:00',
      LastUpdated: '2017-07-13T20:36:38.82+00:00',
      Status: 'Submitted',
      TransferAccountBalance: false,
    },
    {
      ClientReferenceId: '20170713203643',
      FdcnId: 99999,
      AccountNumber: '17272068',
      Direction: 'Debit',
      Amount: 2999,
      DeliveryType: 'Regular',
      CurrencyCode: 'USD',
      RequestReceivedDate: '2017-07-13T20:36:43.81+00:00',
      LastUpdated: '2017-07-13T20:36:38.82+00:00',
      Status: 'Submitted',
      TransferAccountBalance: false,
    },
  ],
};

const checkTransfer = (requestId) => {
  return checkTransfers.Checks.find(transfer => transfer.ClientReferenceId === requestId);
};

const wireTransfers = {
  Items: [
    {
      ClientReferenceId: '20170713203606',
      AccountNumber: '17232446',
      FdcnId: 99999,
      Direction: 'Debit',
      Amount: 3000,
      CurrencyCode: 'USD',
      BeneficiaryBankName: 'Wells Fargo',
      BeneficiaryBankAddress: '1234 Main St',
      BeneficiaryBankCity: 'Ft Worth',
      BeneficiaryBankState: 'TX',
      BeneficiaryBankPostalCode: '11111',
      BeneficiaryBankCountry: 'US',
      BeneficiaryBankABANumber: '11000001',
      BeneficiaryBankIBAN: '',
      BeneficiaryBankSwiftNumber: 'BARCGB22',
      BeneficiaryBankAccountTitle: 'Oki Matsumoto',
      BeneficiaryBankAccountNumber: '123456987',
      IntermediaryBankName: '',
      IntermediaryBankAddress: '',
      IntermediaryBankCity: '',
      IntermediaryBankState: '',
      IntermediaryBankPostalCode: '',
      IntermediaryBankCountry: '',
      IntermediaryBankABANumber: '',
      IntermediaryBankIBAN: '',
      IntermediaryBankSwiftNumber: '',
      FurtherCreditAccountTitle: null,
      FurtherCreditAccountNumber: null,
      TransferAccountBalance: false,
      Status: 'Submitted',
    },
    {
      ClientReferenceId: '20170713203607',
      AccountNumber: '17272068',
      FdcnId: 99999,
      Direction: 'Debit',
      Amount: 1000000,
      CurrencyCode: 'USD',
      BeneficiaryBankName: 'Wells Fargo',
      BeneficiaryBankAddress: '1234 Main St',
      BeneficiaryBankCity: 'Ft Worth',
      BeneficiaryBankPostalCode: '11111',
      BeneficiaryBankState: 'TX',
      BeneficiaryBankCountry: 'US',
      BeneficiaryBankABANumber: '',
      BeneficiaryBankIBAN: '',
      BeneficiaryBankSwiftNumber: 'BARCGB22',
      BeneficiaryBankAccountTitle: 'Oki Matsumoto',
      BeneficiaryBankAccountNumber: '123456988',
      IntermediaryBankName: 'Citibank',
      IntermediaryBankAddress: '1234 Rue Principale',
      IntermediaryBankCity: 'Paris',
      IntermediaryBankState: 'Île-de-France',
      IntermediaryBankPostalCode: '75001',
      IntermediaryBankCountry: 'FR',
      IntermediaryBankABANumber: '123',
      IntermediaryBankIBAN: '1234',
      IntermediaryBankSwiftNumber: '12345',
      FurtherCreditAccountTitle: 'Oki Matsumoto',
      FurtherCreditAccountNumber: '123456789',
      TransferAccountBalance: false,
      Status: 'Submitted',
    },
  ],
};

const wireTransfer = (requestId) => {
  return wireTransfers.Items.find(transfer => transfer.ClientReferenceId === requestId);
};

const internalTransfers = {
  Journals: [
    {
      ClientReferenceId: '20170713203641',
      FdcnId: 99999,
      FromAccountNumber: '17232446',
      FromAccountName: 'Oki Matsumoto',
      FromAccountType: 'Cash',
      ToAccountNumber: '17272068',
      ToAccountName: 'Oki Matsumoto',
      ToAccountType: 'Cash',
      Amount: 4000,
      CurrencyCode: 'USD',
      TransferAccountBalance: false,
      RequestReceivedDate: '2017-07-13T20:36:41.81+00:00',
      Status: 'Submitted',
    },
    {
      ClientReferenceId: '20170713203644',
      FdcnId: 99999,
      FromAccountNumber: '17272068',
      FromAccountName: 'Oki Matsumoto',
      FromAccountType: 'Cash',
      ToAccountNumber: '17232446',
      ToAccountName: 'Oki Matsumoto',
      ToAccountType: 'Cash',
      Amount: 4000,
      CurrencyCode: 'USD',
      TransferAccountBalance: false,
      RequestReceivedDate: '2017-07-13T20:36:44.81+00:00',
      Status: 'Submitted',
    },
  ],
};

const internalTransfer = (requestId) => {
  return internalTransfers.Journals.find(transfer => transfer.ClientReferenceId === requestId);
};

const cashierRequests = {
  Items: [
    {
      Id: 4002,
      AccountNumber: '17272068',
      AccountName: 'Oki Matsumoto',
      ClientReferenceId: '20170713203609',
      TransferType: 'Journal',
      Status: 'Completed',
      StatusType: 'Approved',
      Fdcnid: 99999,
      Amount: 4999,
      CurrencyCode: 'USD',
      RequestReceivedDate: '2017-07-13T20:36:09.81+00:00',
      LastUpdated: '2017-07-13T20:36:09.82+00:00',
    },
    {
      Id: 4001,
      AccountNumber: '17232446',
      AccountName: 'Oki Matsumoto',
      ClientReferenceId: '20170713203608',
      TransferType: 'Journal',
      Status: 'Completed',
      StatusType: 'Approved',
      Fdcnid: 99999,
      Amount: 4000,
      CurrencyCode: 'USD',
      RequestReceivedDate: '2017-07-13T20:36:08.81+00:00',
      LastUpdated: '2017-07-13T20:36:08.82+00:00',
    },
    {
      Id: 3002,
      AccountNumber: '17272068',
      AccountName: 'Oki Matsumoto',
      ClientReferenceId: '20170713203607',
      TransferType: 'Wire - Debit',
      Status: 'Declined',
      StatusType: 'Declined',
      DeclineReason: 'The title of your bank account did not match the title on your brokerage account',
      Fdcnid: 99999,
      Amount: 3999,
      CurrencyCode: 'USD',
      RequestReceivedDate: '2017-07-13T20:36:07.81+00:00',
      LastUpdated: '2017-07-13T20:36:07.82+00:00',
    },
    {
      Id: 3001,
      AccountNumber: '17232446',
      AccountName: 'Oki Matsumoto',
      ClientReferenceId: '20170713203606',
      TransferType: 'Wire - Debit',
      Status: 'Completed',
      StatusType: 'Approved',
      Fdcnid: 99999,
      Amount: 3000,
      CurrencyCode: 'USD',
      RequestReceivedDate: '2017-07-13T20:36:06.81+00:00',
      LastUpdated: '2017-07-13T20:36:06.82+00:00',
    },
    {
      Id: 2002,
      AccountNumber: '17272068',
      AccountName: 'Oki Matsumoto',
      ClientReferenceId: '20170713203605',
      TransferType: 'Check - Debit',
      Status: 'Submitted',
      StatusType: 'Submitted',
      Fdcnid: 99999,
      Amount: 2999,
      CurrencyCode: 'USD',
      RequestReceivedDate: '2017-07-13T20:36:05.81+00:00',
      LastUpdated: '2017-07-13T20:36:05.82+00:00',
    },
    {
      Id: 2001,
      AccountNumber: '210E4099',
      AccountName: 'Oki Matsumoto',
      ClientReferenceId: '20170713203604',
      TransferType: 'Check - Debit',
      Status: 'Submitted',
      StatusType: 'Submitted',
      Fdcnid: 99999,
      Amount: 2000,
      CurrencyCode: 'USD',
      RequestReceivedDate: '2017-07-13T20:36:04.81+00:00',
      LastUpdated: '2017-07-13T20:36:04.82+00:00',
    },
    {
      Id: 1003,
      AccountNumber: '17272068',
      AccountName: 'Oki Matsumoto',
      ClientReferenceId: '20170713203603',
      TransferType: 'ACH - Debit',
      Status: 'Submitted',
      StatusType: 'Submitted',
      Fdcnid: 99999,
      Amount: 1999,
      CurrencyCode: 'USD',
      RequestReceivedDate: '2017-07-13T20:36:03.81+00:00',
      LastUpdated: '2017-07-13T20:36:03.82+00:00',
    },
    {
      Id: 1002,
      AccountNumber: '210E4099',
      AccountName: 'Oki Matsumoto',
      ClientReferenceId: '20170713203602',
      TransferType: 'ACH - Debit',
      Status: 'Submitted',
      StatusType: 'Submitted',
      Fdcnid: 99999,
      Amount: 1000,
      CurrencyCode: 'USD',
      RequestReceivedDate: '2017-07-13T20:36:02.81+00:00',
      LastUpdated: '2017-07-13T20:36:02.82+00:00',
    },
    {
      Id: 1001,
      AccountNumber: '17232446',
      AccountName: 'Oki Matsumoto',
      ClientReferenceId: '20170713203601',
      TransferType: 'ACH - Credit',
      Status: 'Submitted',
      StatusType: 'Submitted',
      Fdcnid: 99999,
      Amount: 10000,
      CurrencyCode: 'USD',
      RequestReceivedDate: '2017-07-13T20:36:01.81+00:00',
      LastUpdated: '2017-07-13T20:36:01.82+00:00',
    },
  ],
  Count: 9,
  NextPageLink: `http://carma.tradestation.io:80/api/v1/CashierRequests?
                  $count=true&
                  $filter=TransferType%20ne%20%27ACH%20Relation%27&$skip=10`,
};

const scheduledTransfers = {
  Items: [
    {
      AccountName: 'LSSLNP  1086725934 Eho',
      AccountNumber: '17232446',
      Amount: 100000.25,
      CreatedBy: 'TRAD\\MRoman',
      Currency: 'USD',
      EndDate: '2099-12-31T00:00:00',
      Frequency: 'Quarterly',
      NextValueDate: '05/01/2019',
      RequestReceivedDate: '2019-04-04T14:10:30.39',
      ScheduledEventID: 27790,
      StartDate: '2019-04-04T00:00:00',
      Status: 'Active',
      Workflow: 'ACH - Deposit',
      WorkflowName: 'achcredit',
      ScheduledTransferDetails: {
        ScheduledEventID: 27790,
        AccountName: 'Lqbqdkqhr Sukri',
        AccountNumber: '17232446',
        Amount: 100000.25,
        Bank: '1ST CONSTITUTION BANK',
        BankRoutingNumber: '021207154',
        CreatedBy: 'TRAD\\KJones',
        CreatedByFDCNID: 3660918,
        Currency: 'USD',
        EndDate: '2099-12-31T00:00:00',
        Frequency: 'Monthly',
        NextValueDate: '05/01/2019',
        Params: '',
        RequestReceivedDate: '2019-04-11T09:14:29.21',
        ScheduledEventParams: {
          startDateField: '05/01/2019',
          directionField: 'Credit',
          bankIdField: 124,
          distributionTypeIdField: '0',
          fedWithholdingPercentageField: '',
          IRADistributionReasonTypeIdField: '0',
          currencyCodeField: 'USD',
          iRATypeIdField: '0',
          stateWithholdingPercentageField: '',
          fedWithholdingIdField: '',
          stateWithholdingIdField: '',
          fedWithholdingAmountField: '',
          stateWithholdingAmountField: '',
          iRADistributionYearField: '',
          directRolloverAccountTypeIdField: null,
          excessContributionYearField: null,
          iRARolloverTypeIdField: 0,
          iRAContributionYearField: null,
          iRAContributionTypeIdField: 0,
          iRADepositTypeIdField: 0,
          iRAContributionSourceTypeIdField: 0,
          isBeforeTaxDeadlineField: null,
          scheduledEventFrequencyIDField: '4',
          accountBalanceField: '0',
        },
        StartDate: '2019-04-11T00:00:00',
        Status: 'Active',
        ToAccountName: null,
        ToAccountNumber: null,
        Workflow: 'ACH - Deposit',
        WorkflowName: 'achcredit',
      },
      IsDeleted: false,
    },
    {
      ScheduledEventID: 27789,
      Workflow: 'ACH - Withdrawal',
      AccountNumber: '17272068',
      AccountName: 'Orjs Sjssrbb',
      Amount: 100000.25,
      Currency: 'USD',
      Frequency: 'One Time',
      StartDate: '2019-02-20T00:00:00',
      EndDate: '2099-12-31T00:00:00',
      NextValueDate: '',
      RequestReceivedDate: '2019-02-20T16:24:56.88',
      CreatedBy: 'TRAD\\MRoman',
      Status: 'Active',
      WorkflowName: 'achdebit',
      ScheduledTransferDetails: {
        ScheduledEventID: 27789,
        AccountName: 'Lqbqdkqhr Sukri',
        AccountNumber: '17272068',
        Amount: 100000.25,
        Bank: '1ST CONSTITUTION BANK',
        BankRoutingNumber: '021207154',
        CreatedBy: 'TRAD\\KJones',
        CreatedByFDCNID: 3660918,
        Currency: 'USD',
        EndDate: '2099-12-31T00:00:00',
        Frequency: 'Quarterly',
        NextValueDate: '07/01/2019',
        Params: '',
        RequestReceivedDate: '2019-04-11T09:14:44.39',
        ScheduledEventParams: {
          startDateField: '07/01/2019',
          directionField: 'Debit',
          bankIdField: 123,
          distributionTypeIdField: '241',
          fedWithholdingPercentageField: '2',
          IRADistributionReasonTypeIdField: '8',
          iRATypeIdField: '227',
          stateWithholdingPercentageField: '2',
          fedWithholdingIdField: 'true',
          stateWithholdingIdField: 'true',
          fedWithholdingAmountField: '',
          stateWithholdingAmountField: '',
          scheduledEventFrequencyIDField: '1',
          accountBalanceField: '0',
        },
        StartDate: '2019-04-11T00:00:00',
        Status: 'Active',
        ToAccountName: null,
        ToAccountNumber: null,
        Workflow: 'ACH - Withdrawal',
        WorkflowName: 'achdebit',
      },
      IsDeleted: false,
    },
    {
      ScheduledEventID: 27741,
      Workflow: 'Journal',
      AccountNumber: '17232446',
      AccountName: 'Mqorbbu Ybqssu',
      Amount: 110000,
      Currency: 'USD',
      Frequency: 'One Time',
      StartDate: '2019-02-20T00:00:00',
      EndDate: '2099-12-31T00:00:00',
      NextValueDate: '2019-12-07T00:00:00',
      RequestReceivedDate: '2019-02-20T17:47:48.13',
      CreatedBy: 'TRAD\\MRoman',
      Status: 'Active',
      WorkflowName: 'journal',
      ScheduledTransferDetails: {
        ScheduledEventID: 27741,
        AccountName: 'Mqorbbu Ybqssu',
        AccountNumber: '21076583',
        Amount: 110000,
        Bank: null,
        BankRoutingNumber: null,
        CreatedBy: 'TRAD\\KJones',
        CreatedByFDCNID: 3741151,
        Currency: 'CAD',
        EndDate: '2099-12-31T00:00:00',
        Frequency: 'Monthly',
        NextValueDate: '05/01/2019',
        Params: '',
        RequestReceivedDate: '2019-04-11T09:40:12.19',
        ScheduledEventParams: {
          startDateField: '05/01/2019',
          fromAccountNumberField: '17232446',
          toAccountNumberField: '17276670',
          directionField: '',
          bankIdField: 124,
          distributionTypeIdField: '0',
          fedWithholdingPercentageField: '',
          IRADistributionReasonTypeIdField: '0',
          currencyCodeField: 'USD',
          iRATypeIdField: '0',
          stateWithholdingPercentageField: '',
          fedWithholdingIdField: '',
          stateWithholdingIdField: '',
          fedWithholdingAmountField: '',
          stateWithholdingAmountField: '',
          iRADistributionYearField: '',
          directRolloverAccountTypeIdField: null,
          excessContributionYearField: null,
          iRARolloverTypeIdField: 0,
          iRAContributionYearField: null,
          iRAContributionTypeIdField: 0,
          iRADepositTypeIdField: 0,
          iRAContributionSourceTypeIdField: 0,
          isBeforeTaxDeadlineField: null,
          scheduledEventFrequencyIDField: '4',
          accountBalanceField: '0',
        },
        StartDate: '2019-04-11T00:00:00',
        Status: 'Active',
        ToAccountName: 'Prdjbqr Oghdquh Doqgfg',
        ToAccountNumber: '2102499P',
        Workflow: 'Journal',
        WorkflowName: 'journal',
      },
      IsDeleted: false,
    },
  ],
  Count: 3,
  NextPageLink: null,
};

const getScheduledTransfers = () => {
  return {
    Items: scheduledTransfers.Items.filter(x => !x.IsDeleted),
  };
};

const cancelScheduledTransfer = (scheduledId) => {
  const scheduled = scheduledTransfers.Items.find(x => x.ScheduledEventID === Number(scheduledId));
  scheduled.IsDeleted = true;
  return scheduled;
};

const scheduledTransferDetails = [
  {
    ScheduledEventID: 27790,
    AccountName: 'Lqbqdkqhr Sukri',
    AccountNumber: '17232446',
    Amount: 100000.25,
    Bank: '1ST CONSTITUTION BANK',
    BankRoutingNumber: '021207154',
    CreatedBy: 'TRAD\\KJones',
    CreatedByFDCNID: 3660918,
    Currency: 'USD',
    EndDate: '2099-12-31T00:00:00',
    Frequency: 'Monthly',
    NextValueDate: '05/01/2019',
    Params: '',
    RequestReceivedDate: '2019-04-11T09:14:29.21',
    ScheduledEventParams: {
      startDateField: '05/01/2019',
      directionField: 'Credit',
      bankIdField: 124,
      distributionTypeIdField: '0',
      fedWithholdingPercentageField: '',
      IRADistributionReasonTypeIdField: '0',
      currencyCodeField: 'USD',
      iRATypeIdField: '0',
      stateWithholdingPercentageField: '',
      fedWithholdingIdField: '',
      stateWithholdingIdField: '',
      fedWithholdingAmountField: '',
      stateWithholdingAmountField: '',
      iRADistributionYearField: '',
      directRolloverAccountTypeIdField: null,
      excessContributionYearField: null,
      iRARolloverTypeIdField: 0,
      iRAContributionYearField: null,
      iRAContributionTypeIdField: 0,
      iRADepositTypeIdField: 0,
      iRAContributionSourceTypeIdField: 0,
      isBeforeTaxDeadlineField: null,
      scheduledEventFrequencyIDField: '4',
      accountBalanceField: '0',
    },
    StartDate: '2019-04-11T00:00:00',
    Status: 'Active',
    ToAccountName: null,
    ToAccountNumber: null,
    Workflow: 'ACH - Deposit',
    WorkflowName: 'achcredit',
  },
  {
    ScheduledEventID: 27789,
    AccountName: 'Lqbqdkqhr Sukri',
    AccountNumber: '17272068',
    Amount: 100000.25,
    Bank: '1ST CONSTITUTION BANK',
    BankRoutingNumber: '021207154',
    CreatedBy: 'TRAD\\KJones',
    CreatedByFDCNID: 3660918,
    Currency: 'USD',
    EndDate: '2099-12-31T00:00:00',
    Frequency: 'Quarterly',
    NextValueDate: '07/01/2019',
    Params: '',
    RequestReceivedDate: '2019-04-11T09:14:44.39',
    ScheduledEventParams: {
      startDateField: '07/01/2019',
      directionField: 'Debit',
      bankIdField: 123,
      distributionTypeIdField: '241',
      fedWithholdingPercentageField: '2',
      IRADistributionReasonTypeIdField: '8',
      iRATypeIdField: '227',
      stateWithholdingPercentageField: '2',
      fedWithholdingIdField: 'true',
      stateWithholdingIdField: 'true',
      fedWithholdingAmountField: '',
      stateWithholdingAmountField: '',
      scheduledEventFrequencyIDField: '1',
      accountBalanceField: '0',
    },
    StartDate: '2019-04-11T00:00:00',
    Status: 'Active',
    ToAccountName: null,
    ToAccountNumber: null,
    Workflow: 'ACH - Withdrawal',
    WorkflowName: 'achdebit',
  },
  {
    ScheduledEventID: 1321,
    AccountName: 'LSSLNP  1086725934 Eho',
    AccountNumber: '17232446',
    Amount: 100000,
    Bank: null,
    BankRoutingNumber: null,
    CreatedBy: '4PMD4LORDN9JD1G',
    CreatedByFDCNID: 2140475,
    Currency: 'USD',
    EndDate: '2099-12-31T00:00:00',
    Frequency: 'Twice Monthly',
    NextValueDate: '',
    Params: '',
    RequestReceivedDate: '2019-04-04T11:50:54.607',
    ScheduledEventParams: {
      startDateField: '2019-04-28',
      clientReferenceIdField: '20170713203607',
      beneficiaryBankAccountNumberField: '1224',
      beneficiaryBankNameField: '1ST COLONIAL COMMUNITY BANK',
      distributionTypeIdField: '241',
      beneficiaryBankIBANField: '',
      beneficiaryBankAddressField: null,
      beneficiaryBankCityField: 'COLLINGSWOOD',
      beneficiaryBankStateField: 'NJ',
      beneficiaryBankPostalCodeField: '1111',
      beneficiaryBankCountryField: 'US',
      beneficiaryBankSwiftNumberField: '',
      beneficiaryBankABANumberField: '031207869',
      beneficiaryBankAccountTitleField: 'Babe Ruth',
      currencyCodeField: 'USD',
      intermediaryBankNameField: null,
      intermediaryBankAddressField: null,
      intermediaryBankCityField: null,
      intermediaryBankStateField: null,
      intermediaryBankPostalCodeField: '1111',
      intermediaryBankCountryField: null,
      intermediaryBankSwiftNumberField: '',
      intermediaryBankABANumberField: '',
      intermediaryBankIBANField: null,
      furtherCreditAccountTitleField: 'Test',
      furtherCreditAccountNumberField: '123456789',
      scheduledEventFrequencyIDField: '3',
      accountBalanceField: '0',
      fedWithholdingPercentageField: '',
      IRADistributionReasonTypeIdField: '7',
      iRATypeIdField: '0',
      stateWithholdingPercentageField: '',
      fedWithholdingIdField: '',
      stateWithholdingIdField: '',
      stateWithholdingAmountField: '',
      iRADistributionYearField: '',
      directRolloverAccountTypeIdField: null,
      excessContributionYearField: null,
      fedWithholdingAmountField: '',
      isBeforeTaxDeadlineField: null,
    },
    StartDate: '2019-04-06T00:00:00',
    Status: 'Completed',
    ToAccountName: null,
    ToAccountNumber: null,
    Workflow: 'Wire - Withdrawal',
    WorkflowName: 'wiredebit',
  },
  {
    ScheduledEventID: 27741,
    AccountName: 'Mqorbbu Ybqssu',
    AccountNumber: '21076583',
    Amount: 110000,
    Bank: null,
    BankRoutingNumber: null,
    CreatedBy: 'TRAD\\KJones',
    CreatedByFDCNID: 3741151,
    Currency: 'CAD',
    EndDate: '2099-12-31T00:00:00',
    Frequency: 'Monthly',
    NextValueDate: '05/01/2019',
    Params: '',
    RequestReceivedDate: '2019-04-11T09:40:12.19',
    ScheduledEventParams: {
      startDateField: '05/01/2019',
      fromAccountNumberField: '17232446',
      toAccountNumberField: '17276670',
      directionField: '',
      bankIdField: 124,
      distributionTypeIdField: '0',
      fedWithholdingPercentageField: '',
      IRADistributionReasonTypeIdField: '0',
      currencyCodeField: 'USD',
      iRATypeIdField: '0',
      stateWithholdingPercentageField: '',
      fedWithholdingIdField: '',
      stateWithholdingIdField: '',
      fedWithholdingAmountField: '',
      stateWithholdingAmountField: '',
      iRADistributionYearField: '',
      directRolloverAccountTypeIdField: null,
      excessContributionYearField: null,
      iRARolloverTypeIdField: 0,
      iRAContributionYearField: null,
      iRAContributionTypeIdField: 0,
      iRADepositTypeIdField: 0,
      iRAContributionSourceTypeIdField: 0,
      isBeforeTaxDeadlineField: null,
      scheduledEventFrequencyIDField: '4',
      accountBalanceField: '0',
    },
    StartDate: '2019-04-11T00:00:00',
    Status: 'Active',
    ToAccountName: 'Prdjbqr Oghdquh Doqgfg',
    ToAccountNumber: '2102499P',
    Workflow: 'Journal',
    WorkflowName: 'journal',
  },
];

const getScheduledTransferDetails = (scheduledId) => {
  return scheduledTransferDetails.find(x => x.ScheduledEventID === Number(scheduledId));
};

const addBankResponse = [
  {
    BankId: 111,
    ClientReferenceId: '20170525213500',
    FdcnId: 99999,
    AccountNumber: 'xxx0001',
    AccountName: 'Oki Matsumoto',
    BankName: 'Bank of America',
    BankAccountNumber: '909987883',
    RoutingNumber: '900997883',
    BankAccountTitle: 'Oki Matsumoto',
    BankAccountType: 'Checking',
    BankAccountNickName: null,
    IsDomesticAccount: true,
    SwiftNumber: null,
    City: null,
    State: null,
    Country: 'US',
    IsActive: true,
    IsVerified: true,
    IsLinked: true,
    Status: 'Received',
  },
];

const fees = {
  Fees: [
    {
      FeeDescription: 'Transfer fee for domestic wires',
      FeeName: 'Transfer Fee - Domestic',
      TransferType: 'wiredebit',
      FeeId: 1,
      FeeAmount: 25,
    },
    {
      FeeDescription: 'Transfer fee for international wires',
      FeeName: 'Transfer Fee - International',
      TransferType: 'wiredebit',
      FeeId: 2,
      FeeAmount: 35,
    },
    {
      FeeDescription: 'Closing fee for IRA accounts',
      FeeName: 'Closing Fee',
      TransferType: 'all',
      FeeId: 5,
      FeeAmount: 50,
    },
    {
      FeeDescription: 'Annual fee for IRA accounts',
      FeeName: 'Annual Fee',
      TransferType: 'all',
      FeeId: 6,
      FeeAmount: 35,
    },
    {
      FeeDescription: 'Overnight Delivery Fee',
      FeeName: 'Overnight Delivery Fee',
      TransferType: 'checkdebit',
      FeeId: 7,
      FeeAmount: 15,
    },
    {
      FeeDescription: 'Standard Delivery Fee',
      FeeName: 'Standard Delivery Fee',
      TransferType: 'checkdebit',
      FeeId: 28,
      FeeAmount: 0,
    },
  ],
};

const bankingDays = [
  new Date(2018, 11, 1),
  new Date(2018, 11, 20),
  new Date(2019, 0, 2),
  '2018-12-24T00:00:00',
  '2018-12-25T00:00:00',
  new Date('2018-12-26T00:00:00'),
  new Date('2018-12-27T00:00:00'),
  new Date(2018, 11, 28),
  new Date(2018, 11, 29),
  new Date(),
  new Date().setDate(new Date().getDate() + 1),
];

export default {
  brokerageAccounts,
  bankAccounts,
  achTransfers,
  achTransfer,
  checkTransfers,
  checkTransfer,
  wireTransfers,
  wireTransfer,
  internalTransfers,
  internalTransfer,
  cashierRequests,
  addBankResponse,
  fees,
  scheduledTransfers,
  scheduledTransferDetails,
  bankingDays,
  cancelScheduledTransfer,
  getScheduledTransfers,
  getScheduledTransferDetails,
};
