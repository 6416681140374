import React from 'react';
import { connect, } from 'react-redux';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import RenderDropdownField from '../Forms/RenderDropdownField';
import Hint from '../Hint/TooltipHint';

const FrequencyFormField = (props) => {
  const frequencyOptions = props.frequencyOptions;
  const frequencyTooltipOptions = props.frequencyTooltipOptions;
  return (
    <div className="amount">
      <Field
        {...props}
        component={RenderDropdownField}
        id={props.id}
        name={props.name}
        label={props.label}
        onChange={props.onChange}
        className={props.className}
        validationMessage="Please select a frequency option from the list."
        required
      >
        <option />
        {frequencyOptions.map((frequencyObject) =>
          <option key={frequencyObject.Key} value={frequencyObject.Key}>{frequencyObject.Value}</option>)}
      </Field>
      <Hint
        effect="solid"
        place="top"
        type="dark"
        html="true"
        text={frequencyTooltipOptions}
      />
    </div>
  );
};

FrequencyFormField.propTypes = {
  frequencyOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  frequencyTooltipOptions: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
};

export default connect()(FrequencyFormField);
