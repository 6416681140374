/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment-timezone';
import Header from '../Header';
import RenderDropdownField from '../Forms/RenderDropdownField';
import FrequencyFormField from '../Forms/FrequencyFormField';
import { WiresDirection, TransferMethod } from '../DropdownRouter';
import { StaticRoutes } from '../../Routes';
import RenderTextField from '../Forms/RenderTextField';
import { isInvalidATW } from '../ATW/validations';
import WireFees from '../Fees/WireFees';
import IRAFees from '../Fees/IRAFees';
import IRADistributionForm from '../IRA/IRADistributionForm';
import IRACustodianAccount from '../IRA/IRACustodianAccount';
import RippleButton from '../Forms/RippleButton';
import publishPageHeight from '../../publishPageHeight';
import DonutChart from '../ATW/DonutChart';
import RenderDatePickerField from '../Forms/RenderDatePickerField';

const AmountErrorMessage = 'Amount must be greater than 0 and less than Available To Withdraw';
const TaxAmountsGreaterThanTransferAmountErrorMessage = 'Tax withholding exceeds requested amount';

class WireTransferWithdrawalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      redraw: false,
      alreadyDrawn: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.normalize = this.normalize.bind(this);
    this.formatAmount = this.formatAmount.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSelectDestination = this.onSelectDestination.bind(this);

    this.selectAccount = this.selectAccount.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleFrequencyChange = this.handleFrequencyChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.getSelectedFrequencyTooltipHtml = this.getSelectedFrequencyTooltipHtml.bind(this);
  }

  componentDidMount() {
    if (!this.props.formValues.anyTouched) {
      this.props.onInitialize(this.props.match.params.requestId, this.props.form);
    }
  }

  componentWillReceiveProps() {
    this.setState({
      error: '',
      alreadyDrawn: true,
    }, () => {
      if (this.state.alreadyDrawn) {
        this.setState({
          redraw: false,
          alreadyDrawn: false,
        });
      }
      const fundsOriginAccount = this.props.formValues.values.fundsOriginAccount;

      if (fundsOriginAccount && fundsOriginAccount.isBrokerageAccount) {
        const atwAmount = fundsOriginAccount.availableToWithdraw
          .find((x) => x.Currency === this.props.formValues.values.transferCurrency);
        const isFuturePlus = fundsOriginAccount.isFuturePlus;
        const today = moment();

        if (!atwAmount) {
          this.setState({
            error: 'It was not possible to process the selected currency. Please try another one',
          });
        } else if (!isFuturePlus && (this.props.amount.current < 0 ||
          (this.props.amount.current > atwAmount.Realtime && this.props.isOneTimeTransfer && today.format('YYYY-MM-DD') === this.props.scheduledDate.format('YYYY-MM-DD')))) {
          this.setState({
            error: AmountErrorMessage,
          });
        } else {
          this.setState({
            error: '',
          });
        }
      }
    });
  }

  componentDidUpdate() {
    if (Object.keys(this.props.cloneRequest).length > 0) {
      this.props.changeFrequency(this.props.cloneRequest.frequency, this.props.form);
      this.props.changeScheduledDate(this.props.cloneRequest.calendar);

      if (this.props.wireTransfers.data && this.props.wireTransfers.data.length > 0) {
        let destinationBank = this.props.wireTransfers.data.find(x => x.destinationAccountNumber === this.props.cloneRequest.destinationAccountNumber);
        if (!destinationBank) {
          destinationBank = this.props.cloneRequest;
          this.props.wireTransfers.data.push(destinationBank);
          // destinationBank.destinationBank = this.props.cloneRequest.destinationBank;
          // destinationBank.destinationABANumber = this.props.cloneRequest.destinationABANumber;
        }
        this.props.cloneRequest.requestHistoryKey = destinationBank.requestHistoryKey;
      }

      this.selectPreviousRequest(this.props.cloneRequest);
    }

    publishPageHeight();
  }

  onSelectDestination(requestHistoryKey) {
    if (requestHistoryKey === 'reset') {
      this.props.resetForm(this.props.form, this.props.formValues.values.fundsOriginAccount);
    } else {
      const selectedRequest = this.props.destinations.find(request => request.requestHistoryKey === requestHistoryKey);
      const fundsOriginAccount = this.props.formValues.values.fundsOriginAccount;
      this.props.selectPreviousRequest(
        selectedRequest,
        this.props.form,
        this.props.brokerageAccounts,
        fundsOriginAccount,
        true,
      );
    }
  }

  getSelectedFrequencyTooltipHtml() {
    let selectedFrequencyTooltipHtml = '';
    const selectedFrequencyKey = this.props.frequency;
    if (selectedFrequencyKey) {
      const selectedFrequency = this.props.frequencyOptions.find((frequencyObject) =>
        selectedFrequencyKey && frequencyObject.Key.toString() === selectedFrequencyKey);
      selectedFrequencyTooltipHtml =
        <p><strong>{selectedFrequency.Value}: </strong>{selectedFrequency.Description}</p>;
    }
    return selectedFrequencyTooltipHtml;
  }

  selectPreviousRequest(requestHistoryKey) {
    if (this.props.match.params.requestId && this.props.cloneRequest.isEdit) {
      this.props.changeScheduledDate(this.props.cloneRequest.calendar);
      // this.props.changeAmount(this.props.cloneRequest.transferAmount);
      this.props.selectPreviousRequest(this.props.cloneRequest, this.props.form, this.props.brokerageAccounts, null, true);
      return;
    }

    if (requestHistoryKey === 'reset') {
      this.props.resetForm(this.props.form, this.props.formValues.values.fundsOriginAccount);
    } else {
      const selectedRequest = this.props.destinations.find(request => request.requestHistoryKey === requestHistoryKey);
      const fundsOriginAccount = this.props.formValues.values.fundsOriginAccount;
      this.props.selectPreviousRequest(
        selectedRequest,
        this.props.form,
        this.props.brokerageAccounts,
        fundsOriginAccount,
        false,
      );
    }
  }

  // Validate that the combination of Tax Amounts is not greater then Trasnfer Amount
  validateAmounts(formValues, amount) {
    // get Amounts
    let IRAFederalAmount = parseFloat(formValues.iraWithdrawal && formValues.iraWithdrawal.federalTaxWithholdingAmount ? this.normalize(formValues.iraWithdrawal.federalTaxWithholdingAmount) : 0);
    let IRAStateAmount = parseFloat(formValues.iraWithdrawal && formValues.iraWithdrawal.stateTaxWithholdingAmount ? this.normalize(formValues.iraWithdrawal.stateTaxWithholdingAmount) : 0);

    // get percentanges
    const IRAStatePercent = parseFloat(formValues.iraWithdrawal && formValues.iraWithdrawal.stateTaxWithholdingPercentage ? this.normalize(formValues.iraWithdrawal.stateTaxWithholdingPercentage) : 0);
    const IRAFederalPercent = parseFloat(formValues.iraWithdrawal && formValues.iraWithdrawal.federalTaxWithholdingPercentage ? this.normalize(formValues.iraWithdrawal.federalTaxWithholdingPercentage) : 0);

    // We are gonna use the IRAmounts to validate that the resulting amount on the Taxes info is not greater than the transfer amount.
    // At any point in time on the tax fields we will have either plain amount or a percent  but not the two at the same time ,
    // if we have percentage instead of tax amount , we calculate the tax amount , this way we always use amounts for the validation and its easier to understand the validation
    if (IRAStatePercent > 0) {
      IRAStateAmount = (amount / 100) * IRAStatePercent;
    }

    if (IRAFederalPercent > 0) {
      IRAFederalAmount = (amount / 100) * IRAFederalPercent;
    }

    return amount <= (IRAStateAmount + IRAFederalAmount);
  }

  // iOS Safari pre 10.3.3 won't call checkValidity before a form submission, so we have to do this
  // ourselves because we depend on native html validation to prevent form submission errors
  handleSubmit(event) {

    const amount = this.props.transferAccountBalance ? this.props.allCashAmount : this.normalize(this.props.formValues.values.transferAmount);
    const isTaxAmountsGreaterThanTransferAmount = this.validateAmounts(this.props.formValues.values, parseFloat(amount));
    const fundsOriginAccount = this.props.formValues.values.fundsOriginAccount;

    if (!fundsOriginAccount.isFuturePlus && (!event.target.checkValidity() || isInvalidATW(fundsOriginAccount, this.props.formValues.values.transferCurrency, this.props.formValues.values.frequency, this.props.scheduledDate) || amount <= 0)) {
      event.preventDefault();
      this.setState({
        error: AmountErrorMessage,
      });
    } else if (isTaxAmountsGreaterThanTransferAmount) {
      event.preventDefault();
      this.setState({
        error: TaxAmountsGreaterThanTransferAmountErrorMessage,
      });
    }
    else {
      this.props.handleSubmit(event);
    }
  }

  selectAccount(e) {
    this.setState({
      redraw: true,
    });

    this.props.onSelectOriginAccount(e.target.value, this.props.form, this.props.brokerageAccounts);
  }  

  handleChange(event) {
    let amount = event.target.value;
    const fundsOriginAccount = this.props.formValues.values.fundsOriginAccount;

    if (!fundsOriginAccount) {
      return;
    }

    if (!event.target.value) {
      amount = 0;
    }

    this.setState({
      redraw: false,
    });

    this.props.changeAmount(amount);
  }

  handleFrequencyChange(event) {
    if (event.target.value === '') {
      event.preventDefault();
      return;
    }
    if (event.target.value) {
      this.props.changeFrequency(event.target.value, this.props.form);
    }
  }

  handleDateChange(event) {
    this.props.changeScheduledDate(event);
  }

  handleCurrencyChange(event) {
    if (event.target.value) {
      this.props.changeCurrency(event.target.value);
    }
  }

  formatAmount(value) {
    if (!value) return value;
    const cleanValue = parseFloat(value.replace(/\./g, '').replace(/,/g, ''));
    const newValue = (cleanValue / 100);
    const result = Number(newValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return result;
  }

  normalize(value) {
    if (!value || !isNaN(value)) return value;
    const cleanValue = parseFloat(value.replace(/\./g, '').replace(/,/g, ''));
    const result = (cleanValue / 100).toFixed(2);
    return result
  }

  render() {
    const {
      fundsOriginAccount,
      destinationBank,
      transferCurrency,
    } = this.props.formValues.values;
    const { showWithdrawAll, 
      amount, 
      allCashAmount, 
      transferAccountBalance,
      isWireTransferEnabled,
      isCheckTransferEnabled,
      scheduledDate,
      bankingDays,
      frequencyOptions,
      frequencyTooltipOptions,
      match,
    } = this.props;
    const bank =
      <div style={{ display: this.props.formValues.values.destinationBank ? 'inherit' : 'none' }}>
        <Field
          component={RenderTextField}
          id="destinationBankName"
          name="destinationBankName"
          key={`${destinationBank}_Name`}
          label="Financial Institution (i.e. Bank Name)"
          required
        />
        <Field
          id="destinationBankCountry"
          name="destinationBankCountry"
          key={`${destinationBank}_Country`}
          component={RenderDropdownField}
          label="Country"
          required
        >
          <option />
          {this.props.countries.map(x =>
            <option
              key={x.countryCode}
              value={x.countryCode}
            >
              {x.country}
            </option>
          )}
        </Field>
        <Field
          component={RenderTextField}
          id="destinationSwiftNumber"
          name="destinationSwiftNumber"
          key={`${destinationBank}_SwiftNumber`}
          label="ABA Fed Wire Number or Swift Code"
          maxLength="34"
          required
        />
        <Field
          component={RenderTextField}
          id="destinationAccountTitle"
          name="destinationAccountTitle"
          key={`${destinationBank}_AccountTitle`}
          label="Account Title at Bank (ex: John Smith)"
          required
        />
        <Field
          component={RenderTextField}
          id="destinationAccountNumber"
          name="destinationAccountNumber"
          key={`${destinationBank}_AccountNumber`}
          label="Account Number/IBAN"
          required
        />
        <Field
          component={RenderTextField}
          id="furtherCreditAccountTitle"
          name="furtherCreditAccountTitle"
          key={`${destinationBank}_FurtherCreditTitle`}
          label="Further Credit Account Title (optional)"
        />
        <Field
          component={RenderTextField}
          id="furtherCreditAccountNumber"
          name="furtherCreditAccountNumber"
          key={`${destinationBank}_FurtherCreditNumber`}
          label="Further Credit Account Number (optional)"
        />
        <div className="intermediary">
          <Field
            component="input"
            type="checkbox"
            id="intermediaryNeeded"
            name="intermediaryNeeded"
          />
          <label htmlFor="intermediaryNeeded">&nbsp;Add Intermediary Bank Information</label>
        </div>
      </div>;
    const intermediary =
      <div
        id="intermediary-information"
        style={{ display: this.props.formValues.values.intermediaryNeeded ? 'inherit' : 'none' }}
      >
        <h5>Additional Bank / Beneficiary Instructions (if applicable)</h5>
        <h6>Only use the fields below for additional bank instructions if applicable,
            including international wires and wires to other broker</h6>
        <Field
          component={RenderTextField}
          id="intermediaryBankName"
          name="intermediaryBankName"
          key={`${destinationBank}_IntName`}
          label="Intermediary Bank Name"
          required={this.props.intermediaryNeeded}
        />
        <Field
          id="intermediaryBankCountry"
          name="intermediaryBankCountry"
          key={`${destinationBank}_IntCountry`}
          component={RenderDropdownField}
          label="Country"
          required={this.props.intermediaryNeeded}
        >
          <option />
          {this.props.countries.map(x =>
            <option
              key={x.countryCode}
              value={x.countryCode}
            >
              {x.country}
            </option>
          )}
        </Field>
        <Field
          component={RenderTextField}
          id="intermediaryBankSwiftNumber"
          name="intermediaryBankSwiftNumber"
          key={`${destinationBank}_IntSwift`}
          label="ABA Fed Wire Number or Swift Code"
          maxLength="34"
          required={this.props.intermediaryNeeded}
        />
        <Field
          component={RenderTextField}
          id="intermediaryBankAccountNumber"
          name="intermediaryBankAccountNumber"
          key={`${destinationBank}_IntAccountNumber`}
          label="Account Number/IBAN"
        />
      </div>;
    return (
      <form id="wire-withdrawal-form" onSubmit={this.handleSubmit}>
        <section>
          <Header
            title="Wire Transfer"
            description="Wire cash from your TradeStation brokerage account to an external account."
          />
          <TransferMethod
            isWireTransferEnabled={isWireTransferEnabled}
            isCheckTransferEnabled={isCheckTransferEnabled}
            defaultValue={StaticRoutes.WIRE_TRANSFER_IN}
          />
          <div className="fee-schedule">
            <div id="fees">
              <WireFees />
              {fundsOriginAccount && fundsOriginAccount.isIRA &&
                <IRAFees
                  fundsOriginAccount={fundsOriginAccount || {}}
                />
              }
            </div>
            <span className="separator" />
            <div id="availability">
              <dl>
                <dt>Availability:</dt>
                <dd>Next Business Day (if received by 12PM ET)</dd>
              </dl>
            </div>
          </div>
          <WiresDirection defaultValue={StaticRoutes.WIRE_TRANSFER_OUT} />
          <Field
            component={RenderDropdownField}
            id="fundsOriginAccountKey"
            name="fundsOriginAccountKey"
            key={`${destinationBank}_IntFundsOrigin`}
            label="From"
            onChange={this.selectAccount}
            required
            validationMessage="Please select an account from the list."
            disabled={match.params.requestId ? true : false}
          >
            <option className="hidden" />
            {this.props.brokerageAccounts.map((account) =>
              <option
                key={account.key}
                value={account.key}
                id={account.key}
                title={account.accountName}
                data-brokeragetype={account.accountDetail}
              >
                {account.displayName}
              </option>
            )}
          </Field>
          {fundsOriginAccount && fundsOriginAccount.isBrokerageAccount &&
            <div>
              {!fundsOriginAccount.isFuturePlus &&
                <DonutChart
                  selectedOriginAccount={fundsOriginAccount}
                  amount={transferAccountBalance ? allCashAmount : amount.current}
                  currency={transferCurrency}
                  redraw={this.state.redraw}
                />
              }
            </div>
          }
          {fundsOriginAccount 
            && (
              (fundsOriginAccount.isIRA && fundsOriginAccount.isFuturesAccount)
              || fundsOriginAccount.IsCustodian
            )
            
            ? <IRACustodianAccount />
            : <div>
              {fundsOriginAccount &&
                <IRADistributionForm {...this.props} account={fundsOriginAccount} />}
              <Field
                component={RenderDropdownField}
                id="destinationBank"
                name="destinationBank"
                label="To"
                onChange={(e) => this.selectPreviousRequest(e.target.value)}
                disabled={!fundsOriginAccount || (match.params.requestId ? true : false)}
                required
              >
                <option />
                <optgroup label="Previously Used Accounts">
                  {this.props.destinations.length > 0
                    ? this.props.destinations.map(request =>
                      <option
                        key={request.requestHistoryKey}
                        value={request.requestHistoryKey}
                        id={request.requestHistoryKey}
                      >
                        {`${request.destinationBank}`}
                      </option>
                    )
                    : <option disabled>No previous accounts</option>
                  }
                </optgroup>
                <option value="reset" style={{ color: '#0077BF', fontWeight: 'bolder' }}>
                  &#xFF0B; Add an account
                </option>
              </Field>
              <div id="withdrawal-form">
                {bank}
                {intermediary}
                <div className="transfer-box">
                  <div className="amount">
                    {this.props.transferAccountBalance &&
                      <div className="field text is-dirty">
                        <label htmlFor="transferBalance">Amount</label>
                        <input
                          type="text"
                          name="transferBalance"
                          id="transferBalance"
                          value="All Available Cash"
                          disabled
                        />
                        <span className="expanding-line" />
                        <span className="validation">{!fundsOriginAccount.isFuturePlus && fundsOriginAccount.availableToWithdraw.find((x) => x.Currency === transferCurrency).Realtime <= 0 ? AmountErrorMessage : ''}</span>
                      </div>
                    }
                    {!this.props.transferAccountBalance && <Field
                      component={RenderTextField}
                      name="transferAmount"
                      type="text"
                      pattern="^-?[\d,.]+$"
                      step="any"
                      id="transferAmount"
                      label="Amount"
                      normalize={this.formatAmount}
                      onChange={this.handleChange}
                      required
                      validationMessage={AmountErrorMessage}
                    />}
                    <Field
                      component={RenderDropdownField}
                      id="transferCurrency"
                      name="transferCurrency"
                      key={`${transferCurrency}_Code`}
                      label="Currency"
                      required
                      defaultValue="USD"
                      onChange={this.handleCurrencyChange}
                    >
                      <option />
                      {this.props.formValues.values.fundsOriginAccount
                        ? this.props.formValues.values.fundsOriginAccount.supportedCurrencies.map(code =>
                          <option
                            value={code}
                            key={code}
                          >
                            {code}
                          </option>
                        ) :
                        <option value="USD">USD</option>
                      }
                    </Field>
                  </div>
                  <div>
                    <span className="amount-validation">{this.state.error}</span>
                  </div>
                  {showWithdrawAll &&
                    <div>
                      <Field
                        component="input"
                        type="checkbox"
                        id="transferBalanceChosen"
                        name="transferBalanceChosen"
                      />
                      <label htmlFor="transferBalanceChosen">&nbsp;Withdraw All Available Cash</label>
                    </div>
                  }
                </div>
                <FrequencyFormField
                  id="frequency"
                  name="frequency"
                  label="Frequency"
                  className="field text is-dirty"
                  onChange={this.handleFrequencyChange}
                  frequencyOptions={frequencyOptions}
                  frequencyTooltipOptions={frequencyTooltipOptions}
                />
                {this.props.isOneTimeTransfer ?
                  <Field
                    {...this.props}
                    component={RenderDatePickerField}
                    name="calendar"
                    type="text"
                    id="calendar"
                    label="Scheduled Date"
                    onChange={this.handleDateChange}
                    value={scheduledDate}
                    disableWeekends={true}
                    disablePastDays={true}
                    readOnly={true}
                    includeDates={bankingDays}
                    required
                  />
                  : <div id="selectedFrequencyText">
                    {this.getSelectedFrequencyTooltipHtml()}
                  </div>
                }
                <div className="actions">
                  <RippleButton disabled={this.state.error.length > 0} className="primary">preview</RippleButton>
                </div>
              </div>
            </div>
          }
        </section>
      </form >
    );
  }
}

WireTransferWithdrawalForm.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({
    countryCode: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
  })),
  brokerageAccounts: PropTypes.arrayOf(PropTypes.object),
  destinations: PropTypes.arrayOf(PropTypes.shape()),
  onInitialize: PropTypes.func,
  onSelectOriginAccount: PropTypes.func.isRequired,
  selectPreviousRequest: PropTypes.func.isRequired,
  intermediaryNeeded: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    anyTouched: PropTypes.bool,
    values: PropTypes.shape({
      fundsOriginAccount: PropTypes.shape(),
      destinationBank: PropTypes.string,
      intermediaryNeeded: PropTypes.bool,
      transferCurrency: PropTypes.string,
    }),
  }),
  cloneRequest: PropTypes.shape(),
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestHistoryKey: PropTypes.string,
      requestId: PropTypes.string,
    }),
  }),
  form: PropTypes.string,
  transferAccountBalance: PropTypes.bool,
  showWithdrawAll: PropTypes.bool,
  changeAmount: PropTypes.func.isRequired,
  changeCurrency: PropTypes.func.isRequired,
  amount: PropTypes.shape({
    current: PropTypes.number.isRequired,
  }).isRequired,
  allCashAmount: PropTypes.number.isRequired,
  isWireTransferEnabled: PropTypes.bool,
  isCheckTransferEnabled: PropTypes.bool,
  changeFrequency: PropTypes.func.isRequired,
  frequencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
  frequency: PropTypes.string,
  scheduledDate: PropTypes.shape({
    date: PropTypes.func,
    format: PropTypes.func,
  }).isRequired, 
  bankingDays: PropTypes.arrayOf(PropTypes.shape()),
  changeScheduledDate: PropTypes.func.isRequired,
  isOneTimeTransfer: PropTypes.bool,
  wireTransfers: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

export default reduxForm({
  form: 'wireTransferWithdrawalForm',
  destroyOnUnmount: false,
})(WireTransferWithdrawalForm);
