import moment from 'moment-timezone';
import lookupData from '../../api/lookupData';

/* eslint-disable */
export const isInvalidATW = (selectedOriginAccount, currency, frequency, scheduledDate) => {
  const oneTimeTransferId = lookupData.RecurrenceSchedule.find(x => x.Value === 'One Time').Key;
  const isOneTimeTransfer = frequency
    && frequency.toString() === oneTimeTransferId;
  const isScheduledToday = scheduledDate ? scheduledDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') : false;

  if(selectedOriginAccount.isBrokerageAccount){
    if (!isOneTimeTransfer || !isScheduledToday) {
      return false;
    }

    const atwAmount = selectedOriginAccount.availableToWithdraw
          .find((x) => x.Currency === currency); 

    if (selectedOriginAccount.accountBalanceDetail) {
      return atwAmount.Realtime <= 0;
    }
  }
  
  return false;
};
