import moment from 'moment-timezone';
import { CHANGE_DATE } from '../actions/scheduledDateActions';

const initialState = {
  scheduledDate: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_DATE: {
      return Object.assign({}, state, {
        scheduledDate: action.payload ? moment(action.payload) : action.payload,
      });
    }
    default:
      return state;
  }
}
