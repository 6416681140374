import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, destroy } from 'redux-form';
import { push } from 'react-router-redux';
import { StaticRoutes } from '../../Routes';
import { DeliveryMethod } from '../../api/lookupData';
import CheckTransferWithdrawalForm from './CheckTransferWithdrawalForm';
import CheckTransferWithdrawalConfirmation from './CheckTransferWithdrawalConfirmation';
import * as brokerageAccountActions from '../../actions/brokerageAccountActions';
import * as transferFeeActions from '../../actions/transferFeeActions';
import * as checkWithdrawalActions from '../../actions/checkTransferActions';
import * as cloneActions from '../../actions/cloneRequestActions';
import * as lookupActions from '../../actions/lookupActions';
import { changeAmount } from '../../actions/amountActions';
import { changeCurrency } from '../../actions/currencyActions';

class CheckTransferWithdrawalPage extends Component {
  componentWillUnmount() {
    this.props.destroyForm();
  }

  render() {
    return (
      <section className="page">
        {this.props.confirming ?
          <CheckTransferWithdrawalConfirmation
            onSubmit={this.props.submitForm}
            {...this.props}
          /> :
          <CheckTransferWithdrawalForm
            onSubmit={this.props.confirmRequested}
            {...this.props}
          />
        }
      </section>
    );
  }
}

CheckTransferWithdrawalPage.propTypes = {
  confirming: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  confirmRequested: PropTypes.func.isRequired,
  destroyForm: PropTypes.func.isRequired,
};

const formName = 'checkTransferWithdrawalForm';

const initialValues = {
  confirming: false,
  deliveryMethod: '20',
  fundsOriginAccount: {
    supportedCurrencies: ['USD'],
  },
  fundsOriginAccountKey: null,
  transferAmount: '0.00',
  transferCurrency: 'USD',
};

export default connect(
  (state) => {
    const form = state.form.checkTransferWithdrawalForm || {};

    initialValues.transferCurrency = state.currency.code;

    form.values = Object.assign({ }, initialValues, form.values);

    let distributionTypeId = form.values.iraWithdrawal && form.values.iraWithdrawal.distributionTypeId;
    const totalDistributionId = state.lookup.identifiers.IRADistributionType.TotalDistribution;
    const brokerageAccounts = state.brokerageAccounts.data;
    const origin = brokerageAccounts.find(account => account.key === form.values.fundsOriginAccountKey);
    const showWithdrawAll = origin && !origin.isIRA;
    const transferBalanceChosen = showWithdrawAll && form.values.transferBalanceChosen;

    // const supportedCurrencies = (selectedDestinationAccount && selectedDestinationAccount.isFuturesAccount &&
    //   selectedOriginAccount && selectedOriginAccount.supportedCurrencies) ||
    //   [initialValues.transferCurrency];

    if (form.values.fundsOriginAccount && !form.values.fundsOriginAccount.isIRA) {
      distributionTypeId = '';
    }

    const transferAccountBalance = transferBalanceChosen || distributionTypeId === totalDistributionId;

    let amount = state.amount;
    if (form.values.fundsOriginAccount &&
        form.values.fundsOriginAccount.isIRA &&
        form.values.fundsOriginAccount.isFuturesAccount) {
      amount = {
        current: 0,
      };
    }

    let allCashAmount = 0;
    if (transferAccountBalance) {
      allCashAmount = form.values.fundsOriginAccount
        .find((x) => x.Currency === form.values.transferCurrency).Realtime;
    }

    const bankingDays = state.bankingDays.data;

    const {
      checkWithdrawalMode,
      showPrintButton,
    } = state.clientConfig;

    return {
      formValues: form,
      brokerageAccounts,
      confirming: form.values.confirming,
      cloneRequest: state.cloneRequest,
      deliveryMethods: state.lookup[DeliveryMethod],
      transferFees: state.transferFees.data,
      showWithdrawAll,
      transferAccountBalance,
      checkWithdrawalMode,
      showPrintButton,
      config: state.configuration,
      amount,
      allCashAmount,
      isWireTransferEnabled: state.clientConfig.isWireTransferEnabled,
      isCheckTransferEnabled: state.clientConfig.isCheckTransferEnabled,
      isCheckDepositTransferEnabled: state.clientConfig.isCheckDepositTransferEnabled,
      isCheckWithdrawalTransferEnabled: state.clientConfig.isCheckWithdrawalTransferEnabled,
      bankingDays,
    };
  },
  (dispatch) => {
    return {
      initializeForm: (requestId) => {
        const actions = [
          dispatch(brokerageAccountActions.requestBrokerageAccounts()),
          dispatch(lookupActions.fetchLookup()),
          dispatch(transferFeeActions.fetchFees()),
        ];

        if (requestId) {
          actions.push(dispatch(checkWithdrawalActions.fetchCheckTransfer(requestId)));
        }

        Promise.all(actions);
      },
      confirmRequested: (_, __, props) => {
        dispatch(change(props.form, 'confirming', true));
        window.scrollTo(0, 0);
      },
      confirmCanceled: (form) => {
        dispatch(change(form, 'confirming', false));
        window.scrollTo(0, 0);
      },
      selectAccount: (event, form, brokerageAccounts) => {
        const selectedAccount = brokerageAccounts.find(
          account => account.key === event.target.value
        );
        dispatch(change(form, 'transferCurrency', 'USD'));

        dispatch(change(form, 'fundsOriginAccountKey', selectedAccount.accountKey));
        dispatch(change(form, 'fundsOriginAccount', selectedAccount));
      },
      changeAmount: (amount) => {
        dispatch(changeAmount(amount));
      },
      changeCurrency: (currency) => {
        dispatch(changeCurrency(currency));
      },
      selectPreviousRequest: (selectedRequest, form, brokerageAccounts) => {
        Object.keys(selectedRequest).forEach(key => dispatch(
          change(form, key, selectedRequest[key])
        ));

        const selectedAccount = brokerageAccounts.find(
          account => account.key === selectedRequest.fundsOriginAccountKey
        );

        dispatch(change(form, 'fundsOriginAccount', selectedAccount));

        dispatch(cloneActions.removeCloneRequest());
      },
      submitForm: (values, _, props) => {
        const submitData = {
          accountNumber: values.fundsOriginAccount.accountNumber,
          deliveryTypeId: values.deliveryMethod,
          amount: values.transferAmount,
          currencyCode: values.transferCurrency,
          transferAccountBalance: props.transferAccountBalance,
          iraDistribution: values.iraWithdrawal,
        };

        return dispatch(checkWithdrawalActions.submitCheckWithdrawalTransfer(submitData))
          .then(action => {
            if (action.isSuccess) {
              dispatch(push(StaticRoutes.THANK_YOU));
            }
          });
      },
      printPage: (event) => {
        event.preventDefault();
        window.print();
      },
      destroyForm: () => {
        dispatch(destroy(formName));
      },
    };
  })(CheckTransferWithdrawalPage);
