import React from 'react';


export default function DisclaimerPLAID() {
  const fundingInstructions = 'https://www.tradestation.com/accounts/funding-instructions';
  const privacyNoticeLink = 'https://www.tradestation.com/important-information/privacy-notice';
  const privacyRequestLink = 'https://plaid.com/legal/data-protection-request-form/';

  return (
    <div>
      <p>
        TradeStation uses Plaid - a U.S based technology provider - to link your
        U.S bank accounts so that you can easily transfer funds between
        your TradeStation accounts and bank. You will briefly leave TradeStation
        to complete this process.<br /><br />

        By continuing and using this service, you agree and understand that:<br />
      </p>
      <ul>
        <li>
          <p>
            The service is not intended for use by clients outside the United States and its territories.
            Funding options for clients outside the U.S are available <a href={fundingInstructions}>here</a>.
          </p>
        </li>
        <li>
          <p>
            You will provide information to Plaid, a 3rd party provider,
            for the purpose of verifying your bank account in accordance
            with TradeStation’s <a href={privacyNoticeLink}>Privacy Notice</a> and the End User Policy
            available in the Plaid service.
          </p>
        </li>
        <li>
          <p>
            Transfers are processed via the Automated Clearing House (ACH) network. By using this service,
            you authorize TradeStation to, upon your instruction, initiate debit and credit transfers between
            your TradeStation and bank account(s).
          </p>
        </li>
        <li>
          <p>
            Transfers can only be processed between bank and TradeStation accounts commonly owned by you.
          </p>
        </li>
        <li>
          <p>
          You may opt-out at any time by unlinking your bank account within the Manage External Accounts section
          in Client Center. Additionally, you can request at any time that Plaid provide a copy of and/or delete
          any data they maintain about you by submitting a <a href={privacyRequestLink}>privacy request</a> to Plaid.
          </p>
        </li>
        <li>
          <p>
            If you choose not to use this service or opt-out of this service in the future,
            you can use wire deposit as an alternative method for depositing funds from your bank.
          </p>
        </li>
      </ul>
    </div>
  );
}
