import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import * as ImgHint from '../../images/question_.png';
import './TooltipHint.css';

const shouldShowMobileMode = () => {
  return window.innerWidth <= 420;
};

const TooltipHint = (props) => {
  return (
    <div>
      <img
        src={ImgHint}
        width="40px"
        height="40px"
        alt="hint"
        data-tip={props.text}
        data-html={props.html}
        data-for="tooltip"
      />
      <ReactTooltip
        id="tooltip"
        effect={props.effect}
        type={props.type}
        place={props.place}
        className={shouldShowMobileMode() ? 'tooltip-mobile' : ''}
      />
    </div>
  );
};

TooltipHint.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  place: PropTypes.string,
  effect: PropTypes.string,
  html: PropTypes.string,
};

export default TooltipHint;
