import React from 'react';
import PropTypes from 'prop-types';
import RippleButton from '../Forms/RippleButton';

const CheckTransferInstructions = (props) => {
  return (
    <div>
      <h4>Instructions</h4>
      <ol>
        <li>
          Write your TradeStation Securities account number ({props.accountNumber})
          in the memo portion of your check.
        </li>
        <li>
          If you are making an IRA contribution, include the contribution year in the memo portion of your check.
        </li>
        <li>
          Make check payable to: <b>TradeStation Securities, Inc.</b>
        </li>
        <li>
          Mail check to
          <address>
            <strong>TradeStation Securities, Inc. </strong><br />
            8050 SW 10th Street, Suite 2000 <br />
            Plantation, FL 33324<br />
          </address>
        </li>
      </ol>
      <h4>Important Notes</h4>
      <p>
        All checks are subject to a 3 business day trading hold and are available for withdrawal
        after 6 business days.
      </p>
      <p>
        The title of your TradeStation Securities account and the title on the account from which the
        check you are sending for deposit is drawn must match exactly. Cashier&lsquo;s or certified checks
        may be subject to a trading hold if the amount of the deposit exceeds $100,000 and/or if the
        issuing bank will not verify authenticity of the check.
      </p>

      {props.showPrintButton &&
        <RippleButton className="primary" onClick={(e) => props.printPage(e)}>PRINT</RippleButton>}
    </div>
  );
};

CheckTransferInstructions.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  printPage: PropTypes.func.isRequired,
  showPrintButton: PropTypes.bool,
};

export default CheckTransferInstructions;
