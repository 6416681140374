export const FUNDS_ORIGIN = 'Funds Origin';
export const FUNDS_DESTINATION = 'Funds Destination';
export const AOP_CLIENT_ID = 'aop';
export const ANDROID_CLIENT_ID = 'android';
export const ANDROID_WEB_CLIENT_ID = 'android_web';
export const ANDROID_REGULAR_WEB_ATLAS_CLIENT_ID = 'android_regular_web_atlas';
export const IPAD_NATIVE_ATLAS_CLIENT_ID = 'ipad_native_atlas';
export const CLIENT_CENTER_CLIENT_ID = 'client_center';
export const TANGO_CLIENT_ID = 'tango';
export const IOS_CLIENT_ID = 'ios';
export const IOS_PLAID_SDK_CLIENT_ID = 'ios_plaid_sdk';
export const ANDROID_PLAID_SDK_CLIENT_ID = 'android_plaid_sdk';
export const DEEP_LINK = 'com.tradestation.MobileTrading://plaid-link';
