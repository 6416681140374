import * as actionTypes from '../actions/transferFeeActions';

const keyMap = {
  'Transfer Fee - Domestic': 'wireDomestic',
  'Transfer Fee - International': 'wireInternational',
  'Closing Fee': 'iraClosing',
  'Annual Fee': 'iraAnnual',
  'Overnight Delivery Fee': 'checkOvernight',
  'Standard Delivery Fee': 'checkStandard',
};

const formatFee = (feeAmount) => {
  return feeAmount === 0
    ? 'FREE'
    : Number(feeAmount).toLocaleString(
      'en-US',
      { style: 'currency', currency: 'USD' }
    );
};

const mapFee = (fee) => {
  return {
    description: fee.FeeDescription,
    name: fee.FeeName,
    amount: fee.FeeAmount,
    displayAmount: formatFee(fee.FeeAmount),
    longDisplayAmount: `${formatFee(fee.FeeAmount)} USD`,
  };
};

export default function (state = { data: {}, everLoaded: false }, action) {
  switch (action.type) {
    case actionTypes.FETCH_FEES_SUCCESS: {
      return {
        data: action.payload.Fees.reduce((fees, fee) => {
          const result = { ...fees };
          result[keyMap[fee.FeeName]] = mapFee(fee);
          return result;
        }, {}),
        everLoaded: true,
      };
    }
    default:
      return state;
  }
}
