export const FETCH_SCHEDULED_TRANSFER_DETAILS_REQUEST = 'SCHEDULED_TRANSFER_DETAILS_REQUEST';
export const FETCH_SCHEDULED_TRANSFER_DETAILS_SUCCESS = 'SCHEDULED_TRANSFER_DETAILS_SUCCESS';
export const FETCH_SCHEDULED_TRANSFER_DETAILS_ERROR = 'SCHEDULED_TRANSFER_DETAILS_ERROR';

export function fetchScheduledTransferDetails(scheduledId) {
  return () => {
    return {
      types: [FETCH_SCHEDULED_TRANSFER_DETAILS_REQUEST,
        FETCH_SCHEDULED_TRANSFER_DETAILS_SUCCESS,
        FETCH_SCHEDULED_TRANSFER_DETAILS_ERROR],
      fetch: {
        url: `/api/v1/scheduledTransfers/${scheduledId}`,
      },
    };
  };
}
