import * as achTransferActions from '../actions/achTransferActions';
import * as checkTransferActions from '../actions/checkTransferActions';
import * as internalTransferActions from '../actions/internalTransferActions';
import * as wireTransferActions from '../actions/wireTransferActions';
import * as cloneActions from '../actions/cloneRequestActions';
import * as map from './responseMapping';

export default function (state = {}, action) {
  switch (action.type) {
    case achTransferActions.FETCH_ACH_TRANSFER_SUCCESS:
      if (action.payload.WorkflowName === 'journal') { return map.internalTransfer(action.payload); }
      return map.achTransfer(action.payload);
    case checkTransferActions.FETCH_CHECK_WITHDRAWAL_SUCCESS:
      return map.checkTransfer(action.payload);
    case internalTransferActions.FETCH_INTERNAL_TRANSFER_SUCCESS:
      return map.internalTransfer(action.payload);
    case wireTransferActions.FETCH_WIRE_WITHDRAWAL_SUCCESS:
      return map.wireTransferEdit(action.payload);
    case cloneActions.REMOVE_CLONE_REQUEST:
      return {};
    default:
      return state;
  }
}
