/* eslint-disable */
import * as actionTypes from '../actions/brokerageAccountActions';
import lookupData from '../api/lookupData';

const isIRA = (account) => {
  return account.TradeAccountType === 'IRA' || account.BrokerAccountType === 'IRA';
};

const getIRATypeDescription = (iraTypeId) => {
  const iraTypes = lookupData.IRAType;
  const iraType = iraTypes.find(x => x.Key === iraTypeId);
  return iraType ? iraType.Description : 'IRA';
};

const getBrokerAccountType = (account) => {
  return isIRA(account) ? getIRATypeDescription(account.IRATypeId) : account.BrokerAccountType;
};

const formatBalance = (balanceAmount, currency) => {
  // balanceAmount === 0 ? 'FREE' : 
  return Number(balanceAmount).toLocaleString('en-US', { style: 'currency', currency: `${currency}` });
};


const getDisplayName = (account) => {
  let assetType = '';

  const accountBalance = account.AccountBalance.NetLiquidatingValue || '0.00';
  const currency = account.AccountBalance.Currency || 'USD';

  if (account.AssetType === 'EQ') assetType = 'Equities';
  if (account.AssetType === 'FU') assetType = 'Futures';

  const accountNumberMasked = account.AccountNumber;
  const brokerAccountType = getBrokerAccountType(account);

  const accountBalanceAmount = account.FuturesPlusAccount ? '' : `(${formatBalance(accountBalance, currency)})`;

  return `${brokerAccountType} ${assetType} -${accountNumberMasked} ${accountBalanceAmount}`;
};

const getDisplayNameShort = (account) => {
  const brokerAccountType = getBrokerAccountType(account);
  return `${brokerAccountType} -${account.AccountNumber.slice(-4)}`;
};

const getBrokerageAccount = (account, allAccounts) => {
  const result = {
    key: `brokerage_${account.AccountNumber}`,
    accountNumber: account.AccountNumber,
    accountTitle: account.AccountName,
    accountDetail: account.TradeAccountType,
    accountBalance: account.AccountSummary.RealtimeAccountBalance,
    tradeAccountType: account.TradeAccountType,
    assetType: account.AssetType,
    isEquitiesAccount: account.AssetType === 'EQ',
    isFuturesAccount: account.AssetType === 'FU',
    isFuturePlus: account.FuturesPlusAccount,
    isIRA: isIRA(account),
    iraTypeId: account.IRATypeId,
    linkedAccounts: {
      bank: account.LinkedBankAccounts.BankAccounts.map(x => x.BankId),
      brokerage: allAccounts.filter(a => a.AccountNumber !== account.AccountNumber)
        .map(a => a.AccountNumber),
    },
    isBrokerageAccount: true,
    displayName: getDisplayName(account),
    displayNameShort: getDisplayNameShort(account),
    supportedCurrencies: account.AvailableToWithdraw.ByCurrency.map(x => x.Currency),
    brokerAddress: {},
    accountBalanceDetail: account.AccountBalance,
    availableToWithdraw: account.AvailableToWithdraw.ByCurrency,
    canWithholdStateTax: account.CanWithholdStateTax,
    fdcnId: account.FdcnId,
    isActive: account.isActive,
    isNewAccount: account.isNewAccount,
    fastlinkLoginCount: account.FastLinkLoginCount,
    repId: account.RepId,
    IsCustodian: account.IsCustodian,
  };

  if (account.Address) {
    result.brokerAddress = {
      address1: account.Address.Address1,
      address2: account.Address.Address2,
      city: account.Address.City,
      state: account.Address.State,
      postalCode: account.Address.Zip,
      country: account.Address.Country,
    };
  }

  return result;
};

export default function (state = { data: [], areLoaded: false }, action) {
  switch (action.type) {
    case actionTypes.FETCH_BROKERAGE_ACCOUNTS_SUCCESS: {
      const accounts = action.payload.Accounts
        .map(account => getBrokerageAccount(account, action.payload.Accounts));
      return { data: accounts, areLoaded: true };
    }
    default:
      return state;
  }
}
