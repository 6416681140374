import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StaticRoutes } from '../../Routes';
import Header from '../Header';
import RippleButton from '../Forms/RippleButton';
import * as bankAccountActions from '../../actions/bankAccountActions';
import { AOP_CLIENT_ID, CLIENT_CENTER_CLIENT_ID } from '../../constants';

class AddBankAccountThankYouPage extends React.Component {
  componentWillMount() {
    if (this.props.sourceRoute === StaticRoutes.TRANSFERS) {
      const sortedBanks = this.props.bankAccounts.sort((a, b) => b.bankId - a.bankId);
      this.props.endLinkBankAccountWorkflow(sortedBanks[0]);
      this.props.history.push(StaticRoutes.TRANSFERS);
    }
  }
  render() {
    const { isAopClient, isClientCenterClient } = this.props;

    return (
      <div className="thank-you-bank page">
        <section>
          <Header
            title="Add External Account"
          />
          <h3>Account Linked!</h3>
          {!isAopClient &&
            <p>
              Your account request has been received. Select Manage Accounts to see the current status of your request.
            </p>
          }
          {isAopClient &&
            <p>
              Click Transfer Funds to schedule a deposit to your new TradeStation account.
            </p>
          }
          <div className="actions">
            {isAopClient &&
              <RippleButton
                type="submit"
                onClick={() => this.props.history.push(StaticRoutes.TRANSFERS)}
                id="return_btn"
                className="primary"
              >
                transfer funds
              </RippleButton>
            }
            {!isAopClient &&
              <RippleButton
                type="submit"
                onClick={() => this.props.history.push(StaticRoutes.MANAGE_BANK_ACCOUNTS)}
                id="return_btn"
                className={isClientCenterClient ? 'default' : 'primary'}
              >
                  manage accounts
              </RippleButton>
            }
            <RippleButton
              type="submit"
              onClick={() => this.props.history.push(StaticRoutes.LINK_BANK_ACCOUNT_AUTOMATIC)}
              id="link_bank_account_btn"
              className="default"
            >
              add an account
            </RippleButton>
            {isClientCenterClient &&
              <RippleButton
                type="submit"
                onClick={() => this.props.history.push(StaticRoutes.TRANSFERS)}
                id="return_btn"
                className="primary"
              >
                  transfer funds
              </RippleButton>
            }
          </div>
        </section>
      </div>
    );
  }
}

AddBankAccountThankYouPage.propTypes = {
  sourceRoute: PropTypes.string,
  bankAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  endLinkBankAccountWorkflow: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAopClient: PropTypes.bool.isRequired,
  isClientCenterClient: PropTypes.bool.isRequired,
};

export default connect(
  state => {
    return {
      sourceRoute: state.linkAccounts.sourceRoute,
      bankAccounts: state.bankingAccounts.data,
      isAopClient: state.clientId === AOP_CLIENT_ID,
      isClientCenterClient: state.clientId === CLIENT_CENTER_CLIENT_ID,
    };
  },
  dispatch => {
    return {
      endLinkBankAccountWorkflow: (newlyLinkedAccount) => {
        dispatch(bankAccountActions.endLinkBankAccountWorkflow(newlyLinkedAccount));
      },
    };
  }
)(AddBankAccountThankYouPage);
