import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import Routes from './Routes';
import Loader from './Loader';
import ErrorBar from './components/ErrorBar';
import NavigationMenu from './components/NavigationMenu/NavigationMenu';
import AccountNavigation from './components/Account/AccountNavigation';
import requireAuthentication from './requireAuthentication';
import * as bankAccountActions from './actions/bankAccountActions';
import * as wireTransferActions from './actions/wireTransferActions';
import * as brokerageAccountActions from './actions/brokerageAccountActions';
import * as transferFeeActions from './actions/transferFeeActions';
import * as preloadActions from './actions/preloadActions';
import * as requestHistoryActions from './actions/requestHistoryActions';
import * as scheduledTransferActions from './actions/scheduledTransferActions';
import * as lookupActions from './actions/lookupActions';
import * as authenticationActions from './actions/authenticationActions';
import * as bankingDayActions from './actions/bankingDayActions';
import * as userProfileActions from './actions/userProfileActions';
import publishPageHeight from './publishPageHeight';
import './App.css';
import resizer from './resizer';
import { ANDROID_WEB_CLIENT_ID, AOP_CLIENT_ID, TANGO_CLIENT_ID } from './constants';
import MaintenancePage from './components/MaintenancePage/MaintenancePage';
import config from './config';

const onPageUpdate = () => {
  publishPageHeight();
  window.scrollTo(0, 0);
};

class App extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.maintenance = config().maintenance;
    this.env = config().env;
  }

  componentDidMount() {
    const { isAopClient } = this.props;

    let tokenReceived = false;

    if (isAopClient) {
      const script = document.createElement('script');
      script.src = resizer;
      document.body.appendChild(script);
    }

    this.props.history.replace(this.props.location.pathname);

    const token = sessionStorage.getItem('authToken');

    if (token && token.length >= 20) {
      tokenReceived = true;
      this.props.preloadData();
    }

    this.messageListenerId = window.addEventListener('message', (event) => {
      const accessToken = event.data.accessToken;

      if (accessToken && accessToken.length >= 20) {
        tokenReceived = true;
        this.props.updateAccessToken(accessToken);
        this.props.preloadData();
      }
    });

    if (this.env !== 'local') {
      // If after 5 seconds the page is still loading, we are gonna redirect to Client Center
      setTimeout(() => {
        if (!tokenReceived && this.maintenance === 'false') {
          const url = DOMPurify.sanitize(this.props.clientCenterHost.replace(/"/g, "'"));
          window.location.href = url;
        }
      }, 5000);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      onPageUpdate();
    }

    if (this.props.preloaded) {
      this.props.updateUserProfile(this.props.brokerageAccounts);
    }
  }

  componentWillUnmount() {
    window.removeEventListener(this.messageListenerId);
  }

  showSpinner() {
    return (
      <div className={`main pre-loader ${this.props.cssClassOverride}`}>
        <ErrorBar />
        <h1>Hold on while we get things ready</h1>
        <Loader />
        <p>{this.props.message}</p>
      </div>
    );
  }

  render() {
    if (this.maintenance === 'true') {
      return <MaintenancePage />;
    }
    const { clientId } = this.props;
    if (!this.props.preloaded) {
      return this.showSpinner();
    }

    return (
      <div className={`main ${this.props.cssClassOverride}`}>
        <div>
          {(clientId === TANGO_CLIENT_ID || clientId === ANDROID_WEB_CLIENT_ID) &&
            <AccountNavigation />
          }
          <ErrorBar />
        </div>
        <div className="container-fluid">
          {this.props.showNavigationMenu &&
            <NavigationMenu />}
          <Routes />
        </div>
      </div>
    );
  }
}

App.propTypes = {
  message: PropTypes.string,
  preloaded: PropTypes.bool,
  preloadData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape(),
  showNavigationMenu: PropTypes.bool,
  cssClassOverride: PropTypes.string,
  updateAccessToken: PropTypes.func,
  isAopClient: PropTypes.bool.isRequired,
  clientCenterHost: PropTypes.string,
  brokerageAccounts: PropTypes.shape(),
  updateUserProfile: PropTypes.func,
  clientId: PropTypes.string,
};

export default connect(
  state => {
    return {
      preloaded: state.preloader.preloaded,
      message: state.preloader.message,
      showNavigationMenu: state.clientConfig.showNavigation,
      cssClassOverride: state.clientConfig.cssClassOverride,
      isAopClient: state.clientId === AOP_CLIENT_ID,
      clientCenterHost: state.configuration.clientCenterHost,
      brokerageAccounts: state.brokerageAccounts,
      clientId: state.clientId,
    };
  },
  dispatch => {
    return {
      preloadData: () => {
        dispatch(bankAccountActions.requestBankingAccounts())
          .then(() => dispatch(wireTransferActions.fetchWireTransfers()))
          .then(() => dispatch(brokerageAccountActions.requestBrokerageAccounts()))
          .then(() => dispatch(requestHistoryActions.fetchHistory()))
          .then(() => dispatch(transferFeeActions.fetchFees()))
          .then(() => dispatch(lookupActions.fetchLookup()))
          .then(() => dispatch(bankingDayActions.requestBankingDays())) // To retrieve banking days
          .then(() => dispatch(scheduledTransferActions.fetchScheduledTransfers())) // To retrieve scheduled transfers
          .then(() => dispatch(preloadActions.completePreload()));
      },
      updateAccessToken: (accessToken) => {
        dispatch(authenticationActions.updateAccessToken(accessToken));
      },
      updateUserProfile: (brokerageAccounts) => {
        dispatch(userProfileActions.updateProfile(brokerageAccounts));
      },
    };
  }
)(requireAuthentication(App));
