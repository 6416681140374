/* eslint-disable */
import React from 'react';
import { Doughnut, Chart } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import pattern from 'patternomaly';
import '../../bootstrap.min.css';

class DonutChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: {},
      chartOptions: {},
      isChartEmpty: false,
      size: 150,
    };

    this.updateDonut = this.updateDonut.bind(this);
    this.formatAmount = this.formatAmount.bind(this);
    this.onScreenResize = this.onScreenResize.bind(this);
    this.getLastUpdateTime = this.getLastUpdateTime.bind(this);
  }
  componentWillMount() {
    const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
    Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
      draw: function () {
        originalDoughnutDraw.apply(this, arguments);
        const chart = this.chart.chart;
        const ctx = chart.ctx;

        const width = chart.width;
        
        const height = chart.height;
        
        const text = chart.config.options.elements.center; 

        let fontSize = (this.chart.radiusLength / 18).toFixed(2);
 
        const fontStyle = 'bold';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        ctx.font = `${fontStyle} ${fontSize}em Verdana`;
        ctx.fillStyle = '#007ABB';
        const textX = (chart.chartArea.left + chart.chartArea.right) / 2;
        const textY = height / 2;
        ctx.fillText(text.text, textX, textY);

        
        fontSize = (this.chart.radiusLength / (text.textNumber.length * 1.7)).toFixed(2);
        fontSize = fontSize > 1.49 ? 1.49 : fontSize;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = `${fontStyle} ${fontSize}em Verdana`;
        ctx.fillStyle = '#007ABB';
        const centerXnumb = (chart.chartArea.left + chart.chartArea.right) / 2;
        const centerYnumb = (chart.chartArea.top + chart.chartArea.bottom) / 2.4;
        ctx.fillText(text.textNumber, centerXnumb, centerYnumb);
      },
    });
  }
  componentDidMount() {
    this.updateDonut();
    window.addEventListener('resize', this.onScreenResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onScreenResize);
  }
  componentWillReceiveProps() {
    this.updateDonut();
  }
  formatAmount(amount, curr) {
    let locales = ['en-US','en-CA', 'en-GB', 'ja-JP', 'de-DE']; 

    let locale = locales[0];
    let display = 'symbol';

    let currency = !curr ? this.props.currency : curr;
    
    if (currency === 'CAD') {
     return Number(amount).toFixed(2).toLocaleString(locale) + 'C$';
    }

    return Number(amount).toLocaleString(locale, { style: 'currency', currencyDisplay: display, currency: currency });
  }
  getLastUpdateTime() {
    const time = moment(new Date()).tz('America/New_York').format('ha').toUpperCase();
    return `${time} ET`;
  }

  onScreenResize() {
    // const width = window.innerWidth
    //   || document.documentElement.clientWidth
    //   || document.body.clientWidth;
    // let height = this.state.size;

    // if (width <= 500) {
    //   height = width;
    // }

    // if (width <= 1500 && width > 500) {
    //   height = width / 2;
    // }

    // height = width / 3;
  }
  updateDonut() {
    let total = 1;
    let amount = 0;
    let newATW = 0;
    let newTotal = 1;
    const parent = this;

    if (this.props.selectedOriginAccount.accountBalanceDetail.NetLiquidatingValue > 0) {
      this.setState({
        isChartEmpty: false,
      });
      
      const atw = this.props.selectedOriginAccount.availableToWithdraw.find((x) => x.Currency === this.props.currency);
      if (!atw) {
        return;
      }

      const availableToWithdraw = (atw.Realtime > 0 ? atw.Realtime : 0);
      total = this.props.selectedOriginAccount.accountBalanceDetail.NetLiquidatingValue;

      total *= atw.ExchangeRate;

      amount = availableToWithdraw > 0 ? this.props.amount : 0;

      newATW = availableToWithdraw - amount;

      newTotal = total - (newATW + amount);

      if (amount > availableToWithdraw) {
        amount = availableToWithdraw;
        newATW = 0;
      }
    } else {
      this.setState({
        isChartEmpty: true,
      });
    }

    const newPercentage = (newATW * 100 / total).toFixed(0);
    const chartData = {
      labels: ['Amount', 'Available To Withdraw', ''],
      datasets: [
        {
          labels: '',
          borderColor: '#36A2EB',
          data: [amount, newATW, newTotal],
          backgroundColor: [
            pattern.draw('dash', 'lightblue'),
            '#36A2EB',
            '#FFFFFF',
          ],
        }
      ],
    };
    const chartOptions = {
      elements: {
        center: {
          textNumber: `${this.formatAmount(newATW)}`,
          text: 'Cash',
          fontColor: '#656566',
          fontFamily: 'EurobankSans',
          fontStyle: 'normal',
          minFontSize: 40,
          maxFontSize: 40,
        },
      },
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      responsive: true,
      cutoutPercentage: 75,
      title: {
        display: false,
        text: 'Cash',
      },
      animation: {
        animateRotate: true,
      },
      tooltips: {
        enabled: true,
        titleFontSize: 10,
        bodyFontSize: 10,
        callbacks: {
          title: (tooltipItems, data) => {
            return `${data.labels[tooltipItems[0].index]}`;
          },
          label: (tooltipItem, data) => {
            if (this.state.isChartEmpty) {
              return `${data.labels[tooltipItem.index]}: ${this.formatAmount(parseFloat(0))}`;
            }

            return `${this.formatAmount(parseFloat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]))}`;
          },
        },
      },
    };
    this.setState({
      chartData,
      chartOptions,
    });
  }
  render() {
    const {
      NetLiquidatingValue,
      MarketValue,
      TotalEquity,
      InitialMargin,
    } = this.props.selectedOriginAccount.accountBalanceDetail;
    
    //const isFuturePlus = this.props.selectedOriginAccount.isFuturePlus;
    const isFuture = this.props.selectedOriginAccount.assetType === 'FU' ? true : false;

    const { Realtime } = this.props.selectedOriginAccount.availableToWithdraw.find((x) => x.Currency === this.props.currency) ? 
                              this.props.selectedOriginAccount.availableToWithdraw.find((x) => x.Currency === this.props.currency) :
                              0;
    const assetTitle = isFuture ? 'Initial Margin:' : 'Assets:';
    const assetValue = isFuture ? this.formatAmount(InitialMargin, 'USD') : this.formatAmount(MarketValue, 'USD');
      
    // If is Future should be change later for the corresponding Initial Margin value
    const totalValue = isFuture ? this.formatAmount(TotalEquity, 'USD') : this.formatAmount(NetLiquidatingValue, 'USD');
  
    return (     
      <div className="row">
        <div id="donut" className="col-xs-6">
          <Doughnut data={this.state.chartData} options={this.state.chartOptions} height={this.state.size} />
        </div>
        <div id="metrics" className="col-xs-6">
          <table id="balance-details">
            <tbody>
              <tr>
                <td>Total:</td>
                <td className="atw-amounts">{`${totalValue}`}</td>
              </tr>
              <tr>
                <td>{assetTitle}</td>
                <td className="atw-amounts">{`${assetValue}`}</td>
              </tr>
              <tr className="atw">
                <td><strong>Available for Withdrawal:</strong></td>
                <td className="atw-amounts result">{`${Realtime > 0 ? this.formatAmount(Realtime) : this.formatAmount(0)}`}</td>
              </tr>
              <tr>
                <td colSpan="2" className="last-update"><span className="update-detail">{`as of ${this.getLastUpdateTime()}`}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 
    );
  }

}
export default DonutChart;

DonutChart.propTypes = {
  selectedOriginAccount: PropTypes.shape({
    accountBalanceDetail: PropTypes.shape({
      AvailableToWithdraw: PropTypes.number,
      TotalEquity: PropTypes.number,
      NetLiquidatingValue: PropTypes.number,
      MarketValue: PropTypes.number,
    }),
  }),
  amount: PropTypes.number,
};
