const { newrelic } = window;

const logMetric = (pageAction, metrics) => {
  if (metrics && metrics.constructor !== Object) {
    throw new TypeError(
      `${metrics.constructor}
      was provided to logMetric. The second parameter of a metric call must be empty or an Object.`
    );
  }

  if (newrelic) {
    newrelic.addPageAction(pageAction, metrics);
  }
};

export default logMetric;
