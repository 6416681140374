import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { StaticRoutes } from '../../Routes';
import Header from '../Header';
import CheckTransferInstructions from './CheckTransferInstructions';
import * as brokerageActions from '../../actions/brokerageAccountActions';
import RenderDropdownField from '../Forms/RenderDropdownField';
import { ChecksDirection, TransferMethod } from '../DropdownRouter';
import publishPageHeight from '../../publishPageHeight';
import IRACustodianAccount from '../IRA/IRACustodianAccount';

class CheckTransferDepositPage extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedAccount: null };

    this.accountSelected = this.accountSelected.bind(this);
    this.transferTypeSelected = this.transferTypeSelected.bind(this);
  }

  componentWillMount() {
    this.props.onRetrieveAccountSources();
  }

  componentDidUpdate() {
    publishPageHeight();
  }

  transferTypeSelected(event) {
    this.props.history.push(event.target.value);
  }

  accountSelected(event) {
    const selectedAccount = this.props.brokerageAccounts.find(
      account => account.key === event.target.value
    );
    this.setState({ selectedAccount });
  }

  render() {
    const { isWireTransferEnabled,
      isCheckTransferEnabled,
      isCheckDepositTransferEnabled,
      isCheckWithdrawalTransferEnabled } = this.props;
    return (
      <form id="check-deposit-form" className="page">
        <section>
          <Header
            title="Check"
            description={`Deposit cash into your TradeStation brokerage account from an
            external account by mailing in a check`}
          />
          <TransferMethod
            isWireTransferEnabled={isWireTransferEnabled}
            isCheckTransferEnabled={isCheckTransferEnabled}
            defaultValue={StaticRoutes.CHECK_TRANSFER_IN}
          />
          <div className="fee-schedule">
            <dl>
              <dt>Cost:</dt>
              <dd>Free</dd>
            </dl>
            <span className="separator" />
            <dl>
              <dt>Availability:</dt>
              <dd>Up to 3 Business Days</dd>
            </dl>
          </div>
          <ChecksDirection
            isCheckDepositTransferEnabled={isCheckDepositTransferEnabled}
            isCheckWithdrawalTransferEnabled={isCheckWithdrawalTransferEnabled}
            defaultValue={StaticRoutes.CHECK_TRANSFER_IN}
          />
          <Field
            component={RenderDropdownField}
            name="selectedAccountType"
            id="selectedAccountType"
            label="Account"
            onChange={this.accountSelected}
            required
            validationMessage="Please select an account from the list."
          >
            <option />
            {this.props.brokerageAccounts.map((account) =>
              <option key={account.key} value={account.key} title={account.accountName}>
                {account.displayName}
              </option>
            )}
          </Field>
          {this.state.selectedAccount &&
            <div>
              {(this.state.selectedAccount.isIRA && this.state.selectedAccount.isFuturesAccount)
                || this.state.selectedAccount.IsCustodian
                ? <IRACustodianAccount />
                : <CheckTransferInstructions accountNumber={this.state.selectedAccount.accountNumber} {...this.props} />
              }
            </div>
          }
        </section>
      </form>
    );
  }
}

CheckTransferDepositPage.propTypes = {
  brokerageAccounts: PropTypes.arrayOf(PropTypes.shape()),
  onRetrieveAccountSources: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  isWireTransferEnabled: PropTypes.bool,
  isCheckTransferEnabled: PropTypes.bool,
  isCheckDepositTransferEnabled: PropTypes.bool,
  isCheckWithdrawalTransferEnabled: PropTypes.bool,
};

export default reduxForm({
  form: 'CheckTransferDepositForm',
})(connect(
  (state) => {
    return {
      brokerageAccounts: state.brokerageAccounts.data,
      showPrintButton: state.clientConfig.showPrintButton,
      isWireTransferEnabled: state.clientConfig.isWireTransferEnabled,
      isCheckTransferEnabled: state.clientConfig.isCheckTransferEnabled,
      isCheckWithdrawalTransferEnabled: state.clientConfig.isCheckWithdrawalTransferEnabled,
      isCheckDepositTransferEnabled: state.clientConfig.isCheckDepositTransferEnabled,
    };
  },
  (dispatch) => {
    return {
      onRetrieveAccountSources: () => {
        dispatch(brokerageActions.requestBrokerageAccounts());
      },
      printPage: (event) => {
        event.preventDefault();
        window.print();
      },
    };
  })(CheckTransferDepositPage));
