import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { StaticRoutes } from '../../Routes';
import Header from '../Header';
import RippleLink from '../Forms/RippleLink';
import RippleButton from '../Forms/RippleButton';
import * as bankAccountActions from '../../actions/bankAccountActions';


const AccountOpeningLandingPage = (props) => {
  return (
    <div id="aop-landing" className="landing page">
      <Header
        title="Welcome to TradeStation!"
        description="Your application has been approved and your account is setup and ready to fund."
      />
      <ul id="navigation">
        {props.hasLinkedBankAccounts && (
          <li>
            <RippleLink to={StaticRoutes.TRANSFERS} className="primary">
              Smart Transfers
            </RippleLink>
          </li>
        )}
        <li>
          <RippleButton
            onClick={props.onAddBankAccount}
            className={`${props.clientId !== 'aop' ? 'default' : 'primary'}`}
            context="ACCOUNT_OPENING_LANDING_LINK_EXTERNAL_ACCOUNT_CLICK"
          >
            Link External Account
          </RippleButton>
        </li>
        <li>
          <RippleLink
            to={StaticRoutes.FUNDING_METHODS}
            className="default"
          >
            Other Funding Methods
          </RippleLink>
        </li>
      </ul>
    </div>
  );
};

AccountOpeningLandingPage.propTypes = {
  hasLinkedBankAccounts: PropTypes.bool,
  onAddBankAccount: PropTypes.func.isRequired,
  clientId: PropTypes.isRequired,
};

export default connect(
  state => {
    return {
      clientId: state.clientId,
      hasLinkedBankAccounts: state.bankingAccounts.everLoaded && state.bankingAccounts.data.length > 0,
    };
  },
  dispatch => {
    return {
      onAddBankAccount: () => {
        dispatch(bankAccountActions.startLinkBankAccountWorkflow(StaticRoutes.TRANSFERS));
        dispatch(push(StaticRoutes.LINK_BANK_ACCOUNT_AUTOMATIC));
      },
    };
  }
)(AccountOpeningLandingPage);
