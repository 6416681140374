import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as lookupKeys from '../../api/lookupData';
import { FORM_FIELD_NAMES } from './IRAContributionForm';

const ConfirmationField = ({ name, value }) => {
  return (
    <dl>
      <dt>{name}</dt>
      <dd>{value}</dd>
    </dl>
  );
};

ConfirmationField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};


const IRAContributionConfirmation = (props) => {
  const getValueFromKey = (name, key) => {
    const lookup = props.lookup[name] && props.lookup[name].find(l => l.Key.toString() === key);
    return lookup && lookup.Description;
  };

  const identifiers = props.lookup.identifiers;

  const ROLLOVER_TYPE_ID = identifiers.IRADepositType.Rollover;
  const CONTRIBUTION_TYPE_ID = identifiers.IRADepositType.Contribution;

  const {
    [FORM_FIELD_NAMES.DEPOSIT_TYPE_ID]: depositTypeId,
    [FORM_FIELD_NAMES.ROLLOVER_TYPE_ID]: rolloverTypeId,
    [FORM_FIELD_NAMES.CONTRIBUTION_YEAR]: contributionYear,
    [FORM_FIELD_NAMES.CONTRIBUTION_SOURCE_ID]: contributionSourceTypeId,
  } = (props.formValues.values.iraDeposit || {});
  return (
    <div>
      <ConfirmationField
        name="IRA Deposit Type"
        value={getValueFromKey(lookupKeys.IRADepositType, depositTypeId)}
      />
      {depositTypeId === ROLLOVER_TYPE_ID &&
        <div>
          {rolloverTypeId &&
            <ConfirmationField
              name="Rollover Type"
              value={getValueFromKey(lookupKeys.IRARolloverType, rolloverTypeId)}
            />
          }
        </div>
      }
      {depositTypeId === CONTRIBUTION_TYPE_ID &&
        <div>
          {contributionYear &&
            <ConfirmationField
              name="Contribution Year"
              value={contributionYear}
            />
          }
          {contributionSourceTypeId &&
            <ConfirmationField
              name="Contribution Source"
              value={getValueFromKey(lookupKeys.IRAContributionSourceType, contributionSourceTypeId)}
            />
          }
        </div>
      }
    </div>
  );
};

IRAContributionConfirmation.propTypes = {
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      iraDeposit: PropTypes.shape(),
    }),
  }),
  lookup: PropTypes.shape(),
};

export default connect(
  state => {
    return {
      lookup: state.lookup,
    };
  }
)(IRAContributionConfirmation);
