import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import 'react-datepicker/dist/react-datepicker.css';
import './Fields.css';
// import DatePickerWrapper from '../DatePicker/DatePicker';

class RenderDatePickerField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };

    this.onInvalid = this.onInvalid.bind(this);
    this.onChange = this.onChange.bind(this);
    this.filterDate = this.filterDate.bind(this);
    this.getMinDate = this.getMinDate.bind(this);
  }
  onInvalid(event) {
    event.preventDefault();
    if (!event.target.validity.valid) {
      this.setState({ error: this.props.validationMessage || event.target.validationMessage });
    }
  }
  onChange(event) {
    this.setState({ error: '' });
    if (this.props.input.onChange) this.props.input.onChange(event);
  }
  getMinDate() {
    if (this.props.disablePastDays) {
      return moment();
    }
    return null;
  }
  /* eslint-disable */
  filterDate(date) {
    const weekDayName = moment(date).format('dddd');
    if (this.props.disableWeekends && (weekDayName === 'Saturday' || weekDayName === 'Sunday')) {
      return false;
    }
    return true;
  }
  render() {
    const {
      scheduledDate,
      name,
      id,
      label,
      readOnly,
      placeholderText,
      includeDates,
      meta: { dirty, active, error },
    } = this.props;
    const floating = dirty ? 'is-dirty' : 'is-empty';
    const hasError = (error || this.state.error) ? 'has-error' : '';
    const isActive = active ? 'is-focused' : '';

    return (
      <div>
        <div className={`field text ${floating} ${hasError} ${isActive} datepicker-calendar`}>
          <DatePicker
            id={id}
            name={name}
            selected={scheduledDate}
            onChange={this.onChange}
            filterDate={this.filterDate}
            minDate={this.getMinDate()}
            placeholderText={placeholderText}
            includeDates={includeDates}
            customInput={<div>
            <label id="scheduled-date-label" htmlFor={id}>{label}</label>
            <input
              id={id}
              onChange={this.onChange}
              value={scheduledDate.toDate().toLocaleDateString()}
              readOnly={true}
              onKeyDown={(e) => e.preventDefault()}
            />
            <span className="expanding-line" />
            <span className="validation">{this.state.error}</span>
          </div>}
          />
          <span className="expanding-line" />
          <span className="validation">{this.state.error}</span>
        </div>
      </div>
    );
  }
}

RenderDatePickerField.propTypes = {
  input: PropTypes.shape().isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  validationMessage: PropTypes.string,
  disableWeekends: PropTypes.bool,
  disablePastDays: PropTypes.bool,
  readOnly: PropTypes.bool,
  scheduledDate: PropTypes.shape(),
};

export default RenderDatePickerField;
