/* eslint no-param-reassign: ["error", { "props": false }] */
export const FETCH_SCHEDULED_TRANSFER_REQUEST = 'SCHEDULED_TRANSFER_REQUEST';
export const FETCH_SCHEDULED_TRANSFER_SUCCESS = 'SCHEDULED_TRANSFER_SUCCESS';
export const FETCH_SCHEDULED_TRANSFER_ERROR = 'SCHEDULED_TRANSFER_ERROR';

export function fetchScheduledTransfers() {
  return {
    types: [FETCH_SCHEDULED_TRANSFER_REQUEST, FETCH_SCHEDULED_TRANSFER_SUCCESS, FETCH_SCHEDULED_TRANSFER_ERROR],
    fetch: {
      url: '/api/v1/scheduledTransfers?$orderby=NextValueDate asc',
    },
  };
}

export const EDIT_SCHEDULED_TRANSFER = 'EDIT_SCHEDULED_TRANSFER';
export function editScheduledTransfer(sourceRoute) {
  return {
    type: EDIT_SCHEDULED_TRANSFER,
    sourceRoute,
  };
}

export const DELETE_SCHEDULED_TRANSFER_REQUEST = 'DELETE_SCHEDULED_TRANSFER_REQUEST';
export const DELETE_SCHEDULED_TRANSFER_SUCCESS = 'DELETE_SCHEDULED_TRANSFER_SUCCESS';
export const DELETE_SCHEDULED_TRANSFER_ERROR = 'DELETE_SCHEDULED_TRANSFER_ERROR';
export function cancelScheduledTransfer(scheduledId) {
  return {
    types: [DELETE_SCHEDULED_TRANSFER_REQUEST, DELETE_SCHEDULED_TRANSFER_SUCCESS, DELETE_SCHEDULED_TRANSFER_ERROR],
    fetch: {
      url: `/api/v1/scheduledTransfers/${scheduledId}`,
      options: {
        method: 'delete',
        // body: JSON.stringify(data)
      },
    },
  };
}

export const UPDATE_ACH_SCHEDULED_TRANSFER_REQUEST = 'UPDATE_ACH_SCHEDULED_TRANSFER_REQUEST';
export const UPDATE_ACH_SCHEDULED_TRANSFER_SUCCESS = 'UPDATE_ACH_SCHEDULED_TRANSFER_SUCCESS';
export const UPDATE_ACH_SCHEDULED_TRANSFER_ERROR = 'UPDATE_ACH_SCHEDULED_TRANSFER_ERROR';
export function updateAchScheduledTransfer(scheduledEventId, data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [UPDATE_ACH_SCHEDULED_TRANSFER_REQUEST,
      UPDATE_ACH_SCHEDULED_TRANSFER_SUCCESS,
      UPDATE_ACH_SCHEDULED_TRANSFER_ERROR],
    fetch: {
      url: `/api/v1/scheduledTransfers/achtransfers/${scheduledEventId}`,
      options: { method: 'put', body: JSON.stringify(data) },
    },
  };
}

export const UPDATE_JOURNAL_SCHEDULED_TRANSFER_REQUEST = 'UPDATE_JOURNAL_SCHEDULED_TRANSFER_REQUEST';
export const UPDATE_JOURNAL_SCHEDULED_TRANSFER_SUCCESS = 'UPDATE_JOURNAL_SCHEDULED_TRANSFER_SUCCESS';
export const UPDATE_JOURNAL_SCHEDULED_TRANSFER_ERROR = 'UPDATE_JOURNAL_SCHEDULED_TRANSFER_ERROR';
export function updateJournalScheduledTransfer(scheduledEventId, data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [UPDATE_JOURNAL_SCHEDULED_TRANSFER_REQUEST,
      UPDATE_JOURNAL_SCHEDULED_TRANSFER_SUCCESS,
      UPDATE_JOURNAL_SCHEDULED_TRANSFER_ERROR],
    fetch: {
      url: `/api/v1/scheduledTransfers/journals/${scheduledEventId}`,
      options: { method: 'put', body: JSON.stringify(data) },
    },
  };
}

export const UPDATE_WIRE_SCHEDULED_TRANSFER_REQUEST = 'UPDATE_WIRE_SCHEDULED_TRANSFER_REQUEST';
export const UPDATE_WIRE_SCHEDULED_TRANSFER_SUCCESS = 'UPDATE_WIRE_SCHEDULED_TRANSFER_SUCCESS';
export const UPDATE_WIRE_SCHEDULED_TRANSFER_ERROR = 'UPDATE_WIRE_SCHEDULED_TRANSFER_ERROR';
export function updateWireScheduledTransfer(scheduledEventId, data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [UPDATE_WIRE_SCHEDULED_TRANSFER_REQUEST,
      UPDATE_WIRE_SCHEDULED_TRANSFER_SUCCESS,
      UPDATE_WIRE_SCHEDULED_TRANSFER_ERROR],
    fetch: {
      url: `/api/v1/scheduledTransfers/wires/${scheduledEventId}`,
      options: { method: 'put', body: JSON.stringify(data) },
    },
  };
}
