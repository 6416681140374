import React from 'react';
import PropTypes from 'prop-types';

const Header = (props) => {
  const { title, description } = props;
  return (
    <div className="page-head">
      <h1>{title}</h1>
      <h6>{description}</h6>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default Header;
