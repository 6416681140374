import { CHANGE_PERIOD } from '../actions/periodActions';

const initialState = {
  data: 30,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_PERIOD: {
      return Object.assign({}, state, {
        data: action.payload,
      });
    }
    default:
      return state;
  }
}
