import React from 'react';
import PropTypes from 'prop-types';
import './Fields.css';

class RenderDropdownField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };

    this.onInvalid = this.onInvalid.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    /* below is a hack for a bug in edge related to form submitting.
    see: https://github.com/facebook/react/issues/7655
    (@maxbittker) */
    const selectedIndex = this.select.selectedIndex;
    if (this.select && selectedIndex >= 0) {
      const options = this.select.options;
      const tempIndex = (selectedIndex + 1) % options.length;

      options[tempIndex].selected = true;
      options[selectedIndex].selected = true;
    }
  }

  onInvalid(event) {
    event.preventDefault();
    if (!event.target.validity.valid) {
      this.setState({ error: this.props.validationMessage || event.target.validationMessage });
    }
  }
  onChange(event) {
    this.setState({ error: '' });
    if (this.props.input.onChange) this.props.input.onChange(event);
  }
  render() {
    const {
      children, input, id, required, style, label, type, disabled, className, meta: { dirty, active, error },
    } = this.props;

    const floating = dirty ? 'is-dirty' : 'is-empty';
    const hasError = (error || this.state.error) ? 'has-error' : '';
    const isActive = active ? 'is-focused' : '';
    const isDisabled = disabled ? 'is-disabled' : '';

    return (
      <div className={`${floating} ${isDisabled} ${hasError} ${isActive} ${className || ''} dropdown field`}>
        <label htmlFor={id}>{label}</label>
        <label htmlFor={id} className="caret" />
        <select
          {...input}
          id={id}
          type={type}
          required={required}
          style={style}
          disabled={disabled}
          onInvalid={this.onInvalid}
          onChange={this.onChange}
          ref={select => { this.select = select; }}
        >
          {children}
        </select>
        <span className="expanding-line" />
        <span className="validation">{this.state.error}</span>
      </div>
    );
  }
}

RenderDropdownField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape(),
  ]).isRequired,
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]),
  className: PropTypes.string,
  validationMessage: PropTypes.string,
};

export default RenderDropdownField;
