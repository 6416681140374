import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { StaticRoutes } from '../../Routes';
import Header from '../Header';
import RippleLink from '../Forms/RippleLink';
import { AOP_CLIENT_ID } from '../../constants';

const LandingPage = props => {
  const { isAopClient, hasLinkedBankAccounts, redirectToYodlee } = props;
  if (isAopClient && !hasLinkedBankAccounts) redirectToYodlee();
  return (
    <div id="app-landing" className="landing page">
      <Header
        title="Fund Your Account"
      />
      <ul id="navigation">
        <li>
          <RippleLink
            to={StaticRoutes.TRANSFERS}
            className="primary"
          >
            Smart Transfers
          </RippleLink>
        </li>
        <li>
          <RippleLink
            to={StaticRoutes.LINK_BANK_ACCOUNT_AUTOMATIC}
            className="default"
          >
            Link External Account
          </RippleLink>
        </li>
        <li>
          <RippleLink
            to={StaticRoutes.FUNDING_METHODS}
            className="default"
          >
            Other Funding Methods
          </RippleLink>
        </li>
      </ul>
    </div>
  );
};

LandingPage.propTypes = {
  isAopClient: PropTypes.bool,
  hasLinkedBankAccounts: PropTypes.bool,
  redirectToYodlee: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    isAopClient: state.clientId === AOP_CLIENT_ID,
    hasLinkedBankAccounts: state.bankingAccounts.everLoaded && state.bankingAccounts.data.length > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    redirectToYodlee: () => {
      dispatch(push(StaticRoutes.LINK_BANK_ACCOUNT_AUTOMATIC));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
