import React from 'react';
import PropTypes from 'prop-types';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import RenderTextField from '../Forms/RenderTextField';
import RenderDropdownField from '../Forms/RenderDropdownField';
import * as lookupKeys from '../../api/lookupData';
import './IRA.css';

const DistributionFormField = (props) => {
  const hidden = props.lookup[props.lookupName].length <= 1;
  return (
    <Field
      {...props}
      className={hidden ? 'hidden' : ''}
      component={RenderDropdownField}
    >
      <option />
      {props.lookup[props.lookupName].map(lookup =>
        <option key={lookup.Key} value={lookup.Key}>{lookup.Description}</option>
      )}
    </Field>
  );
};

DistributionFormField.propTypes = {
  lookup: PropTypes.shape(),
  lookupName: PropTypes.string,
};

export const FORM_FIELD_NAMES = {
  IRA_TYPE_ID: 'iraTypeId',
  DISTRIBUTION_REASON_TYPE_ID: 'iraDistributionReasonTypeId',
  RECHARACTIRIZATION_YEAR: 'recharacterizationYear',
  DIRECT_ROLLOVER_ACCOUNT_TYPE_ID: 'directRolloverAccountTypeId',
  EXCESS_CONTRIBUTION_YEAR: 'excessContributionYear',
  IS_BEFORE_TAX_DEADLINE: 'isBeforeTaxDeadline',
  DISTRIBUTION_TYPE_ID: 'distributionTypeId',
  WITHHOLD_FEDERAL_TAXES: 'withholdFederalTaxes',
  FEDERAL_WITHHOLD_PERCENT: 'federalTaxWithholdingPercentage',
  FEDERAL_WITHHOLD_AMOUNT: 'federalTaxWithholdingAmount',
  WITHHOLD_STATE_TAXES: 'withholdStateTaxes',
  STATE_WITHHOLD_PERCENT: 'stateTaxWithholdingPercentage',
  STATE_WITHHOLD_AMOUNT: 'stateTaxWithholdingAmount',
};

export const IRADistributionFields = (props) => {
  const identifiers = props.lookup.identifiers;

  const RECHARACTIRIZATION_ID = identifiers[lookupKeys.IRADistributionReason].ContributionRecharacterization;
  const DIRECT_ROLLOVER_ID = identifiers[lookupKeys.IRADistributionReason].DirectRollover;
  const EXCESS_CONTRIBUTION_ID = identifiers[lookupKeys.IRADistributionReason].RemovalOfExcessContribution;

  const {
    [FORM_FIELD_NAMES.DISTRIBUTION_REASON_TYPE_ID]: distributionReasonTypeId,
    [FORM_FIELD_NAMES.WITHHOLD_FEDERAL_TAXES]: withholdFederalTaxes,
    [FORM_FIELD_NAMES.WITHHOLD_STATE_TAXES]: withholdStateTaxes,
    [FORM_FIELD_NAMES.FEDERAL_WITHHOLD_AMOUNT]: federalWithholdTaxAmount,
    [FORM_FIELD_NAMES.FEDERAL_WITHHOLD_PERCENT]: federalWithholdTaxPercentage,
    [FORM_FIELD_NAMES.STATE_WITHHOLD_AMOUNT]: stateWithholdTaxAmount,
    [FORM_FIELD_NAMES.STATE_WITHHOLD_PERCENT]: stateWithholdTaxPercentage,
  } = (props.formValues.values.iraWithdrawal || {});
  return (
    <div className="ira-withdraw-fields">
      <p>Tell us more about this IRA distribution</p>
      <DistributionFormField
        {...props}
        name={`iraWithdrawal.${FORM_FIELD_NAMES.IRA_TYPE_ID}`}
        label="IRA Account Type"
        required
        lookupName={lookupKeys.IRAType}
      />
      <DistributionFormField
        {...props}
        name={`iraWithdrawal.${FORM_FIELD_NAMES.DISTRIBUTION_REASON_TYPE_ID}`}
        label="Distribution Reason"
        required
        lookupName={lookupKeys.IRADistributionReason}
      />
      {distributionReasonTypeId === RECHARACTIRIZATION_ID &&
        <Field
          {...props}
          component={RenderTextField}
          name={`iraWithdrawal.${FORM_FIELD_NAMES.RECHARACTIRIZATION_YEAR}`}
          label="Recharacterization Year"
          type="number"
          min="2000"
          max={new Date().getFullYear().toString()}
          required
        />
      }
      {distributionReasonTypeId === DIRECT_ROLLOVER_ID &&
        <DistributionFormField
          {...props}
          name={`iraWithdrawal.${FORM_FIELD_NAMES.DIRECT_ROLLOVER_ACCOUNT_TYPE_ID}`}
          label="Rollover Account Type"
          required
          lookupName={lookupKeys.IRADirectRolloverAccountType}
        />
      }
      {distributionReasonTypeId === EXCESS_CONTRIBUTION_ID &&
        <div>
          <Field
            {...props}
            component={RenderTextField}
            name={`iraWithdrawal.${FORM_FIELD_NAMES.EXCESS_CONTRIBUTION_YEAR}`}
            label="Excess Contribution Year"
            type="number"
            min="2000"
            max={new Date().getFullYear().toString()}
            required
          />
          <label
            htmlFor="isBeforeTaxDeadline"
            className="before-tax-deadline"
          >
            <Field
              component="input"
              type="checkbox"
              name={`iraWithdrawal.${FORM_FIELD_NAMES.IS_BEFORE_TAX_DEADLINE}`}
              label="Is Before Tax Deadline?"
              id="isBeforeTaxDeadline"
            />
            &nbsp; Before Tax Deadline?
          </label>
        </div>
      }
      <DistributionFormField
        {...props}
        name={`iraWithdrawal.${FORM_FIELD_NAMES.DISTRIBUTION_TYPE_ID}`}
        label="Distribution Type"
        required
        lookupName={lookupKeys.IRADistributionType}
      />
      <div>
        <label
          htmlFor="witholdFederalTaxes"
          className="before-tax-deadline"
        >
          <Field
            component="input"
            type="checkbox"
            name={`iraWithdrawal.${FORM_FIELD_NAMES.WITHHOLD_FEDERAL_TAXES}`}
            label="Is Before Tax Deadline?"
            id="witholdFederalTaxes"
          />
          &nbsp; Withold Federal Taxes?
        </label>
        {withholdFederalTaxes &&
          <div className="withhold-amount">
            <Field
              {...props}
              component={RenderTextField}
              name={`iraWithdrawal.${FORM_FIELD_NAMES.FEDERAL_WITHHOLD_AMOUNT}`}
              label="Amount"
              type="number"
              min="0.01"
              max="9999999999.99"
              step="any"
              required={!federalWithholdTaxPercentage}
              disabled={federalWithholdTaxPercentage && true}
            />
            <span>or</span>
            <Field
              {...props}
              component={RenderTextField}
              type="number"
              min="1"
              max="100"
              step="any"
              name={`iraWithdrawal.${FORM_FIELD_NAMES.FEDERAL_WITHHOLD_PERCENT}`}
              label="Percentage (%)"
              required={!federalWithholdTaxAmount}
              disabled={federalWithholdTaxAmount && true}
            />
          </div>
        }
      </div>
      {props.account.canWithholdStateTax &&
      <div>
        <label
          htmlFor="witholdStateTaxes"
          className="before-tax-deadline"
        >
          <Field
            component="input"
            type="checkbox"
            name={`iraWithdrawal.${FORM_FIELD_NAMES.WITHHOLD_STATE_TAXES}`}
            label="Is Before Tax Deadline?"
            id="witholdStateTaxes"
          />
          &nbsp; Withold State Taxes?
        </label>
        {withholdStateTaxes &&
          <div className="withhold-amount">
            <Field
              {...props}
              component={RenderTextField}
              name={`iraWithdrawal.${FORM_FIELD_NAMES.STATE_WITHHOLD_AMOUNT}`}
              label="Amount"
              type="number"
              min="0.01"
              max="9999999999.99"
              step="any"
              required={!stateWithholdTaxPercentage}
              disabled={stateWithholdTaxPercentage && true}
            />
            <span>or</span>
            <Field
              {...props}
              component={RenderTextField}
              type="number"
              min="0.01"
              max="100"
              step="any"
              name={`iraWithdrawal.${FORM_FIELD_NAMES.STATE_WITHHOLD_PERCENT}`}
              label="Percentage (%)"
              required={!stateWithholdTaxAmount}
              disabled={stateWithholdTaxAmount && true}
            />
          </div>
        }
      </div>
      }
    </div>
  );
};

IRADistributionFields.propTypes = {
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      iraWithdrawal: PropTypes.shape(),
    }),
  }),
  lookup: PropTypes.shape(),
  account: PropTypes.shape({
    key: PropTypes.string,
    isIRA: PropTypes.bool,
    iraTypeId: PropTypes.number,
    canWithholdStateTax: PropTypes.bool,
  }).isRequired,
};

class IRADistributionForm extends React.Component {
  componentDidUpdate(prevProps) {
    const hasValue = this.props.formValues.values.iraWithdrawal;
    if (hasValue && prevProps.account.key === this.props.account.key) return;
    if (this.props.account.isIRA) {
      const field = `iraWithdrawal.${FORM_FIELD_NAMES.IRA_TYPE_ID}`;
      this.props.dispatch(change(this.props.form, field, this.props.account.iraTypeId));
    } else if (hasValue) {
      this.props.dispatch(change(this.props.form, 'iraWithdrawal', null));
    }
  }

  render() {
    if (!this.props.account.isIRA) return <div />;
    return (
      <IRADistributionFields {...this.props} />
    );
  }
}

IRADistributionForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  formValues: PropTypes.shape().isRequired,
  account: PropTypes.shape({
    key: PropTypes.string,
    isIRA: PropTypes.bool,
    iraTypeId: PropTypes.number,
    canWithholdStateTax: PropTypes.bool,
  }).isRequired,
};

export default connect(
  state => {
    return {
      lookup: state.lookup,
    };
  }
)(IRADistributionForm);
