import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Header from '../Header';
import { StaticRoutes } from '../../Routes';
import { AOP_CLIENT_ID, DEEP_LINK } from '../../constants';
import AddBankAccountAutomatic from './AddBankAccountAutomatic';
import * as bankAccountActions from '../../actions/bankAccountActions';
import * as brokerAccounts from '../../actions/brokerageAccountActions';
import * as userProfileActions from '../../actions/userProfileActions';
import DisclaimerPLAID from '../Common/DisclaimerPLAID';
import RippleLink from '../Forms/RippleLink';
import RippleButton from '../Forms/RippleButton';
import getUserProfile from '../../userProfile';
import './AutomaticLinkingPage.css';

class AutomaticLinkingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLegalDislaimerConfirmed: false };

    this.onLegalDisclaimerConfirmed = this.onLegalDisclaimerConfirmed.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.isPlaidAllowed = this.isPlaidAllowed.bind(this);
    this.goBackToPreviousPage = this.goBackToPreviousPage.bind(this);
  }

  onLegalDisclaimerConfirmed() {
    if (!this.state.isLegalDislaimerConfirmed) {
      this.setState({ isLegalDislaimerConfirmed: true });
    }
  }

  onCancel() {
    this.goBackToPreviousPage();
  }

  goBackToPreviousPage() {
    const fromRouteExists = (this.props.history.location.state &&
      this.props.history.location.state.from) || '';

    if (fromRouteExists) {
      delete this.props.history.location.state.from;
      this.props.history.push(StaticRoutes.MANAGE_BANK_ACCOUNTS);
    } else {
      this.props.history.push(StaticRoutes.ROOT);
    }
  }

  isPlaidAllowed() {
    return this.props.clientConfig.isPlaidEnabled
      && getUserProfile(this.props.clientId, this.props.brokerageAccounts.data).isPlaidAuthorized;
  }

  isMobileSDKClient() {
    return getUserProfile(this.props.clientId, this.props.brokerageAccounts.data).isMobileSDKClient;
  }

  render() {
    if (!this.props.accounts.length) {
      return (
        <div className="messageError">
          <p>
            We&lsquo;re sorry. It looks like your application has not been
            completed yet.
          </p>
          <p>
            Before adding an external account, please complete your brokerage
            application by:
          </p>
          <ul>
            <li>
              <p>
                Clicking on the green &apos;Add An Account&apos; button at the
                top of the page.
              </p>
            </li>
            <li>
              <p>
                Once that page loads, clicking on the blue &apos;Complete
                Application&apos; button.
              </p>
            </li>
          </ul>
          <p>
            If you still need assistance, please press the &apos;Contact
            Us&apos; button on that page.
          </p>
        </div>
      );
    }

    // If plaid is not allowed this means the user 
    // its trying to access Yodlee from a mobile.
    if (!this.isPlaidAllowed()) {
      return (
        <div className="messageError">
          <p>
            This service has been upgraded and is no longer available
            in the app version you are using.
          </p>
          <p>
            Please visit either the Apple App Store or Google Play Store
            to search for and install the latest version of TradeStation.
          </p>
          <div className="wrapper">
            <RippleButton
              onClick={this.onCancel}
              className="default"
              id="yodlee-cancel"
              isPlaidDisclaimer
            >
              Ok
            </RippleButton>
          </div>
        </div>
      );
    }

    if (!this.state.isLegalDislaimerConfirmed && this.isPlaidAllowed()) {
      return (
        <div className="link-account page">
          {<DisclaimerPLAID />}
          <div className="wrapper">
            <RippleButton
              onClick={this.onCancel}
              className="default"
              id="disclaimer-cancel"
              isPlaidDisclaimer
            >
              Cancel
            </RippleButton>
            {this.isMobileSDKClient() ?

              (<RippleLink
                to={DEEP_LINK}
                className="primary"
              >
                Accept
              </RippleLink>) :
              (<RippleButton
                onClick={this.onLegalDisclaimerConfirmed}
                className="primary"
                id="disclaimer-accept"
                isPlaidDisclaimer
              >
                Accept
              </RippleButton>)
            }
          </div>
        </div>
      );
    }

    return (
      <div className="link-account page">
        <section>
          {!this.props.isAopClient &&
            <Header title="Add External Account" />
          }
          <AddBankAccountAutomatic {...this.props} />
        </section>
      </div>
    );
  }
}

AutomaticLinkingPage.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape),
  isAopClient: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  brokerageAccounts: PropTypes.shape(),
  updateUserProfile: PropTypes.func,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.object,
    }),
    push: PropTypes.func,
  }),
  clientConfig: PropTypes.shape({
    isPlaidEnabled: PropTypes.bool.isRequired,
  }),
  userProfile: PropTypes.shape({
    isEmployee: PropTypes.bool.isRequired,
  }),
};


export default connect(
  state => {
    const brokerageAccountsList = state.brokerageAccounts.data;
    return {
      authToken: state.authentication.authToken,
      fastlink: state.fastlink,
      clientConfig: state.clientConfig,
      accounts: brokerageAccountsList,
      isAopClient: state.clientId === AOP_CLIENT_ID,
      brokerageAccounts: state.brokerageAccounts,
      userProfile: state.userProfile,
      clientId: state.clientId,
      userId: state.userId,
    };
  },
  dispatch => {
    return {
      getBrokerageAccounts: () => {
        return dispatch(brokerAccounts.requestBrokerageAccounts());
      },
      fetchYodleeFrame: () => {
        return dispatch(bankAccountActions.fetchYodleeFrame());
      },
      fetchYodleeHtml: url => {
        const params = new URLSearchParams(url);

        const data = {
          userSession: params.get('userSession'),
          accessToken: params.get('accessToken'),
          username: params.get('username'),
        };
        return dispatch(bankAccountActions.fetchYodleeHtml(data));
      },
      fetchPlaidIAV: () => {
        return dispatch(bankAccountActions.fetchPlaidIAV());
      },
      increaseFastlinkLoginCount: data => {
        return dispatch(bankAccountActions.increaseFastlinkLoginCount(data));
      },
      addLinkedBankAccount: accountId => {
        const data = { yodleeAccountId: accountId };
        dispatch(bankAccountActions.submitBankAccountAutomatic(data)).then(
          action => {
            if (action.isSuccess) {
              dispatch(bankAccountActions.requestBankingAccounts()).then(() =>
                dispatch(push(StaticRoutes.LINK_BANK_ACCOUNT_THANK_YOU))
              );
            }
          }
        );
      },
      addLinkedBankPlaidAccount: (accountsInfo) => {
        const accountId = accountsInfo.numbers.ach[0].account_id;
        const data = {
          accountId: accountId,
          token: accountsInfo.token,
        };

        dispatch(bankAccountActions.submitBankAccountAutomaticFromPlaid(data)).then(
          action => {
            if (action.isSuccess) {
              dispatch(bankAccountActions.requestBankingAccounts()).then(bkAccounts => {
                if (bkAccounts.isSuccess) {
                  dispatch(push(StaticRoutes.LINK_BANK_ACCOUNT_THANK_YOU));
                }
              });
            }
          }
        );
      },
      addLinkedBankPlaidAccountEx: (accountsInfo) => {
        const data = accountsInfo;

        dispatch(bankAccountActions.submitBankAccountAutomaticFromPlaidEx(data)).then(
          action => {
            if (action.isSuccess) {
              const LOADING = true;
              dispatch(bankAccountActions.requestBankingAccounts(LOADING)).then(bkAccounts => {
                if (bkAccounts.isSuccess) {
                  dispatch(push(StaticRoutes.LINK_BANK_ACCOUNT_THANK_YOU));
                }
              });
            }
          }
        );
      },

      onYodleeLoad: frameLoaded => {
        dispatch(bankAccountActions.yodleeLoadCompleted(frameLoaded));
      },
      onFastlinkFailure: () => {
        dispatch(bankAccountActions.fastlinkFailure());
      },
      onPlaidlinkFailure: plaidError => {
        dispatch(bankAccountActions.plaidlinkFailure(plaidError));
      },
      updateUserProfile: (brokerageAccounts) => {
        dispatch(userProfileActions.updateProfile(brokerageAccounts));
      },
      logPlaidEvent: (plaidEvent) => {
        dispatch(bankAccountActions.submitPlaidEvent(plaidEvent));
      },
    };
  }
)(AutomaticLinkingPage);
