import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import RenderDropdownField from '../Forms/RenderDropdownField';
import lookupData from '../../api/lookupData';

class PeriodForm extends React.Component {
  constructor(props) {
    super(props);
    this.onPeriodChange = this.onPeriodChange.bind(this);
  }

  componentDidMount() {
    this.props.onInitializeForm(this.props, 'transfersPeriodForm', this.props.formValues.values.transferPeriod);
  }

  onPeriodChange(event) {
    if (event.target.value === '') {
      event.preventDefault();
      return;
    }
    this.props.onChangePeriod(event.target.value, this.props.form);
  }

  render() {
    const {
      formValues,
    } = this.props;
    return (
      <form id="transfers-period-form">
        <Field
          className="transfers-period"
          component={RenderDropdownField}
          id="transferHistoryPeriod"
          name="transferHistoryPeriod"
          key={`${formValues.values.transferPeriod}_Code`}
          label="Period"
          required
          onChange={this.onPeriodChange}
        >
          <option />
          {lookupData.TransferHistoryPeriods.map(period =>
            <option
              value={period.Key}
              key={period.Key}
            >
              {period.Value}
            </option>
          )}
        </Field>
      </form>
    );
  }
}

PeriodForm.propTypes = {
  onChangePeriod: PropTypes.func.isRequired,
  onInitializeForm: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      transferPeriod: PropTypes.string,
    }),
  }),
};

export default reduxForm({
  form: 'transfersPeriodForm',
  destroyOnUnmount: false,
})(PeriodForm);
