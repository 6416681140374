import { CHANGE_AMOUNT } from '../actions/amountActions';
import { normalizeAmount } from '../components/Forms/RenderTextField';

const initialState = {
  current: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_AMOUNT: {
      let newAmount = normalizeAmount(action.payload);
      newAmount = parseFloat(newAmount);
      return Object.assign({}, state, {
        current: newAmount,
      });
    }
    default:
      return state;
  }
}
