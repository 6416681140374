import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import { StaticRoutes } from '../../Routes';
import RippleButton from '../Forms/RippleButton';
import RenderTextField from '../Forms/RenderTextField';
import { CLIENT_CENTER_CLIENT_ID } from '../../constants';

const AccountNickNameFormatErrorMessage = 'Nickname must be between 1 and 20 characters';

const DeleteAccountView = (props) => {
  return <a
    href="#delete-account"
    role="button"
    id={`delete_bank_${props.account.accountNumber && props.account.accountNumber.slice(-4)}`}
    onClick={() => props.onDeleteAccount(props.account)}
  >
    delete
  </a>;
};

const CreateNickNameAccountView = (props) => {
  return <a
    href="#createnickname-account"
    role="button"
    id={`create_nickname_bank_${props.account.accountNumber && props.account.accountNumber.slice(-4)}`}
    onClick={() => props.onCreateAccountNickName(props.account)}
  >
    create nickname
  </a>;
};
DeleteAccountView.propTypes = {
  account: PropTypes.shape({
    accountNumber: PropTypes.string,
  }).isRequired,
  onDeleteAccount: PropTypes.func.isRequired,
};

CreateNickNameAccountView.propTypes = {
  account: PropTypes.shape({
    accountNumber: PropTypes.string,
  }).isRequired,
  onCreateAccountNickName: PropTypes.func.isRequired,
};

const DeleteBankAccountConfirmationModal = (props) => {
  const { account, onDeleteAccount } = props;
  const onConfirmDeleteAccount = () => {
    onDeleteAccount(account);
  };
  return (
    <div className="modal">
      <div>
        <p>Are you sure you want to delete {account.bankName} - {account.accountNumber}?</p>
        <div className="actions">
          <RippleButton
            onClick={props.onCancelDeleteAccount}
            className="default"
            type="button"
          >
            Cancel
          </RippleButton>
          <RippleButton
            onClick={onConfirmDeleteAccount}
            className="success"
            type="button"
            disabled={props.deleting}
            id="confirm"
          >
            Confirm
          </RippleButton>
        </div>
      </div>
    </div>
  );
};

DeleteBankAccountConfirmationModal.propTypes = {
  account: PropTypes.shape(),
  onCancelDeleteAccount: PropTypes.func.isRequired,
  onDeleteAccount: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
};

const CreateNickNameConfirmationModal = (props) => {
  const { account, onCreateAccountNickName } = props;

  const onConfirmNickName = () => {
    onCreateAccountNickName(account);
  };

  return (
    <div className="modal">
      <div>
        <p>Create nickname for {account.bankName} - {account.accountNumber}</p>
        <Field
          component={RenderTextField}
          name="accountNickName"
          type="text"
          pattern="^[a-zA-Z0-9_\-\+\/]{3,50}$"
          step="any"
          id={`create_nickname_${account.BankAccountNickName}`}
          label="Account Nickname"
          value={account.BankAccountNickName}
          validationMessage={AccountNickNameFormatErrorMessage}
          onChange={props.onChangeAccountNickName}
          required
        />
        <div className="actions">
          <RippleButton
            onClick={() => { props.onCancelCreateAccountNickName(); props.onChangeAccountNickName(null); }}
            className="default"
            type="button"
          >
            Cancel
          </RippleButton>

          <RippleButton
            onClick={() => { onConfirmNickName(); props.onChangeAccountNickName(null); }}
            className="success"
            type="button"
            disabled={props.updatingNickName}
            id="confirm"
          >
            Confirm
          </RippleButton>
        </div>
      </div>

    </div>
  );
};

CreateNickNameConfirmationModal.propTypes = {
  account: PropTypes.shape(),
  onCancelCreateAccountNickName: PropTypes.func.isRequired,
  onCreateAccountNickName: PropTypes.func.isRequired,
  updatingNickName: PropTypes.bool.isRequired,
  onChangeAccountNickName: PropTypes.func.isRequired,
};

const FullAccountView = (props) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Account Name</th>
          <th>Account Nickname</th>
          <th>Account Type</th>
          <th>Account Number</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {props.linkedAccounts.map((account) =>
          <tr key={account.key}>
            <td>{account.bankName}</td>
            <td>{account.BankAccountNickName}</td>
            <td>{account.accountDetail} Account</td>
            <td>-{account.accountNumber && account.accountNumber.slice(-4)}</td>
            <td className="status">{account.linkedAccounts.brokerage.length > 0 ? 'Approved' : ''}</td>
            <td>
              <DeleteAccountView
                account={account}
                {...props}
              />
              |
              <CreateNickNameAccountView
                account={account}
                {...props}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

FullAccountView.propTypes = {
  linkedAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const BriefAccountView = (props) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Account Name</th>
          <th>Account Nickname</th>
          <th>Account Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {props.linkedAccounts.map((account) =>
          <tr key={account.key}>
            <td>{account.bankName}</td>
            <td>{account.BankAccountNickName}</td>
            <td>{account.accountDetail} Account</td>
            <td className="abc">
              <DeleteAccountView
                account={account}
                {...props}
              />
              |
              <CreateNickNameAccountView
                account={account}
                {...props}
              />

            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

BriefAccountView.propTypes = {
  linkedAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

class ManageAccountsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      deleting: false,
      showCreateNickNameModal: false,
      updatingNickName: false,
      accountNickName: '',
    };

    this.onAddNewAccount = this.onAddNewAccount.bind(this);
    this.onDeleteAccount = this.onDeleteAccount.bind(this);
    this.onCreateAccountNickName = this.onCreateAccountNickName.bind(this); //

    this.onCancelDeleteAccount = this.onCancelDeleteAccount.bind(this);
    this.onCancelCreateAccountNickName = this.onCancelCreateAccountNickName.bind(this);
    this.onConfirmDeleteAccount = this.onConfirmDeleteAccount.bind(this);
    this.onConfirmNickName = this.onConfirmNickName.bind(this);
    this.onChangeAccountNickName = this.onChangeAccountNickName.bind(this);
  }

  componentDidMount() {
    this.props.requestBankingAccounts();
  }

  onAddNewAccount() {
    this.props.onAddNewAccount();
    this.props.history.push(StaticRoutes.LINK_BANK_ACCOUNT_AUTOMATIC, { from: StaticRoutes.MANAGE_BANK_ACCOUNTS });
  }

  onDeleteAccount(account) {
    this.setState({ account, showDeleteModal: true, deleting: false });
  }

  onCreateAccountNickName(account) {
    this.setState({ account, showCreateNickNameModal: true, updatingNickName: false });
  }

  onChangeAccountNickName(event) {
    this.setState({ accountNickName: event ? event.target.value : null });
  }

  onConfirmNickName(account) {
    this.setState({ updatingNickName: true });
    return this.props.onCreateAccountNickName({ ...account, BankAccountNickName: this.state.accountNickName })
      .then(() => {
        this.props.resetForm();
        this.setState({ showCreateNickNameModal: false, updatingNickName: false });
      });
  }

  onCancelCreateAccountNickName() {
    this.props.resetForm();
    this.setState({ showCreateNickNameModal: false, updatingNickName: false });
  }

  onConfirmDeleteAccount(account) {
    this.setState({ deleting: true });
    return this.props.onDeleteAccount(account)
      .then(() => {
        this.setState({ showDeleteModal: false, deleting: false });
      });
  }

  onCancelDeleteAccount() {
    this.setState({ showDeleteModal: false, deleting: false });
  }

  showAccounts() {
    return this.props.showFullAccountView ?
      <FullAccountView
        linkedAccounts={this.props.linkedAccounts}
        onDeleteAccount={this.onDeleteAccount}
        onCreateAccountNickName={this.onCreateAccountNickName}
      /> :
      <BriefAccountView
        linkedAccounts={this.props.linkedAccounts}
        onDeleteAccount={this.onDeleteAccount}
        onCreateAccountNickName={this.onCreateAccountNickName}
      />;
  }

  render() {
    const { clientId } = this.props;
    const isClientCenter = clientId === CLIENT_CENTER_CLIENT_ID;

    return (
      <div className="manage-accounts">
        {!this.props.linkedAccounts.length ?
          <p>You don&lsquo;t have any linked external accounts, you can add one by clicking the button below</p>
          : this.showAccounts()}
        {
          isClientCenter ?
            <div className="actions">
              <RippleButton
                type="submit"
                onClick={this.onAddNewAccount}
                className="default"
              >
                Add External Account
              </RippleButton>
              <RippleButton
                type="submit"
                onClick={() => this.props.history.push(StaticRoutes.TRANSFERS)}
                id="return_btn"
                className="primary"
              >
                transfer funds
              </RippleButton>
            </div> :
            <div className="manage-account-actions">
              <RippleButton
                onClick={this.onAddNewAccount}
                className="primary"
              >
                Add External Account
              </RippleButton>
            </div>
        }
        {this.state.showDeleteModal &&
          <DeleteBankAccountConfirmationModal
            onDeleteAccount={this.onConfirmDeleteAccount}
            onCancelDeleteAccount={this.onCancelDeleteAccount}
            account={this.state.account}
            deleting={this.state.deleting}
          />}
        {this.state.showCreateNickNameModal &&
          <CreateNickNameConfirmationModal
            onCreateAccountNickName={this.onConfirmNickName}
            onCancelCreateAccountNickName={this.onCancelCreateAccountNickName}
            account={this.state.account}
            updatingNickName={this.state.updatingNickName}
            onChangeAccountNickName={this.onChangeAccountNickName}
          />}
      </div>
    );
  }
}

ManageAccountsForm.propTypes = {
  linkedAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  requestBankingAccounts: PropTypes.func.isRequired,
  onDeleteAccount: PropTypes.func.isRequired,
  onAddNewAccount: PropTypes.func,
  resetForm: PropTypes.func.isRequired,
  onCreateAccountNickName: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  showFullAccountView: PropTypes.bool,
  clientId: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  resetForm: () => dispatch(reset('manageAccountsForm')),
});

export default connect(null, mapDispatchToProps)(
  reduxForm({
    form: 'manageAccountsForm',
    destroyOnUnmount: false,
  })(ManageAccountsForm)
);
