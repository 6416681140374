import React from 'react';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';
// import { DynamicRoutes } from '../../Routes';
// import * as brokerageAccountActions from '../../actions/brokerageAccountActions';
import * as requestHistoryActions from '../../actions/requestHistoryActions';
import * as scheduledTransferActions from '../../actions/scheduledTransferActions';
import * as periodActions from '../../actions/periodActions';
// import * as scheduledTransferDetailsActions from '../../actions/scheduledTransferDetailsActions';
import './RequestHistory.css';
import Header from '../Header';
import TransferHistory from './TransferHistory';
import ModalRoot from '../../components/Modals/ModalRoot';
import * as modalActions from '../../actions/modalActions';
import PeriodForm from './PeriodForm';

// const PENDING_STATUS = ['Submitted', 'Pending', 'Suspended'];
const SUSPENDED_STATUS = ['Suspended'];
const PENDING_ACCOUNT_STATE = ['pending_account_approval'];
const BOUNCED_RETURNED_TYPE = ['Bounced/Returned'];

class RequestHistoryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPendingTransfers: props.showFullHistory || true,
      showCompletedTransfers: props.showFullHistory || false,
      focus: '',
      scheduledId: null,
    };

    this.onStatusFocus = this.onStatusFocus.bind(this);
    this.onStatusBlur = this.onStatusBlur.bind(this);
    this.onShowModal = this.onShowModal.bind(this);
    this.onConfirmModal = this.onConfirmModal.bind(this);
    // this.onConfirmDetailModal = this.onConfirmDetailModal.bind(this);
  }

  componentDidMount() {
    this.props.fetchRequestsHistory(this.props);
  }

  componentWillReceiveProps(props) {
    if (!props.modal.modalProps.open) {
      this.setState({
        scheduledId: null,
      });
    }
  }

  onStatusFocus() {
    this.setState({ focus: 'is-focused' });
  }

  onStatusBlur() {
    this.setState({ focus: '' });
  }

  onShowModal(scheduledId) {
    this.setState({
      scheduledId,
    });
    this.props.showModal({
      modalType: 'alert',
      modalProps: {
        open: true,
        title: 'Confirmation',
        message: 'Are you sure you want to cancel this scheduled transfer?',
        isConfirmModal: true,
        onConfirmModal: this.onConfirmModal,
        onCancelModal: this.onCancelModal,
      },
    });
  }

  onShowDetailModal(reason) {
    this.showModal({
      modalType: 'alert',
      modalProps: {
        open: true,
        title: '',
        message: reason,
        isLinkModal: true,
        isConfirmModal: false,
        onConfirmModal: this.hideModal,
        confirmText: 'DISMISS',
        linkText: 'NEW TRANSFER',
      },
    });
  }

  onConfirmModal() {
    // call api to cancel scheduled
    this.props.onCancel(this.state.scheduledId);
    this.props.hideModal();
  }

  // onConfirmDetailModal() {
  //   this.props.hideModal();
  // }

  showTransfers(event) {
    this.setState({
      showPendingTransfers: (event.target.value === 'pending'),
      showCompletedTransfers: (event.target.value === 'completed'),
    });
  }

  render() {
    return (
      <div>
        <section className="page">
          <Header
            title="Transfer History"
          />
          <PeriodForm
            {...this.props}
          />
          <TransferHistory
            {...this.props}
            transfers={this.props.scheduledTransfers}
            title={'Scheduled Transfers'}
            onShowModal={this.onShowModal}
            isScheduledTransfer
          />
          <TransferHistory
            {...this.props}
            transfers={this.props.completedTransfers}
            title={'Completed Transfers'}
            onShowModal={this.onShowDetailModal}
          />
          <ModalRoot
            modalType={this.props.modal.modalType}
            modalProps={this.props.modal.modalProps}
          />
        </section>
      </div>
    );
  }
}

RequestHistoryPage.propTypes = {
  showFullHistory: PropTypes.bool.isRequired,
  fetchRequestsHistory: PropTypes.func.isRequired,
  completedTransfers: PropTypes.arrayOf(PropTypes.shape()),
  pendingTransfers: PropTypes.arrayOf(PropTypes.shape()),
  scheduledTransfers: PropTypes.arrayOf(PropTypes.shape()),
  modal: PropTypes.shape({
    modalType: PropTypes.string,
    modalProps: PropTypes.shape(),
  }).isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  authToken: PropTypes.string,
};

const initialValues = {
  transferPeriod: '30',
};

export default connect(
  (state) => {
    const form = state.form.transfersPeriodForm || {};
    form.values = Object.assign({ }, initialValues, form.values);

    const modal = state.modal;
    const brokerageAccounts = state.brokerageAccounts.data;

    const today = moment();
    const newDate = today.subtract(state.period.data || 30, 'days');
    const completedTransfers = state.requestHistory.filter(request => !request.stateName.includes(PENDING_ACCOUNT_STATE)
    && !request.transferType.includes(BOUNCED_RETURNED_TYPE) && request.requestReceivedDate >= newDate);

    return {
      formValues: form,
      brokerageAccounts,
      pendingTransfers: state.requestHistory.filter(request => SUSPENDED_STATUS.includes(request.transferStatusType)),
      completedTransfers,
      scheduledTransfers: state.scheduledTransfers,
      showFullHistory: state.clientConfig.showFullHistory,
      modal,
    };
  },
  (dispatch) => {
    return {
      onInitializeForm: (props, form, period) => {
        props.onChangePeriod(period, form);
      },
      fetchRequestsHistory: () => {
        const actions = [
          // dispatch(brokerageAccountActions.requestBrokerageAccounts()),
          dispatch(requestHistoryActions.fetchHistory()),
          // dispatch(scheduledTransferActions.fetchScheduledTransfers()),
        ];

        Promise.all(actions);
      },
      showModal: (modal) => {
        dispatch(modalActions.showModal(modal));
      },
      hideModal: () => {
        dispatch(modalActions.hideModal());
      },
      onEditScheduledTransfer: (route) => {
        // dispatch(scheduledTransferActions.editScheduledTransfer(DynamicRoutes.editScheduled(scheduledId)));
        // dispatch(scheduledTransferDetailsActions.fetchScheduledTransferDetails(scheduledId));

        // dispatch(scheduledTransferDetailsActions.fetchScheduledTransferDetails(scheduledId))
        //   .then(action => {
        //     if (action.isSuccess) {
        //       dispatch(push(route));
        //     }
        //   });

        dispatch(push(route));
      },
      onCancel(scheduledId) {
        dispatch(scheduledTransferActions.cancelScheduledTransfer(scheduledId))
          .then(action => {
            if (action.isSuccess) {
              dispatch(scheduledTransferActions.fetchScheduledTransfers());
            }
          });
      },
      onChangePeriod(period, form) {
        dispatch(change(form, 'transferHistoryPeriod', period));
        dispatch(periodActions.changePeriod(period || 30));
        // const actions = [
        //   dispatch(requestHistoryActions.fetchHistory(period)),
        //   dispatch(scheduledTransferActions.fetchScheduledTransfers(period)),
        //   dispatch(change(form, 'transferHistoryPeriod', period)),
        // ];

        // Promise.all(actions);
      },
    };
  })(RequestHistoryPage);
