import React from 'react';
import PropTypes from 'prop-types';
import './Fields.css';

class RenderTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };

    this.onInvalid = this.onInvalid.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onInvalid(event) {
    event.preventDefault();
    if (!event.target.validity.valid) {
      this.setState({ error: this.props.validationMessage || event.target.validationMessage });
    }
  }
  onChange(event) {
    this.setState({ error: '' });
    if (this.props.input.onChange) this.props.input.onChange(event);
  }
  render() {
    const {
      input,
      value,
      id,
      label,
      required,
      disabled,
      type,
      min,
      max,
      maxLength,
      step,
      pattern,
      meta: { dirty, active, error },
    } = this.props;
    const floating = dirty ? 'is-dirty' : 'is-empty';
    const hasError = (error || this.state.error) ? 'has-error' : '';
    const isActive = active ? 'is-focused' : '';

    return (
      <div className={`field text ${floating} ${hasError} ${isActive}`}>
        <label htmlFor={id}>{label}</label>
        <input
          {...input}
          value={value || input.value}
          id={id}
          type={type}
          min={min}
          max={max}
          maxLength={maxLength}
          step={step}
          pattern={pattern}
          required={required}
          disabled={disabled}
          onInvalid={this.onInvalid}
          onChange={this.onChange}
          onKeyUp={this.onChange}
        />
        <span className="expanding-line" />
        <span className="validation">{this.state.error}</span>
      </div>
    );
  }
}

RenderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  value: PropTypes.string,
  id: PropTypes.string,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  maxLength: PropTypes.string,
  step: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  validationMessage: PropTypes.string,
};

export default RenderTextField;

export const normalizeAmount = (value) => {
  if (!value) return value;
  const cleanValue = parseFloat(value.replace(/\./g, '').replace(/,/g, ''));
  return (cleanValue / 100).toFixed(2);
};
