import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import config from './config';
import LoginRequiredPage from './components/LoginRequiredPage';

function requireAuthentication(Component) {
  const AuthenticatedComponent = props => {
    if (props.redirect) {
      window.location.href = props.clientCenterHost.replace(/"/g, "'");
      return true;
    }

    if (config().env === 'local' && !props.isAuthenticated) {
      return <LoginRequiredPage />;
    }
    
    // For client center, we don't need to check if the user is authenticated since we are gonna
    // wait for the client center to send us the access token
    return <Component {...props} />;
  };

  AuthenticatedComponent.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    redirect: PropTypes.bool.isRequired,
    clientCenterHost: PropTypes.string,
    clientId: PropTypes.string,
  };

  function mapStateToProps(state) {
    return {
      redirect: false, // never rediect
      clientCenterHost: state.configuration.clientCenterHost,
      isAuthenticated: state.authentication.isAuthenticated || false,
    };
  }

  return connect(mapStateToProps)(AuthenticatedComponent);
}

export default requireAuthentication;
