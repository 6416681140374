const parseError = (errorBody) => {
  const error = {
    Message: '',
    Errors: [],
  };
  try {
    return JSON.parse(errorBody) || error;
  } catch (e) { return error; }
};

const getError = (errorBody) => {
  const error = parseError(errorBody);
  return {
    error: true,
    message: error.Message,
    errors: error.Errors && error.Errors.map(e => e.ErrorMessage),
  };
};

export default function (state = { }, action) {
  if (action.isSuccess || action.type === '@@router/LOCATION_CHANGE') return { error: false };
  if (action.error && action.error.body) {
    return getError(action.error.body);
  }
  return action.isError ? { error: true } : state;
}
