import React from 'react';
import ACATSDepositForm from './ACATSDepositForm';

const IncomingACATSPage = () => {
  return (
    <div className="acats page">
      <ACATSDepositForm />
    </div>
  );
};

export default IncomingACATSPage;
