import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import './Forms/Fields.css';
import { StaticRoutes } from '../Routes';
import { changeAmount } from '../actions/amountActions';
import { changeCurrency } from '../actions/currencyActions';
import { changeFrequency } from '../actions/frequencyActions';
import { AOP_CLIENT_ID } from '../constants';

class DropdownRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { focus: '' };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onFocus() {
    this.setState({ focus: 'is-focused' });
  }

  onBlur() {
    this.setState({ focus: '' });
  }

  render() {
    return (
      <div className={`field ${this.props.id} dropdown is-dirty ${this.state.focus}`}>
        <label htmlFor={this.props.id}>{this.props.label}</label>
        <span htmlFor={this.props.id} className="caret" />
        <select
          id={this.props.id}
          defaultValue={this.props.defaultValue}
          onChange={this.props.pushTo}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        >
          {this.props.children}
        </select>
        <span className="expanding-line" />
      </div>
    );
  }
}

DropdownRouter.propTypes = {
  pushTo: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape(),
  ]).isRequired,
};

const ConnectedDropdownRouter = connect(
  () => ({}),
  (dispatch) => {
    return {
      pushTo: (event) => {
        dispatch(changeAmount(0));
        dispatch(changeCurrency('USD'));
        dispatch(push(event.target.value));
        dispatch(changeFrequency(null));
      },
    };
  }
)(DropdownRouter);

export default ConnectedDropdownRouter;

const TransferMethod = (props) => {
  const { isWireTransferEnabled, isCheckTransferEnabled, isAopClient } = props;
  
  if (isAopClient) {
    return null;
  }
  
  return (
    <ConnectedDropdownRouter
      {...props}
      id="transfer-method"
      label="Transfer Method"
    >
      <option value={StaticRoutes.TRANSFERS}>Smart Transfer</option>
      {isWireTransferEnabled && <option value={StaticRoutes.WIRE_TRANSFER_IN}>Wire Transfer</option>}
      {isCheckTransferEnabled && <option value={StaticRoutes.CHECK_TRANSFER_IN}>Check</option>}
    </ConnectedDropdownRouter>
  );
};

TransferMethod.propTypes = {
  isWireTransferEnabled: PropTypes.bool,
  isCheckTransferEnabled: PropTypes.bool,
  isAopClient: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAopClient: state.clientId === AOP_CLIENT_ID,
});

const ConnectedTransferMethod = connect(mapStateToProps)(TransferMethod);
export { ConnectedTransferMethod as TransferMethod };

export const ChecksDirection = (props) => {
  const { isCheckDepositTransferEnabled, isCheckWithdrawalTransferEnabled } = props;
  return (
    <ConnectedDropdownRouter
      {...props}
      id="direction"
      label="Direction"
    >
      {isCheckDepositTransferEnabled && <option value={StaticRoutes.CHECK_TRANSFER_IN}>Deposit</option>}
      {isCheckWithdrawalTransferEnabled && <option value={StaticRoutes.CHECK_TRANSFER_OUT}>Withdrawal</option>}
    </ConnectedDropdownRouter>
  );
};

ChecksDirection.propTypes = {
  isCheckDepositTransferEnabled: PropTypes.bool,
  isCheckWithdrawalTransferEnabled: PropTypes.bool,
};

export const WiresDirection = (props) => {
  return (
    <ConnectedDropdownRouter
      {...props}
      id="direction"
      label="Direction"
    >
      <option value={StaticRoutes.WIRE_TRANSFER_IN}>Deposit</option>
      <option value={StaticRoutes.WIRE_TRANSFER_OUT}>Withdrawal</option>
    </ConnectedDropdownRouter>
  );
};
