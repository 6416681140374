import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const WireFees = (props) => {
  const fees = props.transferFees;
  return (
    <dl>
      <dt>Cost:</dt>
      <div>
        {!props.transferFeesLoaded && <dd>Fees may be assessed.</dd>}
        {fees.wireDomestic && <dd>{fees.wireDomestic.displayAmount} Domestic</dd>}
        {fees.wireInternational && <dd>{fees.wireInternational.displayAmount} International</dd>}
      </div>
    </dl>
  );
};

WireFees.propTypes = {
  transferFees: PropTypes.shape().isRequired,
  transferFeesLoaded: PropTypes.bool.isRequired,
};

export default connect(
  (state) => {
    return {
      transferFees: state.transferFees.data,
      transferFeesLoaded: state.transferFees.everLoaded,
    };
  },
)(WireFees);
