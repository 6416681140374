import React from 'react';
import { connect } from 'react-redux';
import { StaticRoutes } from '../../Routes';
import * as bankAccountActions from '../../actions/bankAccountActions';
import Header from '../Header';
import './ManageAccounts.css';
import ManageAccountsForm from './ManageAccountsForm';

const ManageAccountsPage = (props) => {
  return (
    <div className="page">
      <section>
        <Header title="External Accounts" />
        <ManageAccountsForm
          {...props}
        />
      </section>
    </div>
  );
};


// The connect funtion connect Redut to reac
// it gets twp paremeters coonect( mapStasteToProps, mapDispatchToProps)
// it basically pass the needed satate and the actions needed for the component

export default connect(
  (state) => {
    return {
      linkedAccounts: state.bankingAccounts.data,
      showFullAccountView: state.clientConfig.showFullAccountView,
      clientId: state.clientId,
    };
  },
  (dispatch) => {
    const requestBankingAccounts = () => dispatch(bankAccountActions.requestBankingAccounts());
    return {
      requestBankingAccounts,

      onDeleteAccount: (account) => {
        return dispatch(bankAccountActions.deleteLinkedAccount(account.bankId))
          .then(() => requestBankingAccounts());
      },

      onCreateAccountNickName: (account) => {
        return dispatch(bankAccountActions.updateLinkedBankAccountNickName(account.bankId, account.BankAccountNickName))
          .then(() => requestBankingAccounts());
      },

      onAddNewAccount: () => {
        dispatch(bankAccountActions.startLinkBankAccountWorkflow(StaticRoutes.MANAGE_BANK_ACCOUNTS));
      },
    };
  }
)(ManageAccountsPage);
