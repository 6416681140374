import { StaticRoutes, AbsoluteRoutes } from './Routes';

const clientConfigs = {
  tango: {
    authResponseType: 'response_type=token+userid',
    cssClassOverride: 'tango-default',
    isYodleeEnabled: true,
    manualLinkingUrl: AbsoluteRoutes.ONLINE_TRANSFER_ACH_AUTH,
    showFullAccountView: false,
    showFullHistory: false,
    showNavigation: true,
    showPrintButton: false,
    checkWithdrawalMode: 'static',
    isWireTransferEnabled: true,
    isCheckTransferEnabled: true,
    isCheckDepositTransferEnabled: true,
    isCheckWithdrawalTransferEnabled: false,
    isFastlinkEnabled: true,
    fastlinkAllowedAttemps: 5,
    isPlaidEnabled: true,
  },
  demo: {
    manualLinkingUrl: StaticRoutes.LINK_BANK_ACCOUNT_MANUAL,
    checkWithdrawalMode: 'tango',
  },
  client_center: {
    authResponseType: 'response_type=cookie',
    cssClassOverride: 'client-center',
    showFullAccountView: true,
    showFullHistory: true,
    showNavigation: false,
    showPrintButton: true,
    checkWithdrawalMode: 'legacy',
    isCheckTransferEnabled: true,
    isCheckDepositTransferEnabled: true,
    isCheckWithdrawalTransferEnabled: false,
  },
  aop: {
    authResponseType: 'response_type=token+userid',
    cssClassOverride: 'aop',
    isYodleeEnabled: true,
    manualLinkingUrl: AbsoluteRoutes.ONLINE_TRANSFER_ACH_AUTH,
    showFullAccountView: true,
    showFullHistory: false,
    showNavigation: false,
    showPrintButton: false,
    checkWithdrawalMode: 'legacy',
    isWireTransferEnabled: true,
    isCheckTransferEnabled: true,
    isCheckDepositTransferEnabled: true,
    isCheckWithdrawalTransferEnabled: false,
  },
  ios: {},
  android: {},
};

export default clientId => {
  return Object.assign({}, clientConfigs.tango, clientConfigs[clientId]);
};
