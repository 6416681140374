import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import * as modalActions from '../../actions/modalActions';
import './Modals.css';

function parseMessageToExtractHtmlLink(propsMessage) {
  const message = DOMPurify.sanitize(propsMessage);
  const hasLink = message.indexOf('|') > 0;

  if (hasLink) {
    const index1 = message.indexOf('|');
    const index2 = message.lastIndexOf('|') + 1;
    const preMessage = message.substring(0, index1);
    const postMessage = message.substring(index2);
    const midMessage = message.substring(index1 + 1, index2 + 1);
    const link = midMessage.substring(0, midMessage.indexOf('|'));
    const linkText = midMessage.substring(midMessage.indexOf('|') + 1, midMessage.lastIndexOf('|'));
    const aOpenening = '<a href="';
    const aClosing = '" >';
    const aFinal = '</a>';
    const linkMessage = aOpenening + link + aClosing + linkText + aFinal;
    return preMessage + linkMessage + postMessage;
  }
  return message;
}

const AlertModal = (props) => {
  const contentMessage = parseMessageToExtractHtmlLink(props.message);
  return (
    <div className="modal-content">
      <div className="modal-header">
        <span id="modalTitle">{props.title}</span>
      </div>
      <div className="modal-body">
        <p id="modalMessage">{Parse(contentMessage)}</p>
      </div>
      <div className="modal-footer">
        {props.isConfirmModal &&
        <button type="button" id="cancelModal" className="btn-cancel" onClick={props.onCancelModal}>
          {props.cancelText || 'No'}
        </button>}
        {props.isLinkModal &&
        <button type="button" id="linkModal" className="btn-transfer" onClick={props.onLinkModal}>
          {props.linkText || 'NEW TRANSFER'}
        </button>}
        <button
          type="button"
          className={`${!props.isConfirmModal && !props.isLinkModal ? 'success btn-centered' : 'btn-confirm'}`}
          id="confirmModal"
          onClick={props.onConfirmModal}
        >
          {props.confirmText || 'Yes'}
        </button>
      </div>
    </div>
  );
};

AlertModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  linkText: PropTypes.string,
  isConfirmModal: PropTypes.bool,
  isLinkModal: PropTypes.bool,
  onCancelModal: PropTypes.func,
  onConfirmModal: PropTypes.func,
  onLinkModal: PropTypes.func,
};

export default connect(null,
  dispatch => {
    return {
      onCancelModal: () => {
        dispatch(modalActions.hideModal());
      },
      onLinkModal: () => {
        window.location.href = '/transfers';
      },
    };
  }
)(AlertModal);
