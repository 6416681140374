import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { StaticRoutes } from '../../Routes';
import './NavigationMenu.css';
import { AOP_CLIENT_ID } from '../../constants';

const NavigationMenu = props => {
  const { isAopClient, hasLinkedBankAccounts } = props;
  return (

    <div className="navigation-menu">
      {!(isAopClient && !hasLinkedBankAccounts) &&
        <NavLink
          to={StaticRoutes.TRANSFERS}
        >
          New Transfer
        </NavLink>
      }
      <NavLink
        to={StaticRoutes.MANAGE_BANK_ACCOUNTS}
      >
        Accounts
      </NavLink>
      {!(isAopClient && !hasLinkedBankAccounts) &&
        <NavLink
          to={StaticRoutes.HISTORY}
        >
          History
        </NavLink>
      }
    </div>
  );
};

NavigationMenu.propTypes = {
  isAopClient: PropTypes.bool,
  hasLinkedBankAccounts: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isAopClient: state.clientId === AOP_CLIENT_ID,
    hasLinkedBankAccounts: state.bankingAccounts.everLoaded && state.bankingAccounts.data.length > 0,
  };
}

export default connect(mapStateToProps)(NavigationMenu);
