import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import RenderTextField from '../Forms/RenderTextField';
import RippleButton from '../Forms/RippleButton';

const IncompleteInfoForm = (props) => {
  return (
    <div>
      <p>Please enter your routing number below to complete linking your account.</p>
      <form onSubmit={props.handleSubmit}>
        <Field
          component={RenderTextField}
          type="text"
          id="routingNumber"
          name="routingNumber"
          label="Routing Number"
          required
        />
        <div className="actions">
          <RippleButton
            id="next_btn"
            className="success"
          >
            SUBMIT
          </RippleButton>
        </div>
      </form>
    </div>
  );
};

IncompleteInfoForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export { IncompleteInfoForm };
export default reduxForm({
  form: 'IncompleteInfoForm',
})(IncompleteInfoForm);

