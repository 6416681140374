import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Header from '../Header';
import RenderTextField from '../Forms/RenderTextField';
import RenderDropdownField from '../Forms/RenderDropdownField';
import CheckFees from '../Fees/CheckFees';
import IRAFees from '../Fees/IRAFees';
import { isInvalidATW } from '../ATW/validations';
import IRADistributionForm from '../IRA/IRADistributionForm';
import { ChecksDirection, TransferMethod } from '../DropdownRouter';
import { StaticRoutes } from '../../Routes';
import RippleButton from '../Forms/RippleButton';
import IRACustodianAccount from '../IRA/IRACustodianAccount';
import CheckTransferWithdrawalLegacy from './CheckTransferWithdrawalLegacy';
import CheckTransferWithdrawalStatic from './CheckTransferWithdrawalStatic';
import DonutChart from '../ATW/DonutChart';

const AmountErrorMessage = 'Amount must be greater than 0 and less than Available To Withdraw';

class CheckTransferWithdrawalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.formatAmount = this.formatAmount.bind(this);
    this.normalize = this.normalize.bind(this);
    this.selectAccount = this.selectAccount.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
  }

  componentDidMount() {
    this.props.initializeForm(this.props.match.params.requestId);
  }

  componentWillReceiveProps() {
    this.setState({
      error: '',
    }, () => {
      if (this.props.formValues.values.fundsOriginAccount &&
          this.props.formValues.values.fundsOriginAccount.isBrokerageAccount) {
        const atwAmount = this.props.formValues.values.fundsOriginAccount
          .availableToWithdraw.find((x) => x.Currency === this.props.formValues.values.transferCurrency);

        if (!atwAmount) {
          this.setState({
            error: 'It was not possible to process the selected currency. Please try another one',
          });
        } else if (this.props.amount.current < 0 || this.props.amount.current > atwAmount) {
          this.setState({
            error: AmountErrorMessage,
          });
        } else {
          this.setState({
            error: '',
          });
        }
      }
    });
  }

  componentDidUpdate() {
    if (Object.keys(this.props.cloneRequest).length > 0) {
      this.props.selectPreviousRequest(this.props.cloneRequest, this.props.form, this.props.brokerageAccounts);
    }
  }

  /* eslint-disable */
  formatAmount(value) {
    if (!value) return value;
    const cleanValue = parseFloat(value.replace(/\./g, '').replace(/,/g, ''));
    const newValue = (cleanValue / 100);

    const result = Number(newValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    
    return result;
  }

  handleChange(event) {
    if (!this.props.formValues.values.fundsOriginAccount) {
      return;
    }
    this.props.changeAmount(event.target.value);
  }

  selectAccount(event) {
    this.props.changeCurrency('USD');
    this.props.selectAccount(event, this.props.form, this.props.brokerageAccounts);
  }

  handleCurrencyChange(event) {
    this.props.changeCurrency(event.target.value);
  }

  /* eslint-disable */
  normalize(value) {
    if (!value) return value;

    const cleanValue = parseFloat(value.toString().replace(/\./g, '').replace(/,/g, ''));
    return (cleanValue / 100).toFixed(2);
  }

  // iOS Safari pre 10.3.3 won't call checkValidity before a form submission, so we have to do this
  // ourselves because we depend on native html validation to prevent form submission errors
  handleSubmit(event) {
    if (!event.target.checkValidity() || isInvalidATW(this.props.formValues.values.fundsOriginAccount, 'USD')) event.preventDefault();
    else {
      this.props.handleSubmit(event);
    }
  }

  render() {
    const fees = this.props.transferFees;
    const { isWireTransferEnabled, isCheckTransferEnabled } = this.props;
    const deliveryFeeMap = {
      Regular: fees.checkStandard && fees.checkStandard.displayAmount,
      Overnight: fees.checkOvernight && fees.checkOvernight.displayAmount,
    };
    const { fundsOriginAccount, transferCurrency } = this.props.formValues.values;
    const deliveryMethod = this.props.deliveryMethods
      .find(m => m.Key.toString() === this.props.formValues.values.deliveryMethod);
    const showRegular = deliveryMethod && deliveryMethod.Value === 'Regular';
    const { showWithdrawAll, transferAccountBalance, allCashAmount, amount } = this.props;
    return (
      <form id="check-withdrawal-form" onSubmit={this.handleSubmit}>
        <Header
          title="Check"
          description="Withdraw cash from your TradeStation brokerage by receiving a check for deposit"
        />
        <section>
          <TransferMethod 
            isWireTransferEnabled={isWireTransferEnabled} 
            isCheckTransferEnabled={isCheckTransferEnabled} 
            defaultValue={StaticRoutes.CHECK_TRANSFER_IN} 
          />
          <div className="fee-schedule">
            <div id="fees">
              <CheckFees showRegular={showRegular} />
              {fundsOriginAccount && fundsOriginAccount.isIRA &&
                <IRAFees fundsOriginAccount={fundsOriginAccount} />
              }
            </div>
            <span className="separator" />
            <dl>
              <dt>Availability:</dt>
              <dd>
                {showRegular
                  ? '5 - 7 Business Days'
                  : 'Next Business Day'}
              </dd>
            </dl>
          </div>
          <ChecksDirection defaultValue={StaticRoutes.CHECK_TRANSFER_OUT} />
          {this.props.checkWithdrawalMode === 'legacy' &&
            <CheckTransferWithdrawalLegacy {...this.props} />
          }
          {this.props.checkWithdrawalMode === 'static' &&
            <CheckTransferWithdrawalStatic {...this.props} />
          }
          {this.props.checkWithdrawalMode === 'tango' &&
          <Field
            component={RenderDropdownField}
            id="fundsOriginAccountKey"
            name="fundsOriginAccountKey"
            label="Account"
            onChange={this.selectAccount}
            required
            validationMessage="Please select an account from the list."
          >
            <option className="hidden" />
            {this.props.brokerageAccounts.map((account) =>
              <option
                title={account.accountName}
                key={account.key}
                value={account.key}
                id={account.key}
                data-brokeragetype={account.accountDetail}
              >
                {account.displayName}
              </option>
            )}
          </Field>
          }
          {fundsOriginAccount && fundsOriginAccount.isBrokerageAccount &&
            <div>
              <DonutChart
                selectedOriginAccount={fundsOriginAccount}
                amount={(transferAccountBalance ? allCashAmount : amount.current)}
                currency={transferCurrency}
              />
            </div>
          }
          {this.props.checkWithdrawalMode === 'tango'
           && fundsOriginAccount
           && ( 
           (fundsOriginAccount.isIRA && fundsOriginAccount.isFuturesAccount)
           || fundsOriginAccount.IsCustodian
           )
            ? <IRACustodianAccount />
            : this.props.checkWithdrawalMode === 'tango' &&
            <div>
              {fundsOriginAccount &&
                <IRADistributionForm {...this.props} account={fundsOriginAccount} />}
              <div className="amount">
                {this.props.transferAccountBalance &&
                  <div className="field text is-dirty">
                    <label htmlFor="transferBalance">Amount</label>
                    <input
                      type="text"
                      name="transferBalance"
                      id="transferBalance"
                      value="All Available Cash"
                      disabled
                    />
                    <span className="expanding-line" />
                    <span className="validation">{fundsOriginAccount.availableToWithdraw.find((x) => x.Currency === transferCurrency).Realtime <= 0 ? AmountErrorMessage : ''}</span>
                  </div>
                }
                {!this.props.transferAccountBalance && <Field
                  component={RenderTextField}
                  name="transferAmount"
                  type="text"
                  pattern="^-?[\d,.]+$"
                  step="any"
                  id="transferAmount"
                  label="Amount"
                  normalize={this.formatAmount}
                  validationMessage={AmountErrorMessage}
                  onChange={this.handleChange}
                  required
                />}
                <Field
                  component={RenderDropdownField}
                  id="transferCurrency"
                  name="transferCurrency"
                  key={`${transferCurrency}_Code`}
                  label="Currency"
                  required
                  disabled
                  defaultValue="USD"
                  onChange={this.handleCurrencyChange}
                >
                  <option />
                  {fundsOriginAccount ? fundsOriginAccount.supportedCurrencies.map(code =>
                    <option
                      value={code}
                      key={code}
                    >
                      {code}
                    </option>
                    ) :
                    <option value="USD">USD</option>
                  }
                </Field>
              </div>
              <div>
                <span className="amount-validation">{this.state.error}</span>
              </div>
              {showWithdrawAll &&
                <div>
                  <Field
                    component="input"
                    type="checkbox"
                    id="transferBalanceChosen"
                    name="transferBalanceChosen"
                  />
                  <label htmlFor="transferBalanceChosen">&nbsp;Withdraw All Available Cash</label>
                </div>
              }
              <Field
                component={RenderDropdownField}
                id="deliveryMethod"
                name="deliveryMethod"
                label="Delivery Method"
                required
              >
                <option />
                {this.props.deliveryMethods.map((method, key) =>
                  <option key={key} value={method.Key}>{method.Description} ({deliveryFeeMap[method.Value]})</option>)}
              </Field>
              <div className="actions">
                <RippleButton
                  disabled={this.state.error.length > 0}
                  type="submit"
                  id="next_btn"
                  className="primary"
                >
                  preview
                </RippleButton>
              </div>
            </div>
          }
          <h5>Important Notes</h5>
          <p>
            Requests placed before 12 p.m. ET are usually processed the same business day.
            All checks will be made payable to the account name and address on file. If you
            have a PO Box address on file, we will not be able to send an overnight delivery.
            Check requests are only accepted for amounts between $100 and $100,000.
          </p>
        </section>
      </form>
    );
  }
}

CheckTransferWithdrawalForm.propTypes = {
  checkWithdrawalMode: PropTypes.string.isRequired,
  brokerageAccounts: PropTypes.arrayOf(PropTypes.shape()),
  initializeForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      fundsOriginAccount: PropTypes.shape(),
      deliveryMethod: PropTypes.string,
      transferCurrency: PropTypes.string,
    }),
  }),
  cloneRequest: PropTypes.shape(),
  selectAccount: PropTypes.func.isRequired,
  selectPreviousRequest: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string,
    }),
  }),
  form: PropTypes.string,
  transferAccountBalance: PropTypes.bool,
  deliveryMethods: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
  transferFees: PropTypes.shape().isRequired,
  showWithdrawAll: PropTypes.bool,
  changeAmount: PropTypes.func.isRequired,
  changeCurrency: PropTypes.func.isRequired,
  amount: PropTypes.shape({
    current: PropTypes.number.isRequired,
  }).isRequired,
  allCashAmount: PropTypes.number.isRequired,
};

export default reduxForm({
  form: 'checkTransferWithdrawalForm',
  destroyOnUnmount: false,
})(CheckTransferWithdrawalForm);
