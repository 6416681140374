export const FETCH_LOOKUP_REQUEST = 'FETCH_LOOKUP_REQUEST';
export const FETCH_LOOKUP_SUCCESS = 'FETCH_LOOKUP_SUCCESS';
export const FETCH_LOOKUP_ERROR = 'FETCH_LOOKUP_ERROR';

export function fetchLookup() {
  return {
    types: [FETCH_LOOKUP_REQUEST, FETCH_LOOKUP_SUCCESS, FETCH_LOOKUP_ERROR],
    fetch: {
      url: '/Types',
    },
  };
}
