import config from '../config';

const logglyTag = config().logglyTag || 'fundings-ui';

const _LTracker = window._LTracker || []; // eslint-disable-line no-underscore-dangle
_LTracker.push({
  logglyKey: 'b8eba5f0-5dbc-4147-b087-94845a9df5b0',
  sendConsoleErrors: true,
  tag: logglyTag,
});
