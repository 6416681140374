import * as bankAccountActions from '../actions/bankAccountActions';
import * as brokerageAccountActions from '../actions/brokerageAccountActions';
import * as preloadActions from '../actions/preloadActions';
import * as requestHistoryActions from '../actions/requestHistoryActions';
import * as scheduledTransferActions from '../actions/scheduledTransferActions';
import * as transferFeeActions from '../actions/transferFeeActions';
import * as lookupActions from '../actions/lookupActions';
import * as bankingDayActions from '../actions/bankingDayActions';

export default function (state = { preloaded: false }, action) {
  switch (action.type) {
    case bankAccountActions.FETCH_BANKING_ACCOUNTS_REQUEST:
      return {
        ...state,
        message: 'Loading Bank Accounts',
        loading: action.loading,
      };
    case brokerageAccountActions.FETCH_BROKERAGE_ACCOUNTS_REQUEST:
      return {
        ...state,
        message: 'Loading TradeStation Accounts',
      };
    case requestHistoryActions.FETCH_HISTORY_REQUEST:
      return {
        ...state,
        message: 'Loading History',
      };
    case transferFeeActions.FETCH_FEES_REQUEST:
      return {
        ...state,
        message: 'Loading Fees',
      };
    case lookupActions.FETCH_LOOKUP_REQUEST:
      return {
        ...state,
        message: 'Loading Lookup Data',
      };
    case bankingDayActions.FETCH_BANKING_DAYS_REQUEST:
      return {
        ...state,
        message: 'Loading Banking Days',
      };
    case scheduledTransferActions.FETCH_SCHEDULED_TRANSFER_REQUEST:
      return {
        ...state,
        message: 'Loading Scheduled Transfers',
      };
    case preloadActions.PRELOAD_COMPLETE:
      return {
        ...state,
        message: 'Done',
        preloaded: true,
      };
    default:
      return state;
  }
}
