import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import RippleButton from '../Forms/RippleButton';
import Header from '../Header';
import IRADistributionConfirmation from '../IRA/IRADistributionConfirmation';

/* eslint-disable */
const normalize = (value) => {
    if (!value) return value;

    const cleanValue = parseFloat(value.toString().replace(/\./g, '').replace(/,/g, ''));
    return (cleanValue / 100).toFixed(2);
}

const convertAmountToNumber = (amount) => {
  return Number(normalize(amount)).toFixed(2);
}

const formatAmount = (formValues) => {
  formValues.transferAmount = convertAmountToNumber(formValues.transferAmount);
}

const CheckTransferWithdrawalConfirmation = (props) => {
  const { handleSubmit } = props;
  const { fundsOriginAccount, transferAmount, transferCurrency } = props.formValues.values;
  const deliveryMethod = props.deliveryMethods.find(m => m.Key.toString() === props.formValues.values.deliveryMethod);
  return (
    <form id="check-withdrawal-confirmation" onSubmit={handleSubmit} className="confirmation">
      <Header
        title="Verify Transfer Details"
      />
      <dl>
        <dt>Transfer Method:</dt>
        <dd>Check</dd>
      </dl>
      <dl>
        <dt>Account:</dt>
        <dd>{fundsOriginAccount.displayName}</dd>
      </dl>
      <dl>
        <dt>Amount:</dt>
        {props.transferAccountBalance ?
          <dd>All Available Cash</dd> :
          <dd>
            {transferAmount.toLocaleString(
              'en-US',
              { style: 'currency', currency: transferCurrency }
            ) } {transferCurrency}
          </dd>}
      </dl>
      <dl>
        <dt>Delivery</dt>
        <dd>{deliveryMethod && deliveryMethod.Description}</dd>
      </dl>
      <dl>
        <dt>Est. Arrival</dt>
        <dd>
          {deliveryMethod && deliveryMethod.Value === 'Regular'
            ? '5 - 7 Business Days'
            : 'Next Business Day'}
        </dd>
      </dl>
      {fundsOriginAccount.isIRA &&
        <IRADistributionConfirmation formValues={props.formValues} />}
      <div className="actions">
        <RippleButton
          type="button"
          id="edit_btn"
          onClick={() => props.confirmCanceled(props.form)}
          className="default"
        >
          EDIT
        </RippleButton>
        <RippleButton onClick={formatAmount(props.formValues.values)}>SUBMIT</RippleButton>
      </div>
    </form>
  );
};

CheckTransferWithdrawalConfirmation.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  confirmCanceled: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      fundsOriginAccount: PropTypes.shape(),
      fundsOriginAccountKey: PropTypes.string.isRequired,
      transferAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      transferCurrency: PropTypes.string.isRequired,
      deliveryMethod: PropTypes.string.isRequired,
    }),
  }),
  form: PropTypes.string,
  transferAccountBalance: PropTypes.bool,
  deliveryMethods: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
};

export default reduxForm({
  form: 'checkTransferWithdrawalForm',
  destroyOnUnmount: false,
})(CheckTransferWithdrawalConfirmation);
