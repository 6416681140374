import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import actionLogger from './logging/actionLogger';
import apiThunk from './api/apiThunk';
import { publishPageHeightMiddleware } from './publishPageHeight';

const applyStateToAction = store => next => action => {
  const result = typeof action === 'function' ? action(store.getState()) : action;
  return next(result);
};

const enhancer = (middleware = []) => {
  middleware.unshift(actionLogger);
  middleware.unshift(apiThunk);
  middleware.unshift(publishPageHeightMiddleware);
  middleware.unshift(applyStateToAction);

  return composeWithDevTools(applyMiddleware(...middleware));
};

export default function (initialState, middleware = []) {
  return createStore(
    rootReducer,
    initialState,
    enhancer(middleware, initialState)
  );
}
