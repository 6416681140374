import React from 'react';


export default function Disclaimer() {
  const baseUpload = 'https://uploads.tradestation.com/uploads/';
  const privacyLink = 'privacy.pdf?_ga=2.1685425.1277208431.1635274965-1153169783.1635274965';
  const privacyNoticeLink = baseUpload + privacyLink;
  return (
    <p>
      When you fund your account by using the Automated Clearing House (ACH) payment option,
      you will be redirected to your banking institution.
      You acknowledge that data will then be collected by Envestnet│
      Yodlee (“Yodlee”) and provided to Us in order to complete the ACH transaction.
      Yodlee acts on our behalf in this process, which means we may also share your data with Yodlee,
      as one of our service providers in accordance with our <a href={privacyNoticeLink}>Privacy Notice</a>.
      For more information on how Yodlee collects, uses, stores, and handles your data,
      please see Yodlee’s <a href="https://www.yodlee.com/company/clients-consumers">
        Commitment to its Clients and their Users.</a>
    </p>
  );
}
