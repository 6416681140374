import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const CheckFees = (props) => {
  const fees = props.transferFees;
  const showRegular = props.showRegular;
  return (
    <dl className="check-fees">
      <dt>Cost:</dt>
      {!props.transferFeesLoaded && <dd>Fees may be assessed.</dd>}
      {fees.checkStandard && showRegular && <dd>{fees.checkStandard.displayAmount}</dd>}
      {fees.checkOvernight && !showRegular && <dd>{fees.checkOvernight.displayAmount}</dd>}
    </dl>
  );
};

CheckFees.propTypes = {
  transferFees: PropTypes.shape().isRequired,
  transferFeesLoaded: PropTypes.bool.isRequired,
  showRegular: PropTypes.bool,
};

export default connect(
  (state) => {
    return {
      transferFees: state.transferFees.data,
      transferFeesLoaded: state.transferFees.everLoaded,
    };
  },
)(CheckFees);
