import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Header from '../Header';
import { StaticRoutes } from '../../Routes';
import IncompleteInfoForm from './IncompleteInfoForm';
import * as bankAccountActions from '../../actions/bankAccountActions';

const IncompleteInfoPage = (props) => {
  return (
    <section className="page">
      <Header
        title="Add External Account"
      />
      <IncompleteInfoForm
        {...props}
        onSubmit={props.addLinkedBankAccount}
        initialValues={{ accountId: props.match.params.accountId }}
      />
    </section>
  );
};

IncompleteInfoPage.propTypes = {
  addLinkedBankAccount: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.string,
    }),
  }),
};

export default connect(
  (state) => {
    return {
      fields: state.form.IncompleteInfoForm,
    };
  },
  (dispatch) => {
    return {
      addLinkedBankAccount: (values) => {
        const data = {
          yodleeAccountId: values.accountId,
          routingNumber: values.routingNumber,
        };
        dispatch(bankAccountActions.submitBankAccountAutomatic(data))
          .then(action => {
            if (action.isSuccess) {
              dispatch(bankAccountActions.requestBankingAccounts())
                .then(() => dispatch(push(StaticRoutes.LINK_BANK_ACCOUNT_THANK_YOU)));
            }
          });
      },
    };
  }
)(IncompleteInfoPage);
