const fetch = (authToken, baseUrl, secured) => (requestUrl, requestOptions) => {
  const url = baseUrl + requestUrl;
  const options = requestOptions || { };
  options.credentials = 'same-origin';
  options.method = options.method || 'get';
  options.headers = options.headers || { };
  options.headers['Content-Type'] = options.headers['Content-Type'] || 'application/json';
  if (options.headers['Content-Type'] === 'multipart/form-data') { delete options.headers['Content-Type']; }
  options.headers.Accept = options.headers.Accept || 'application/json';

  if (secured) {
    options.headers.Authorization = `Bearer ${authToken}`;
    options.headers['X-Tango-Auth'] = authToken;
  }

  return window.fetch(url, options);
};

const api = (options, config, authToken) => {
  const urlLookup = {
    carma: config.carmaUrl,
    fastlink: config.fastlinkUrl,
    aop: config.aopUrl,
    aws: config.awsUrl,
    yodleeFastlinkUrl: config.yodleeFastlinkUrl,
    plaidUrl: config.plaidUrl,
    plaidEnabled: config.plaidEnabled,
  };

  const url = urlLookup[options.api];
  return fetch(authToken, url, options.secured);
};

export default api;
