/* eslint-disable */
import * as actionTypes from '../actions/countriesActions';

const UnitedStates = { countryCode: 'US', country: 'United States' };

const sortAlpha = (a, b) => {
  if (a.country < b.country) return -1;
  if (a.country > b.country) return 1;
  return 0;
};

export default function (state = { data: [UnitedStates] }, action) {
  switch (action.type) {
    case actionTypes.FETCH_COUNTRIES_SUCCESS: {
      const countries = action.payload.map((node) => ({ countryCode: node.countryCode, country: node.country }));
      // work around to include CANADA in countries list.
      // Exceptions.forEach(x => {
      const exists = countries.filter(ct => ct.countryCode === 'CA');
      if (exists.length <= 0) {
        countries.unshift({ countryCode: 'CA', country: 'Canada' });
      }
      // });
      // end work around 
      const result = countries.filter(x => x.countryCode !== UnitedStates.countryCode).sort(sortAlpha);
      result.unshift(UnitedStates);
      return { data: result, everLoaded: true };
    }
    default:
      return state;
  }
}
