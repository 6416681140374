export const FETCH_FEES_REQUEST = 'FETCH_FEES_REQUEST';
export const FETCH_FEES_SUCCESS = 'FETCH_FEES_SUCCESS';
export const FETCH_FEES_ERROR = 'FETCH_FEES_ERROR';

export function fetchFees() {
  return {
    types: [FETCH_FEES_REQUEST, FETCH_FEES_SUCCESS, FETCH_FEES_ERROR],
    fetch: {
      url: '/Fees',
    },
  };
}
