import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import RippleButton from '../Forms/RippleButton';
// import 'react-bootstrap/Button';

export default class TransfersRenderer extends Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDetails = this.onDetails.bind(this);
  }

  onEdit() {
    const parentComponent = this.props.context.componentParent;
    const data = this.props.node.data;
    parentComponent.onEdit(data);
  }

  onCancel() {
    const parentComponent = this.props.context.componentParent;
    const id = this.props.node.data.id;
    parentComponent.onCancel(id);
  }

  onDetails(declinedReason) {
    const parentComponent = this.props.context.componentParent;
    // const id = this.props.node.data.ScheduledEventId;
    parentComponent.onDetails(declinedReason);
  }

  render() {
    const {
      data,
    } = this.props;
    return (
      <div className="history-container">
        <div className="request-history">
          <ul key={data.id} className="transactions">
            <li>{data.date}</li>
            <li>{this.props.context.componentParent.props.isScheduledTransfer ?
              data.frequency : data.status}</li>
            <li className="currency-amount">{data.amount}</li>
            <li className="small">{data.account}</li>
            <li className="small">{data.type}</li>
            <li className="request-actions">
              {data.declinedReason && <span className="scheduled-actions">
                <button
                  style={{ height: 30, lineHeight: 0.5 }}
                  onClick={() => this.onDetails(data.declinedReason)}
                  className="primary"
                >
                  DETAILS
                </button>
              </span>
              }
              {data.suspenseReason && <span className="scheduled-actions">
                <button
                  style={{ height: 30, lineHeight: 0.5 }}
                  onClick={() => this.onDetails(data.suspenseReason)}
                  className="primary"
                >
                  DETAILS
                </button>
              </span>
              }
              {this.props.context.componentParent.props.isScheduledTransfer && <span className="scheduled-actions">
                <button
                  style={{ height: 30, lineHeight: 0.5 }}
                  onClick={this.onEdit}
                  className="primary"
                >
                  {<i className="fa fa-pencil" />}
                </button>
              </span>}
              {this.props.context.componentParent.props.isScheduledTransfer && <span className="scheduled-actions">
                <button
                  style={{ height: 30, lineHeight: 0.5 }}
                  onClick={this.onCancel}
                  className="danger"
                >
                  <i className="fa fa-trash" />
                </button>
              </span>}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

TransfersRenderer.propTypes = {
  node: PropTypes.shape().isRequired,
  context: PropTypes.shape({
    componentParent: PropTypes.shape({
      onEdit: PropTypes.func.isRequired,
      props: PropTypes.shape({
        isScheduledTransfer: PropTypes.bool,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  data: PropTypes.shape(),
};
