/* eslint no-param-reassign: ["error", { "props": false }] */
export const SUBMIT_CHECK_WITHDRAWAL_REQUEST = 'SUBMIT_CHECK_WITHDRAWAL_REQUEST';
export const SUBMIT_CHECK_WITHDRAWAL_SUCCESS = 'SUBMIT_CHECK_WITHDRAWAL_SUCCESS';
export const SUBMIT_CHECK_WITHDRAWAL_ERROR = 'SUBMIT_CHECK_WITHDRAWAL_ERROR';
export const FETCH_CHECK_WITHDRAWAL_REQUEST = 'FETCH_CHECK_WITHDRAWAL_REQUEST';
export const FETCH_CHECK_WITHDRAWAL_SUCCESS = 'FETCH_CHECK_WITHDRAWAL_SUCCESS';
export const FETCH_CHECK_WITHDRAWAL_ERROR = 'FETCH_CHECK_WITHDRAWAL_ERROR';

export function submitCheckWithdrawalTransfer(data) {
  data.clientId = sessionStorage.clientId;
  return {
    types: [SUBMIT_CHECK_WITHDRAWAL_REQUEST, SUBMIT_CHECK_WITHDRAWAL_SUCCESS, SUBMIT_CHECK_WITHDRAWAL_ERROR],
    fetch: {
      url: `/Accounts/${data.accountNumber}/Checks`,
      options: { method: 'post', body: JSON.stringify(data) },
    },
  };
}

export function fetchCheckTransfer(requestId) {
  return {
    types: [FETCH_CHECK_WITHDRAWAL_REQUEST, FETCH_CHECK_WITHDRAWAL_SUCCESS, FETCH_CHECK_WITHDRAWAL_ERROR],
    fetch: { url: `/Checks/${requestId}` },
  };
}
