import moment from 'moment-timezone';
import * as scheduledTransferActions from '../actions/scheduledTransferActions';

const getRequest = (transfer) => {
  const obj = {
    id: transfer.ScheduledEventID,
    account: transfer.AccountType && transfer.AccountType !== '' ?
      `${transfer.AccountType} -${transfer.AccountNumber.slice(-4)}` :
      transfer.AccountNumber.slice(-4),
    date: transfer.NextValueDate === '' ?
      transfer.NextValueDate : moment(transfer.NextValueDate).format('MM/DD/YYYY'),
    requestReceivedDate: moment(transfer.RequestReceivedDate),
    type: transfer.Workflow,
    amount: transfer.Amount ? transfer.Amount.toLocaleString('en-US',
      { style: 'currency', currency: transfer.Currency }) : '',
    currency: transfer.Currency,
    frequency: transfer.Frequency,
    details: transfer.ScheduledTransferDetails,
  };
  if (transfer.Currency === 'CAD' && transfer.Amount) {
    obj.amount = `CAD$${Number(transfer.Amount).toFixed(2)}`;
  }
  return obj;
};

export default function (state = [], action) {
  switch (action.type) {
    case scheduledTransferActions.FETCH_SCHEDULED_TRANSFER_SUCCESS: {
      if (!action.payload.Items) return state;
      const scheduledTransfers = action.payload.Items.map(request => getRequest(request));
      return scheduledTransfers;
    }
    default:
      return state;
  }
}
