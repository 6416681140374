import mockData from './mockData';
import lookupData from './lookupData';

const carma = {
  '/Users/omgjsam/Accounts': {
    get: () => ({ status: 200, json: () => ({ Accounts: mockData.brokerageAccounts }) }),
  },
  '/AchTransfers': {
    post: input => {
      return { status: 201, json: () => input };
    },
  },
  '/AchTransfers/:clientReferenceId': {
    get: (_, __, url) => ({ status: 200, json: () => mockData.achTransfer(url.split('/').pop()) }),
  },
  '/Users/omgjsam/BankAccounts/111/Documents': {
    post: () => {
      return { status: 201, json: () => {} };
    },
  },
  '/Users/omgjsam/BankAccounts': {
    get: () => ({ status: 200, json: () => ({ BankAccounts: mockData.bankAccounts }) }),
    post: () => {
      return { status: 201, json: () => mockData.addBankResponse };
    },
  },
  '/Users/omgjsam/BankAccounts/123': {
    delete: input => {
      mockData.bankAccounts = mockData.bankAccounts.filter(x => x.BankId !== 123);
      return { status: 200, json: () => input };
    },
  },
  '/Users/omgjsam/BankAccounts/124': {
    delete: input => {
      mockData.bankAccounts = mockData.bankAccounts.filter(x => x.BankId !== 124);
      return { status: 200, json: () => input };
    },
  },
  '/Users/omgjsam/BankAccounts/125': {
    delete: input => {
      mockData.bankAccounts = mockData.bankAccounts.filter(x => x.BankId !== 125);
      return { status: 200, json: () => input };
    },
  },
  '/Users/omgjsam/Transactions': {
    get: () => ({ status: 200, json: () => mockData.cashierRequests }),
  },
  '/ScheduledEvents/Users': {
    get: () => {
      return { status: 200, json: () => mockData.getScheduledTransfers() };
    },
  },
  '/ScheduledEvents/GetScheduledEventDetails/:scheduledId': {
    get: (_, __, url) => {
      return ({ status: 200, json: () => mockData.getScheduledTransferDetails(url.split('/').pop()) });
    },
  },
  '/ScheduledEvents/Cancel/:scheduledId': {
    delete: (_, __, url) => {
      return { status: 200, json: () => mockData.cancelScheduledTransfer(url.split('/').pop()) };
    },
  },
  '/ScheduledEvents/UpdateAchScheduledEvent': {
    post: input => {
      return { status: 201, json: () => input };
    },
  },
  '/ScheduledEvents/UpdateJournalScheduledEvent': {
    post: input => {
      return { status: 201, json: () => input };
    },
  },
  '/ScheduledEvents/UpdateWireScheduledEvent': {
    post: input => {
      return { status: 201, json: () => input };
    },
  },
  '/Users/omgjsam/Transactions/Wires': {
    get: () => ({ status: 200, json: () => mockData.wireTransfers }),
  },
  '/Checks': {
    post: input => {
      return { status: 201, json: () => input };
    },
  },
  '/Checks/:clientReferenceId': {
    get: (_, __, url) => ({ status: 200, json: () => mockData.checkTransfer(url.split('/').pop()) }),
  },
  '/Types': {
    get: () => ({
      status: 200,
      json: () => lookupData,
    }),
  },
  '/Journals': {
    post: input => {
      return { status: 201, json: () => input };
    },
  },
  '/Journals/:clientReferenceId': {
    get: (_, __, url) => ({ status: 200, json: () => mockData.internalTransfer(url.split('/').pop()) }),
  },
  '/Accounts/:accountId/Wires': {
    get: () => ({ status: 200, json: () => mockData.wireTransfers }),
    post: input => {
      return { status: 201, json: () => input };
    },
  },
  '/Accounts/:accountId/Wires/:clientReferenceId': {
    get: (_, __, url) => {
      return ({ status: 200, json: () => mockData.wireTransfer(url.split('/').pop()) });
    },
  },
  '/Fees': {
    get: () => ({ status: 200, json: () => mockData.fees }),
  },
  '/Banking/ValidDates': {
    get: () => ({ status: 200, json: () => mockData.bankingDays }),
  },
};

const fastlink = {
  '/': {
    get: (input, config) => {
      return !window.fetch.polyfill ? window.fetch(`${config.fastlinkUrl}/?oauth_token=1234`) :
        {
          status: 200,
          json: () => {
            return {
              fastlinkUiUrl: 'https://fake.fastlink.com/funding/fastlink/ui',
            };
          },
        };
    },
    post: input => {
      return { status: 201, json: () => input };
    },
  },
};

const aop = {
  '/countries': {
    get: () => ({ status: 200,
      json: () => [
        { countryCode: 'FR', country: 'France' },
        { countryCode: 'DE', country: 'Germany' },
        { countryCode: 'JP', country: 'Japan' },
        { countryCode: 'MX', country: 'Mexico' },
        { countryCode: 'US', country: 'United States' },
      ],
    }),
  },
};

const mock = {
  carma,
  fastlink,
  aop,
};

const validate = (which, url, method) => {
  let problem;
  if (!mock[which]) {
    problem = `unknown api "${which}"`;
  } else if (!mock[which][url]) {
    problem = `unknown url "${url}"`;
  } else if (!mock[which][url][method]) {
    problem = `unknown request method "${method}"`;
  }

  const msg = `Mock API - Invalid Parameter: ${problem}. api: ${which}, url: ${url}, method: ${method}`;
  if (problem) throw new Error(msg);
};

const chooseUrl = requestUrl => {
  if (requestUrl.endsWith('/AchTransfers')) return '/AchTransfers';
  if (requestUrl.endsWith('/Journals')) return '/Journals';
  if (requestUrl.endsWith('/Checks')) return '/Checks';
  if (requestUrl.startsWith('/Enum')) return '/Enum';
  if (requestUrl.endsWith('/Wires?$count=true&$orderby=ClientReferenceId desc&$top=20')) {
    return '/Users/omgjsam/Transactions/Wires';
  }
  if (requestUrl.startsWith('/Users/omgjsam/Transactions')) return '/Users/omgjsam/Transactions';
  if (requestUrl.startsWith('/ScheduledEvents/Users')) return '/ScheduledEvents/Users';
  if (requestUrl.startsWith('/ScheduledEvents/GetScheduledEventDetails')) {
    return '/ScheduledEvents/GetScheduledEventDetails/:scheduledId';
  }
  if (requestUrl.startsWith('/ScheduledEvents/Cancel')) return '/ScheduledEvents/Cancel/:scheduledId';
  if (requestUrl.startsWith('/ScheduledEvents/UpdateAchScheduledEvent')) {
    return '/ScheduledEvents/UpdateAchScheduledEvent';
  }
  if (requestUrl.startsWith('/ScheduledEvents/UpdateJournalScheduledEvent')) {
    return '/ScheduledEvents/UpdateJournalScheduledEvent';
  }
  if (requestUrl.startsWith('/ScheduledEvents/UpdateWireScheduledEvent')) {
    return '/ScheduledEvents/UpdateWireScheduledEvent';
  }
  if (requestUrl.startsWith('/ACHRelationship') && requestUrl.endsWith('/documents')) {
    return '/ACHRelationship/:clientReferenceId/documents';
  }
  if (requestUrl.startsWith('/AchTransfers') && requestUrl !== ('/AchTransfers')) {
    return '/AchTransfers/:clientReferenceId';
  }
  if (requestUrl.startsWith('/Checks') && requestUrl !== ('/Checks')) {
    return '/Checks/:clientReferenceId';
  }
  if (requestUrl.endsWith('/Wires')) {
    return '/Accounts/:accountId/Wires';
  } else if (requestUrl.includes('/Wires')) {
    return '/Accounts/:accountId/Wires/:clientReferenceId';
  }
  if (requestUrl.startsWith('/Journals') && requestUrl !== ('/Journals')) {
    return '/Journals/:clientReferenceId';
  }
  if (requestUrl.startsWith('/countries')) {
    return '/countries';
  }

  return requestUrl;
};

const handle = (which, config) => (requestUrl, requestOptions) => {
  const options = requestOptions || { };
  options.method = (options.method || 'get').toLowerCase();
  options.headers = options.headers || { };
  options.headers['Content-Type'] = options.headers['Content-Type'] || 'application/json';

  const url = chooseUrl(requestUrl);

  validate(which, url, options.method);
  const request = mock[which][url][options.method];
  const isJsonContent = options.headers['Content-Type'] === 'application/json';
  const body = isJsonContent ? JSON.parse(options.body || '{ }') : options.body;
  const response = request(body, config, requestUrl);
  response.headers = { get: header => (header === 'content-type' ? 'application/json' : undefined) };

  return Promise.resolve(response);
};

export default { handle };
