import React from 'react';
import PropTypes from 'prop-types';
import RippleLink from '../Forms/RippleLink';

const CheckTransferWithdrawalLegacy = (props) => {
  const { clientCenterHost: host } = props.config;
  return (
    <div>
      <p>
            Please select your account type below.
      </p>
      <div className="actions">
        <RippleLink
          to={`${host}/support/myaccount/online_transfers/online_check.aspx?link=Link_FundTranDepWithSungard`}
          target="_blank"
          className="primary"
        >
              Equities
        </RippleLink>
        <RippleLink
          to={`${host}/support/myaccount/online_transfers/online_check.aspx?link=Link_FundTranDepWith`}
          target="_blank"
          className="primary"
        >
              Futures
        </RippleLink>
      </div>
    </div>
  );
};

CheckTransferWithdrawalLegacy.propTypes = {
  config: PropTypes.shape().isRequired,
};

export default CheckTransferWithdrawalLegacy;
