import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Header from '../Header';
import RippleButton from '../Forms/RippleButton';
import IRADistributionConfirmation from '../IRA/IRADistributionConfirmation';

/* eslint-disable */
const normalize = (value) => {
  if (!value || !isNaN(value)) return value;
    const cleanValue = parseFloat(value.replace(/\./g, '').replace(/,/g, ''));
    const result = (cleanValue / 100).toFixed(2);
    return result;
}

const convertAmountToNumber = (amount) => {
  return Number(normalize(amount)).toFixed(2);
}

const formatAmount = (formValues) => {
  formValues.transferAmount = convertAmountToNumber(formValues.transferAmount);
}

const getSelectedFrequencyTooltipHtml = (props) => {
  let selectedFrequencyTooltipHtml = '';
  const selectedFrequencyKey = props.formValues.values.frequency;
  if (selectedFrequencyKey) {
    const selectedFrequency = props.frequencyOptions.find((frequency) =>
      selectedFrequencyKey && frequency.Key.toString() === selectedFrequencyKey);
    selectedFrequencyTooltipHtml =
      <p><strong>{selectedFrequency.Value}: </strong>{selectedFrequency.Description}</p>;
  }
  return selectedFrequencyTooltipHtml;
}

const WireTransferWithdrawalConfirmation = (props) => {
  const values = props.formValues.values;
  const { handleSubmit, isOneTimeTransfer, scheduledDate } = props;
  const { transferAmount, transferCurrency, frequency } = props.formValues.values;

  const frequencyOption = props.frequencyOptions
    .find(m => m.Key.toString() === frequency.toString()).Value;

  return (
    <form id="wire-withdrawal-confirmation" className="confirmation" onSubmit={handleSubmit}>
      <Header
        title="Verify Transfer Details"
      />
      <section>
        <dl>
          <dt>Transfer Method:</dt>
          <dd>Wire Transfer</dd>
        </dl>
        <dl>
          <dt>From:</dt>
          <dd>{values.fundsOriginAccount.displayName}</dd>
        </dl>
        <dl>
          <dt>To:</dt>
          <dd>{values.destinationBankName} - {values.destinationBankCountry}
          </dd>
        </dl>

        <dl>
          <dt>ABA / Swift:</dt>
          <dd>{values.destinationSwiftNumber}</dd>
        </dl>
        <dl>
          <dt>Account Title:</dt>
          <dd>{values.destinationAccountTitle}</dd>
        </dl>
        <dl>
          <dt>Account / IBAN:</dt>
          <dd>{values.destinationAccountNumber}</dd>
        </dl>
        {values.furtherCreditAccountTitle &&
        <dl>
          <dt>Further Credit Account Title:</dt>
          <dd>{values.furtherCreditAccountTitle}</dd>
        </dl>
        }
        {values.furtherCreditAccountNumber &&
        <dl>
          <dt>Further Credit Account Number:</dt>
          <dd>{values.furtherCreditAccountNumber}</dd>
        </dl>
        }
        <div className={`${values.intermediaryNeeded ? '' : 'hidden'}`}>
          <dl>
            <dt>Intermediary:</dt>
            <dd>{values.intermediaryBankName} - {values.intermediaryBankCountry}
            </dd>
          </dl>

          <dl>
            <dt>ABA / Swift:</dt>
            <dd>{values.intermediaryBankSwiftNumber}</dd>
          </dl>
          <dl>
            <dt>Account / IBAN:</dt>
            <dd>{values.intermediaryBankAccountNumber}</dd>
          </dl>
        </div>
        {values.fundsOriginAccount.isIRA &&
          <IRADistributionConfirmation {...props} />}
        <dl>
          <dt>Amount:</dt>
          {props.transferAccountBalance ?
            <dd>All Available Cash</dd> :
            <dd>
              {(transferCurrency === 'CAD') ?
                transferAmount.toLocaleString('en-US') :
                transferAmount.toLocaleString('en-US', { style: 'currency', currency: transferCurrency })
              } {(transferCurrency === 'CAD') ? 'C$' : transferCurrency}
            </dd>}
        </dl>
        <dl>
          <dt>Fee:</dt>
          <dd>{(values.destinationBankCountry === 'US')
            ? props.fees.wireDomestic.longDisplayAmount
            : props.fees.wireInternational.longDisplayAmount}
          </dd>
        </dl>
        <dl>
          <dt>Frequency:</dt>
          <dd>{frequencyOption}</dd>
        </dl>
        {isOneTimeTransfer ?
          <dl>
            <dt>Schedule Date:</dt>
            <dd>{scheduledDate.toDate().toLocaleDateString()}</dd>
          </dl> :
          <dl>
            <div id="selectedFrequencyText">
              {getSelectedFrequencyTooltipHtml(props)}
            </div>
          </dl>
        }
        <div className="actions">
          <RippleButton
            type="button"
            id="cancel"
            className="default"
            onClick={() => props.confirmCanceled(props.form)}
          >
            EDIT
          </RippleButton>
          <RippleButton onClick={formatAmount(props.formValues.values)}>CONFIRM</RippleButton>
        </div>
        <div>
          <p>
            <strong>Note: </strong> Scheduled Transfers that occur on non-banking days will
            be processed on the next banking day, following the selected date.
          </p>
        </div>
      </section>
    </form >
  );
};

WireTransferWithdrawalConfirmation.propTypes = {
  formValues: PropTypes.shape({
    values: PropTypes.shape(),
  }).isRequired,
  scheduledDate: PropTypes.shape({
    date: PropTypes.func,
  }),
  handleSubmit: PropTypes.func.isRequired,
  confirmCanceled: PropTypes.func.isRequired,
  form: PropTypes.string,
  transferAccountBalance: PropTypes.bool,
  fees: PropTypes.shape({
    wireDomestic: PropTypes.shape({
      longDisplayAmount: PropTypes.string,
    }),
    wireInternational: PropTypes.shape({
      longDisplayAmount: PropTypes.string,
    }),
  }),
  frequencyOptions: PropTypes.arrayOf(PropTypes.shape()),
  isOneTimeTransfer: PropTypes.bool,
};

export default reduxForm({
  form: 'wireTransferWithdrawalForm',
  destroyOnUnmount: false,
})(WireTransferWithdrawalConfirmation);
