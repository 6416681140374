import moment from 'moment-timezone';
import * as bankingDayActionsTypes from '../actions/bankingDayActions';

export default function (state = { data: [], areLoaded: false }, action) {
  switch (action.type) {
    case bankingDayActionsTypes.FETCH_BANKING_DAYS_SUCCESS: {
      const bankingDays = action.payload;
      const dates = bankingDays.map(date => moment(date));
      return { data: dates, areLoaded: true };
    }
    default:
      return state;
  }
}
