import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import publishPageHeight from '../../publishPageHeight';
import RippleButton from '../Forms/RippleButton';
import { sendEvent } from '../../utils/eventUtils';

/* eslint-disable */
const normalize = (value) => {
  if (!value) return value;

  const cleanValue = parseFloat(value.toString().replace(/\./g, '').replace(/,/g, ''));
  return (cleanValue / 100).toFixed(2);
}

const convertAmountToNumber = (amount) => {
  return Number(normalize(amount)).toFixed(2);
}

const WireViaUK = ({ account, from, to }) => ({
  'Intermediary Bank': <dl>
    <dt>Intermediary Bank:</dt>
    <dd>{from.bank}</dd>
    <dt>Address:</dt>
    <dd>{from.address1}<br />
      {from.address2}<br />
      {from.address3}
    </dd>
    <dt>SWIFT BIC Routing:</dt>
    <dd>{from.swift}</dd>
  </dl>,
  'Beneficiary Account': <dl>
    <dt>Beneficiary Account:</dt>
    <dd>JPMorgan Chase Bank, N.A.</dd>
    <dt>Address:</dt>
    <dd>1 Chaseside,<br />
      Bournemouth,<br />
      Dorset, BH7 7DA, England, UK</dd>
    <dt>Phone:</dt>
    <dd>44-1202-342552</dd>
    <dt>SWIFT BIC Routing:</dt>
    <dd>CHASGB2L</dd>
    <dt>Account Number:</dt>
    <dd>{account}</dd>
  </dl>,
  'Final Beneficiary': <dl>
    <dt>Final Beneficiary Account:</dt>
    <dd>TradeStation Securities</dd>
    <dt>Address:</dt>
    <dd>8050 SW 10th Street,<br />
      Suite 2000<br />
      Plantation, FL 33324</dd>
    <dt>Account Title:</dt>
    <dd>TradeStation Securities</dd>
    <dt>Account Number:</dt>
    <dd>{to.account} (IBAN is preferred)</dd>
    <dt>IBAN:</dt>
    <dd>{to.IBAN}</dd>
  </dl>,
});

class WireTransferDepositFutures extends Component {

  componentDidMount() {
    sendEvent({
      version: '1.0',
      eventType: 'COMPONENT_MOUNTED',
      context: "WIRE_DEPOSIT_VIEW_INSTRUCTIONS_FUTURES",
    });
  }

  componentDidUpdate() {
    publishPageHeight();
  }

  render() {
    const { handleSubmit } = this.props;
    const values = this.props.formValues.values;
    const forFurtherCredit = <dl>
      <dt>Further Credit To:</dt>
      <dd>{values.fundsDestinationAccount.accountTitle}</dd>
      <dt>Account Number:</dt>
      <dd>{values.fundsDestinationAccount.accountNumber}</dd>
      <dt>By Order Of:</dt>
      <dd>&#60;Account Title at Sending Bank&#62;</dd>
      <dt />
      <dd className="description">
        The title of your TradeStation Securities account and the title&nbsp;
      of the account from which you are transferring funds must match.</dd>
    </dl>;
    const instructions = {
      USD: {
        'Beneficiary Bank': <dl>
          <dt>Beneficiary Bank:</dt>
          <dd>JPMorgan Chase</dd>
          <dt>Address:</dt>
          <dd>One Chase Manhattan Plaza 7th Floor<br />
            New York, NY 10005</dd>
          <dt>Phone:</dt>
          <dd>(877) 204-1123</dd>
          <dt>ABA Routing:</dt>
          <dd>021-000-021</dd>
          <dt>SWIFT BIC Routing:</dt>
          <dd>CHASUS33</dd>
        </dl>,
        'Beneficiary Account': <dl>
          <dt>Beneficiary Account:</dt>
          <dd>TradeStation Securities</dd>
          <dt>Address:</dt>
          <dd>8050 SW 10th Street,<br />
            Suite 2000<br />
            Plantation, FL 33324</dd>
          <dt>Account Title:</dt>
          <dd>TradeStation Securities</dd>
          <dt>Account Number:</dt>
          <dd>799824925</dd>
        </dl>,
      },
      EUR: {
        'Beneficiary Bank': <dl>
          <dt>Beneficiary Bank:</dt>
          <dd>JPMorgan Chase Bank N.A. London</dd>
          <dt>Address:</dt>
          <dd>1 Chaseside,<br />
            Bournemouth,<br />
            Dorset, BH7 7DA, England, UK</dd>
          <dt>Phone:</dt>
          <dd>44-1202-342552</dd>
          <dt>SWIFT BIC Routing:</dt>
          <dd>CHASGB2L</dd>
        </dl>,
        'Beneficiary Account': <dl>
          <dt>Beneficiary Account:</dt>
          <dd>TradeStation Securities</dd>
          <dt>Address:</dt>
          <dd>8050 SW 10th Street,<br />
            Suite 2000<br />
            Plantation, FL 33324</dd>
          <dt>Account Title:</dt>
          <dd>TradeStation Securities</dd>
          <dt>Account Number:</dt>
          <dd>0040813901 (IBAN is preferred)</dd>
          <dt>IBAN:</dt>
          <dd>GB36CHAS60924240813901</dd>
        </dl>,
      },
      CAD: WireViaUK({
        account: '095912194132',
        from: {
          bank: 'Royal Bank of Canada, Toronto',
          address1: '180 Wellington St West',
          address2: 'M5J 1J1, Canada',
          swift: 'ROYCCAT2',
        },
        to: {
          account: '40813902',
          IBAN: 'GB09CHAS60924240813902',
        },
      }),
      HKD: WireViaUK({
        account: '6743197680',
        from: {
          bank: 'JPMorgan Chase Bank, Hong Kong',
          address1: 'Chart house 8 Connaught',
          address2: 'Road Central',
          address3: 'Hong Kong',
          swift: 'CHASHKHH',
        },
        to: {
          account: '40813906',
          IBAN: 'GB95CHAS60924240813906',
        },
      }),
      GBP: {
        'Beneficiary Bank': <dl>
          <dt>Beneficiary Bank:</dt>
          <dd>JPMorgan Chase Bank N.A. London</dd>
          <dt>Address:</dt>
          <dd>1 Chaseside,<br />
            Bournemouth,<br />
            Dorset, BH7 7DA, England, UK</dd>
          <dt>Phone:</dt>
          <dd>44-1202-342552</dd>
          <dt>Sort Code:</dt>
          <dd>60-92-42</dd>
          <dt>SWIFT BIC Routing:</dt>
          <dd>CHASGB2L</dd>
        </dl>,
        'Beneficiary Account': <dl>
          <dt>Beneficiary Account:</dt>
          <dd>TradeStation Securities</dd>
          <dt>Address:</dt>
          <dd>8050 SW 10th Street,<br />
            Suite 2000<br />
            Plantation, FL 33324</dd>
          <dt>Account Title:</dt>
          <dd>TradeStation Securities</dd>
          <dt>Account Number:</dt>
          <dd>40813908 (IBAN is Preferred)</dd>
          <dt>IBAN:</dt>
          <dd>GB41CHAS60924240813908</dd>
        </dl>,
      },
      SGD: WireViaUK({
        account: '501474191001',
        from: {
          bank: 'OVERSEA-CHINESE BANKING CORPORATION LIMITED, SINGAPORE',
          address1: 'OCBC CENTRE FLOOR 10',
          address2: '63 CHULIA STREET',
          address3: '049514, SINGAPORE',
          swift: 'OCBCSGSG',
        },
        to: {
          account: '40813905',
          IBAN: 'GB25CHAS60924240813905',
        },
      }),
      CHF: WireViaUK({
        account: '02300000044129050000B',
        from: {
          bank: 'Union Bank of Switzerland, Zurich',
          address1: 'Zurich, Switzerland',
          swift: 'UBSWCHZH80A',
        },
        to: {
          account: '40813907',
          IBAN: 'GB68CHAS60924240813907',
        },
      }),
      JPY: WireViaUK({
        account: '0195006713',
        from: {
          bank: 'JPMorgan Chase Bank, Tokyo',
          address1: 'TOKYO BUILDING FLOOR 29 2-7-3',
          address2: 'MARUNOUCHI, CHIYODA-KU',
          address3: '100-6432, JAPAN',
          swift: 'UBSWCHZH80A',
        },
        to: {
          account: '40813904',
          IBAN: 'GB52CHAS60924240813904',
        },
      }),
      AUD: WireViaUK({
        account: '0218032/00001',
        from: {
          bank: 'AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED',
          address1: '100 QUEEN ST',
          address2: 'MELBOURNE 3000, AUSTRALIA',
          swift: 'ANZBAU3M',
        },
        to: {
          account: '40813903',
          IBAN: 'GB79CHAS60924240813903',
        },
      }),
    };
    return (
      <form className="confirmation" onSubmit={handleSubmit}>
        <section id="wire-deposit-futures">
          <h4>Instructions</h4>
          <p>Provide the information below to your bank or brokerage firm to request the transfer.
              Before wiring, confirm your TradeStation account is approved and is ready to receive a wire.
              Note: Foreign currency funds wired to the instructions below will be converted to USD at
              JPMorgan when received.</p>
          <h4>Important Notes</h4>
          <p>Please do not wire funds until your account is approved, and you have confirmed that the
              account is ready to receive a wire. For all incoming wires, the sending bank/firm must
              reference the title of your account from which the funds originated.</p>
          <p><b>Please Note: The title of your TradeStation account and the title of the account from which
              you are transferring funds must match. Foreign currency funds wired to any of the USD instructions
              below will be automatically converted to U.S. dollars (USD) upon receipt. This will create a
              currency rate risk exposure if you should need to convert the funds back to your domestic currency
              which may result in a noticeable profit or loss. As an alternative you may wire funds in your domestic
              currency if the special instructions are displayed below for your currency.</b></p>
          <dl>
            <dt id="deposit-amount">Deposit Amount:&nbsp;
              <span className="amount">{`${(this.props.formValues.values.transferAmount || 0).toLocaleString(
                'en-US', { style: 'currency', currency: this.props.formValues.values.transferCurrency }
              )} ${this.props.formValues.values.transferCurrency}`}</span></dt>
          </dl>
          <div className="instruction-block">
            {Object.keys(instructions[values.transferCurrency]).map(descriptionKey =>
              <div key={descriptionKey}>
                <h5>{descriptionKey}</h5>
                {instructions[values.transferCurrency][descriptionKey]}
              </div>
            )}
            <h5>For Further Credit Instructions</h5>
            {forFurtherCredit}
          </div>
          <div className="actions">
            <RippleButton className="default" context="EDIT_WIRE_DEPOSIT_FORM">edit</RippleButton>
            {this.props.showPrintButton &&
              <RippleButton className="primary" onClick={(e) => this.props.printPage(e)}>print</RippleButton>}
          </div>
        </section>
      </form>
    );
  }
}

WireTransferDepositFutures.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  printPage: PropTypes.func.isRequired,
  showPrintButton: PropTypes.bool.isRequired,
  formValues: PropTypes.shape({
    values: PropTypes.shape(),
  }),
};
export default reduxForm({
  form: 'wireTransferDepositForm',
  destroyOnUnmount: false,
})(WireTransferDepositFutures);
