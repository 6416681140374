import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ErrorBar.css';

const ErrorBar = (props) => {
  return (
    <div className={`error-bar ${props.showError ? 'active' : ''}`}>
      {props.errorMessage ||
        <span>An unexpected error occurred.<br /> Please try again later.</span>}
      {props.errors &&
        <ul>
          {props.errors.map((error, key) =>
            <li key={key}>{error}</li>)}
        </ul>}
    </div>
  );
};

ErrorBar.propTypes = {
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default connect(
  state => {
    return {
      showError: state.errorBar.error,
      errorMessage: state.errorBar.message,
      errors: state.errorBar.errors,
    };
  }
)(ErrorBar);
