import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './IRAFees.css';

const IRAFees = (props) => {
  const fees = props.transferFees;
  const origin = props.fundsOriginAccount;
  const isIRA = origin && origin.isIRA;
  return (
    <div className="ira-fees">
      {isIRA &&
        <dl>
          <dt>For IRA Account Closing:</dt>
          <div>
            {!props.transferFeesLoaded && <dd>Fees may be assessed.</dd>}
            {fees.iraClosing && <dd>&nbsp;&nbsp;{fees.iraClosing.displayAmount} IRA Closing Fee</dd>}
            {fees.iraAnnual && <dd>&nbsp;&nbsp;{fees.iraAnnual.displayAmount} IRA Annual Fee</dd>}
          </div>
        </dl>
      }
    </div>
  );
};

IRAFees.propTypes = {
  transferFees: PropTypes.shape().isRequired,
  transferFeesLoaded: PropTypes.bool.isRequired,
  fundsOriginAccount: PropTypes.shape(),
};

export default connect(
  (state) => {
    return {
      transferFees: state.transferFees.data,
      transferFeesLoaded: state.transferFees.everLoaded,
    };
  },
)(IRAFees);
