import * as accountActionsTypes from '../actions/bankAccountActions';

function extractDisplayNameFromAccount(account) {
  let dispName = '';
  if (account.IsSubstituteBankAccountNumber) {
    dispName = `${account.BankAccountNickName} ${account.BankAccountType}`;
    return dispName;
  }

  dispName = `${account.BankAccountNickName} ${account.BankAccountType}
             -${account.BankAccountNumber.slice(-4)}`;
  return dispName;
}

const getBankAccount = (account) => {
  const dispName = extractDisplayNameFromAccount(account);
  const result = {
    key: `bank_${account.BankId}`,
    isSubstituteBankAccountNumber: account.IsSubstituteBankAccountNumber,
    accountTitle: account.BankAccountTitle,
    accountDetail: account.BankAccountType,
    bankId: account.BankId,
    bankAccountType: account.BankAccountType,
    bankName: account.BankName,
    routingNumber: account.RoutingNumber,
    BankAccountNickName: account.BankAccountNickName,
    linkedAccounts: {
      brokerage: account.LinkedBrokerageAccounts.Accounts
        .filter(x => x) // TODO simplifly this , we should be able to remove this filter.
        .map(x => x.AccountNumber),
      repIds: account.LinkedBrokerageAccounts.Accounts
        .map(x => x.RepId || 'N/A'),
      bank: [],
    },
    isBankAccount: true,
    displayName: dispName,
    supportedCurrencies: ['USD'],
  };

  return result;
};


export default function (state = { data: [], everLoaded: false }, action) {
  switch (action.type) {
    case accountActionsTypes.FETCH_BANKING_ACCOUNTS_SUCCESS: {
      const accounts = action.payload.BankAccounts.map((account) => getBankAccount(account));
      return { data: accounts, everLoaded: true };
    }
    default:
      return state;
  }
}
