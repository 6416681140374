import { AOP_CLIENT_ID,
  IOS_CLIENT_ID,
  TANGO_CLIENT_ID,
  ANDROID_CLIENT_ID,
  CLIENT_CENTER_CLIENT_ID,
  ANDROID_WEB_CLIENT_ID,
  ANDROID_PLAID_SDK_CLIENT_ID,
  ANDROID_REGULAR_WEB_ATLAS_CLIENT_ID,
  IPAD_NATIVE_ATLAS_CLIENT_ID,
  IOS_PLAID_SDK_CLIENT_ID } from './constants';

const getUserProfile = (clientId, tsBrokerageAccounts) => {
  if (!tsBrokerageAccounts) {
    return {
      isEmployee: false,
      isPlaidAuthorized: false,
      isMobileSDKClient: false,
    };
  }

  const isEmployee = tsBrokerageAccounts.filter(acc => acc.repId.startsWith('EMP')).length > 0;

  const isAOP = clientId === AOP_CLIENT_ID;
  const isClientCenter = clientId === CLIENT_CENTER_CLIENT_ID;
  const isAndroidWeb = clientId === ANDROID_WEB_CLIENT_ID || clientId === ANDROID_REGULAR_WEB_ATLAS_CLIENT_ID;
  const isWeb = clientId === TANGO_CLIENT_ID || isAOP || isClientCenter || isAndroidWeb;

  const isAndroid = clientId === ANDROID_CLIENT_ID;
  const isIOS = clientId === IOS_CLIENT_ID;

  const isMobileSDKClient = clientId === ANDROID_PLAID_SDK_CLIENT_ID ||
                            clientId === IOS_PLAID_SDK_CLIENT_ID ||
                            clientId === IPAD_NATIVE_ATLAS_CLIENT_ID;
                            
  const isPlaidAuthorized = isWeb || isMobileSDKClient || (isEmployee && (isAndroid || isIOS));
  
  return {
    isEmployee,
    isPlaidAuthorized,
    isMobileSDKClient,
  };
};

export default getUserProfile;
