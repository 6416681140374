import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Ripples from './Ripples';
import BetterLink from '../BetterLink';

const RippleLink = (props) => {
  return (
    <Ripples className="ripple">
      <BetterLink
        to={props.to}
        target={props.target}
        className={`${props.className || 'success'} btn`}
      >
        {props.children}
      </BetterLink>
    </Ripples>
  );
};

RippleLink.propTypes = {
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.string,
};

export default connect()(RippleLink);
