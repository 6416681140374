import React from 'react';
import { PlaidLink, PlaidLinkStableEvent } from 'react-plaid-link';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';
import { StaticRoutes } from '../../Routes';
import logError from '../../logging/logError';
import logMsg from '../../logging/logMsg';


const PlaidRenderer = (props) => {
  // const BANKLINKINGERROR = 'no accounts information retrieved from Plaid';
  const currentHistory = props.history;
  const setPlaidLinkError = props.onPlaidlinkFailure;
  const logPlaidEvent = props.logPlaidEvent;
  const currentPlaidUrl = props.plaidUrl;
  const addLinkedBankPlaidAccount = props.addLinkedBankPlaidAccount;
  const addLinkedBankPlaidAccountEx = props.addLinkedBankPlaidAccountEx;
  const fdcnId = props.accounts[0].fdcnId.toString() || '';
  // eslint-disable-next-line no-unused-vars
  // const fastLinkFailure = props.onFastlinkFailure;
  const clientName = props.clientName;
  const env = props.env;
  const token = props.token;
  const products = props.products;
  const clientId = props.clientId;
  const userId = props.userId;

  const loadHandler = () => {
    const btnElem = document.getElementsByClassName('btnOpenPlaid')[0];
    btnElem.click();
  };

  const goBackToPreviousPage = () => {
    const fromRouteExists = (currentHistory.location.state &&
      currentHistory.location.state.from) || '';

    if (fromRouteExists) {
      delete currentHistory.location.state.from;
      currentHistory.push(StaticRoutes.MANAGE_BANK_ACCOUNTS);
    } else {
      currentHistory.push(StaticRoutes.ROOT);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const goToManualLinkingAccount = () => {
    currentHistory.push(StaticRoutes.LINK_BANK_ACCOUNT_MANUAL);
  };

  /**
   * callback function called if an error occured or the user manually exits PlaidLink
   * @param  {PlaidLinkError}           error     plaid error object
   * @param  {PlaidLinkOnExitMetadata}  metadata  plaid exit metadata object
   */
  // eslint-disable-next-line no-unused-vars
  const exitHandler = (error, metadata) => {
    if (error != null) {
      setPlaidLinkError(error);
      logError({ userId, clientId, ...metadata });
    } else {
      goBackToPreviousPage();
      // goToManualLinkingAccount();
    }
  };

  const handleLinkedAccounts = (accountsData) => {
    if (accountsData.numbers.ach[0].account_id && accountsData.token) {
      addLinkedBankPlaidAccount({ clientId, ...accountsData });
    }
  };

  const handleLinkedAccountsEx = (accountsData) => {
    addLinkedBankPlaidAccountEx({ clientId, ...accountsData });
  };

  /**
   * callback function called when the bank linking process successfully ends
   * @param  {string}                      publicToken  public token
   * @param  {PlaidLinkOnSuccessMetadata}  metadata     plaid on success metadata object
   */
  // eslint-disable-next-line no-unused-vars
  const successHandler = (publicToken, metadata) => {
    logMsg({ userId, clientId, ...metadata });

    return Promise.resolve()
      .then(() =>
        fetch(`${currentPlaidUrl}/tokenexchange`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ public_token: publicToken }),
        }))
      .then(res => res.json())
      .then(accountsData => {
        handleLinkedAccounts(accountsData);
      })
      .catch(error => {
        logError({ userId, clientId, ...error });
        setPlaidLinkError(error);
      });
  };

    /**
   * callback function called when the bank linking process successfully ends
   * @param  {string}                      publicToken  public token
   * @param  {PlaidLinkOnSuccessMetadata}  metadata     plaid on success metadata object
   */
  // eslint-disable-next-line no-unused-vars
  const successHandlerEx = (publicToken, metadata) => {
    logMsg({ userId, clientId, ...metadata });

    try {
      return Promise.resolve().then(() => {
        handleLinkedAccountsEx(metadata);
      });
    } catch (error) {
      logError({ userId, clientId, ...error });
      setPlaidLinkError(error);
    }

    return 1;
  };

  const eventHandler = (eventName, metadata) => {
    if (eventName === PlaidLinkStableEvent.ERROR) {
      logError({ userId, clientId, ...metadata });
    } else {
      logMsg({ userId, clientId, ...metadata });
    }

    if (!fdcnId) {
      // eslint-disable-next-line max-len
      logError(`FDCNID not defined for this userId: ${userId}, clientId: ${clientId}. Plaid event log not generated!`);
    } else {
      logPlaidEvent({ fdcnId, ...metadata });
    }
  };

  return (
    <PlaidLink
      clientName={clientName}
      env={env}
      product={products}
      style={{
        display: 'none',
      }}
      token={token}
      onSuccess={successHandlerEx}
      className="btnOpenPlaid"
      onLoad={loadHandler}
      onExit={exitHandler}
      onEvent={eventHandler}
    />
  );
};

PlaidRenderer.propTypes = {
  plaidUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.object,
    }),
  }),
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  clientName: PropTypes.string.isRequired,
  env: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPlaidlinkFailure: PropTypes.func.isRequired,
  logPlaidEvent: PropTypes.func.isRequired,
  addLinkedBankPlaidAccount: PropTypes.func.isRequired,
  addLinkedBankPlaidAccountEx: PropTypes.func.isRequired,
  clientId: PropTypes.string,
  userId: PropTypes.string,
};

export default PlaidRenderer;
