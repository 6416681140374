import React from 'react';
import BetterLink from '../BetterLink';

const IRACustodianAccount = () => {
  return (
    <div>
      <p>We’re sorry, but this transaction cannot be completed through TradeStation.
        Please contact your custodian below to initiate your request.</p>

      <ul>
        <li>
          <BetterLink
            to="https://www.midlandIRA.com"
            title="Midland IRA, Inc."
            target="_blank"
          >Midland IRA, Inc.
          </BetterLink>
        </li>

        <li>
          <BetterLink
            to="https://www.trustetc.com"
            title="Equity Trust Company"
            target="_blank"
          >Equity Trust Company
          </BetterLink>
        </li>

        <li>
          <BetterLink
            to="https://www.mtrustcompany.com"
            title="Millennium Trust Company"
            target="_blank"
          >Millennium Trust Company
          </BetterLink>
        </li>

        <li>
          <BetterLink
            to="https://www.stratatrust.com"
            title="STRATA Trust Company"
            target="_blank"
          >STRATA Trust Company
          </BetterLink>
        </li>

        <li>
          <BetterLink
            to="https://directedira.com"
            title="Directed Trust Company"
            target="_blank"
          >Directed IRA
          </BetterLink>
        </li>

        <li>
          <BetterLink
            to="https://globalnetgemel.co.il"
            title="Global Net Provident"
            target="_blank"
          >Global Net Provident
          </BetterLink>
        </li>

      </ul>

      <p>If you do not know who your custodian is, please contact our&nbsp;
        <BetterLink
          to="https://www.tradestation.com/contact-us/"
          title="TradeStation Client Experience Team"
          target="_top"
        >TradeStation Client Services
        </BetterLink>.
      </p>
    </div >
  );
};

export default IRACustodianAccount;
