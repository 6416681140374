import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Ripples from './Ripples';
import { sendEvent } from '../../utils/eventUtils';

const RippleButton = (props) => {
  const handleClick = (event) => {
    if (props.context) {
      sendEvent({
        version: '1.0',
        eventType: 'BUTTON_CLICK',
        context: props.context,
      });
    }

    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <Ripples className="ripple">
      <button
        type={props.type || 'submit'}
        id={props.id || 'submit'}
        onClick={handleClick}
        disabled={!props.isPlaidDisclaimer && (props.disabled || props.activityBar)}
        className={props.className || 'success'}
      >
        {props.children}
      </button>
    </Ripples>
  );
};

RippleButton.propTypes = {
  activityBar: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  type: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isPlaidDisclaimer: PropTypes.bool,
  context: PropTypes.string,
};

export default connect(
  state => {
    return {
      activityBar: state.activityBar,
    };
  }
)(RippleButton);
