import * as authenticationActions from '../actions/authenticationActions';

const handleRequest = (state, action) => {
  const status = (action.error && action.error.response) ? action.error.response.status : null;
  const failedAuth = status === 401 || status === 403 || state.failedAuth || false; // [1]

  const authentication = {
    authToken: state.authToken,
    failedAuth,
    isAuthenticated: (state.authToken && !failedAuth) || false,
  };

  return authentication;
};

// [1]: state.failedAuth? if auth has ever failed, it's failed

export default function (state = { }, action) {
  switch (action.type) {
    case authenticationActions.UPDATE_ACCESS_TOKEN: {
      return {
        ...state,
        authToken: action.accessToken,
        failedAuth: false,
        isAuthenticated: action.accessToken && action.accessToken.length >= 20,
      };
    }
    default: {
      return handleRequest(state, action);
    }
  }
}

