export const FETCH_BANKING_DAYS_REQUEST = 'FETCH_BANKING_DAYS_REQUEST';
export const FETCH_BANKING_DAYS_SUCCESS = 'FETCH_BANKING_DAYS_SUCCESS';
export const FETCH_BANKING_DAYS_ERROR = 'FETCH_BANKING_DAYS_ERROR';

/* eslint-disable */
export function requestBankingDays() {
  return (state) => {
    return {
      types: [FETCH_BANKING_DAYS_REQUEST, FETCH_BANKING_DAYS_SUCCESS, FETCH_BANKING_DAYS_ERROR],
      fetch: {
        url: '/Banking/ValidDates',
      },
    };
  };
}
