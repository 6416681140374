import React from 'react';
import './MaintenancePage.css';
/**
 * This page must be used for maintenance purposes only. 
 * Load it through a flag and avoid load it directly from the browser 
 * 
 * */
/* eslint-disable max-len */
const MaintenancePage = () => {
  return (
    <div className="maintenance">
      <section>
        <h1>System Temporarily Unavailable</h1>
        <p className="mainP">We are upgrading our services in order to better serve you. As a result, the feature you requested will be unavailable until approximately 8:00 PM Eastern time on Saturday, June 24th. Please try your request again after that time.</p>
        <p>We apologize for any inconvenience and appreciate your patience.</p>
      </section>
    </div>
  );
};
/* eslint-enable max-len */

export default MaintenancePage;
