import React from 'react';
import { Link } from 'react-router-dom';
import { StaticRoutes } from '../Routes';

const ErrorPage = () => {
  return (
    <section className="page">
      <h1>Sorry!</h1>
      <p>The page you&#8217;re looking for doesn&#8217;t exist.</p>
      <Link
        to={StaticRoutes.TRANSFERS}
      >
        Go To Smart Transfers
      </Link>
    </section>
  );
};

export default ErrorPage;
