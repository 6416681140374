import { CHANGE_FREQUENCY } from '../actions/frequencyActions';

const initialState = {
  key: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_FREQUENCY: {
      return Object.assign({}, state, {
        key: action.payload,
      });
    }
    default:
      return state;
  }
}
