export default function publishPageHeight(overrideHeight = null) {
  const html = document.documentElement;
  const height = overrideHeight || Math.max(html.scrollHeight, html.offsetHeight);
  window.parent.postMessage({ height }, '*');

  return height;
}

export const publishPageHeightMiddleware = () => next => action => {
  publishPageHeight();
  return next(action);
};
