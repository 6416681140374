import log from '../logging/logError';

export default (response, clientId, userId) => {
  const successResponses = [200, 201, 202, 204, 304];
  if (successResponses.includes(response.status)) {
    return response;
  }

  return response.text()
    .then(body => {
      const href = window.location.href;
      const url = response.url;
      const status = `${response.status} ${response.statusText}`;
      const error = new Error(status);
      error.href = href;
      error.body = body;
      error.response = response;
      log({
        clientId,
        userId,
        href,
        url,
        status,
        body,
      });
      throw error;
    });
};
