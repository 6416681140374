function shouldShowActivityBar(fetch) {
  const method = (fetch && fetch.options && fetch.options.method) || '';
  return ['post', 'delete', 'put'].includes(method.toLowerCase());
}

export default function (state = false, action) {
  if (action.isRequest) {
    return shouldShowActivityBar(action.fetch);
  } else if (action.isSuccess || action.isError) {
    const method = (action.fetch && action.fetch.options && action.fetch.options.method) || '';
    if (method === '') {
      return state;
    }
    return !['post', 'delete', 'put'].includes(method.toLowerCase());
  }

  return state;
}
