export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_ERROR = 'FETCH_COUNTRIES_ERROR';

export function fetchCountries() {
  return {
    types: [FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRIES_ERROR],
    fetch: {
      api: 'aop',
      url: '/countries?filter[where][isAllowed]=true&filter[fields][countryCode]=true&filter[fields][country]=true',
      unsecured: true,
    },
  };
}
